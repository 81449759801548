import { useEffect, useRef } from 'react';

// Allows access to the previous value when re-rendering
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    ref.current = value;
  }, [value]);

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
