import { useEffect, useRef, useState } from 'react';
import { usePublicClient, useWalletClient } from 'wagmi';
import { BrowserProvider, FallbackProvider, FetchRequest, JsonRpcProvider, Network, ethers } from 'ethers';
import { PublicClient, WalletClient } from 'viem';
import { getApi3RpcUrls } from 'utils/utils';
import uniq from 'lodash/uniq';
import { go } from '@api3/promise-utils';
import { useProviderStore } from 'stores';
import { usePageParams } from 'hooks/use-page-params';
import { allChainsByAlias } from 'utils/dapis';

const connectedClientToProvider = (walletClient?: WalletClient) =>
  walletClient && walletClient.transport ? new BrowserProvider(walletClient) : undefined;

const createProvider = (rpcUrl: string, chainId: number, name: string) => {
  const fetchClient = new FetchRequest(rpcUrl);
  fetchClient.timeout = 10_000;

  const network = { name, chainId };
  const provider = new JsonRpcProvider(fetchClient, network, {
    staticNetwork: Network.from(network.chainId),
  });

  return provider;
};

const isProviderResponding = async (provider: ethers.JsonRpcProvider) => {
  // There is a bug in FallbackProvider that makes it hang if one RPC hangs
  // (See https://github.com/ethers-io/ethers.js/issues/2030)
  // Because of 10s timeout we set in createProvider function, this eth_chainId call will fail
  // and this RPC will not be added as one of the providers in the FallbackProvider
  const goChainId = await go(async () => provider.send('eth_chainId', []));
  return goChainId.success;
};

const filterRespondingProviders = async (providers: ethers.JsonRpcProvider[]) => {
  const providersWithTestCallResult = await Promise.all(
    providers.map(async (provider) => ({
      provider,
      responding: await isProviderResponding(provider),
    }))
  );

  return providersWithTestCallResult.filter(({ responding }) => responding).map(({ provider }) => provider);
};

const createFallbackProvider = async (chainId: string, publicClient: PublicClient, walletClient?: WalletClient) => {
  if (!publicClient.chain || !publicClient.transport) return;

  const { chain, transport } = publicClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };

  // Combine wagmi RPC URL with those from API3
  const wagmiRpcUrl = transport.url as string;
  const api3RpcUrls = getApi3RpcUrls(chain.id);
  const rpcUrls = uniq([wagmiRpcUrl, ...api3RpcUrls]);
  const allProviders = rpcUrls.map((rpcUrl) => createProvider(rpcUrl, chain.id, chain.name));
  const respondingProviders = await filterRespondingProviders(allProviders);

  // Use wallet provider with increased priority
  const walletProvider = connectedClientToProvider(walletClient);
  const walletProviderNetwork = await walletProvider?.getNetwork();
  if (walletProvider && walletProviderNetwork?.chainId?.toString() === chainId) {
    const providersWithWeights = [walletProvider, ...respondingProviders].map((provider, index) => {
      if (index === 0) return { provider, weight: 3, priority: 3 }; // Wallet provider
      return { provider, weight: 1, priority: 1 }; // Public provider
    });

    return new FallbackProvider(providersWithWeights, network);
  }

  // Don't create a FallbackProvider if there are no responding providers and user is not connected
  if (respondingProviders.length === 0) return null;

  // Use only wagmi and API3 RPC providers
  return new FallbackProvider(respondingProviders, network);
};

// Hook to set the provider for the current page chain
export const useInitializeProvider = () => {
  const { chainAlias } = usePageParams();
  const chain = chainAlias ? allChainsByAlias[chainAlias] : undefined;
  const chainId = chain?.id;
  const publicClient = usePublicClient({ chainId: Number(chainId) });
  const { data: walletClient } = useWalletClient({ chainId: Number(chainId) });
  const { setProviderState, setProviderForChain } = useProviderStore();
  const walletProvider = connectedClientToProvider(walletClient);
  const [walletChainId, setWalletChainId] = useState<string>();
  const initializationRequest = useRef(0);

  const initializeProvider = async (reqNum: number) => {
    if (!chainId) return;

    setProviderState(chainId, 'initializing');

    const provider =
      process.env.VITE_APP_MOCK_ENV === 'true'
        ? new FallbackProvider([(await createProvider('http://127.0.0.1:8545/', 31337, 'hardhat'))!])
        : publicClient && (await createFallbackProvider(chainId, publicClient, walletClient));

    // If there was a new initialization request done in the meantime, ignore the result
    if (reqNum !== initializationRequest.current) return;

    const fallbackProvidersHasEntries = provider?.providerConfigs?.length;
    if (fallbackProvidersHasEntries) {
      setProviderForChain(chainId, provider);
      setProviderState(chainId, 'initialized');
    } else {
      setProviderState(chainId, 'error');
    }
  };

  // Fetch chain ID of the connected wallet
  useEffect(() => {
    const fetchNetwork = async () => {
      const network = await walletProvider?.getNetwork();
      setWalletChainId(network?.chainId?.toString());
    };

    fetchNetwork();
  }, [walletProvider]);

  // Reinitialize provider when page's chain ID changes or wallet is connected
  useEffect(() => {
    initializationRequest.current += 1;
    initializeProvider(initializationRequest.current);
  }, [chainId, walletChainId]);
};
