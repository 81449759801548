import { ComponentProps } from 'react';

export const HelpOutlineIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M8.69331 17.0784C12.7815 15.6513 16.4141 12.6021 16.4141 8.44735C16.4141 4.29263 13.0562 0.924561 8.91406 0.924561C4.77193 0.924561 1.58594 4.09723 1.58594 8.25195C1.58594 12.5566 5.16406 15.252 8.69331 15.252M8.69331 17.0784C8.67323 16.5379 8.71335 15.2521 8.69331 15.252M8.69331 17.0784V15.252"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M8.2345 9.90047V9.83956C8.23876 9.44205 8.27818 9.12575 8.35275 8.89067C8.42945 8.65558 8.53812 8.46537 8.67874 8.32004C8.81937 8.17472 8.98876 8.04221 9.18691 7.92253C9.31475 7.84132 9.42981 7.75049 9.53208 7.65005C9.63436 7.5496 9.71532 7.43419 9.77498 7.30383C9.83464 7.17346 9.86447 7.0292 9.86447 6.87105C9.86447 6.68085 9.81972 6.51629 9.73024 6.37737C9.64075 6.23845 9.52143 6.1316 9.37228 6.0568C9.22526 5.97986 9.0612 5.94139 8.88009 5.94139C8.71603 5.94139 8.55943 5.97558 8.41028 6.04397C8.26113 6.11236 8.13755 6.21922 8.03954 6.36455C7.94153 6.50774 7.88507 6.6926 7.87015 6.91914H6.89856C6.91347 6.53445 7.01042 6.2096 7.1894 5.9446C7.36837 5.67745 7.60488 5.47549 7.89891 5.33871C8.19508 5.20193 8.52214 5.13354 8.88009 5.13354C9.27214 5.13354 9.61518 5.20728 9.90921 5.35474C10.2032 5.50007 10.4312 5.70417 10.5932 5.96704C10.7572 6.22777 10.8393 6.53231 10.8393 6.88067C10.8393 7.12003 10.802 7.33588 10.7274 7.52823C10.6528 7.71844 10.5463 7.88834 10.4078 8.03794C10.2714 8.18754 10.1074 8.32004 9.91561 8.43545C9.7345 8.54872 9.58748 8.66626 9.47455 8.78808C9.36376 8.9099 9.28279 9.05416 9.23166 9.22086C9.18052 9.38755 9.15282 9.59379 9.14856 9.83956V9.90047H8.2345ZM8.7171 11.8496C8.54238 11.8496 8.39217 11.7876 8.26646 11.6636C8.14075 11.5375 8.07789 11.3858 8.07789 11.2084C8.07789 11.0332 8.14075 10.8836 8.26646 10.7596C8.39217 10.6335 8.54238 10.5705 8.7171 10.5705C8.88968 10.5705 9.03883 10.6335 9.16454 10.7596C9.29238 10.8836 9.3563 11.0332 9.3563 11.2084C9.3563 11.326 9.32647 11.4339 9.26681 11.5322C9.20928 11.6284 9.13258 11.7053 9.0367 11.763C8.94082 11.8207 8.83428 11.8496 8.7171 11.8496Z"
        fill="currentColor"
      />
    </svg>
  );
};
