import { useNotificationBannerContext } from 'components/layout/notification-banner';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { isResponseNotFound } from 'utils/utils';

const MAX_RETRIES_ALLOWED = 3;

export const onRetryCalls = (failureCount: number, error: any) => {
  return !isResponseNotFound(error) && failureCount < MAX_RETRIES_ALLOWED;
};

export const useHandleApiError = (error: Error | null, isError: boolean, message?: string) => {
  const { setErrorBanner } = useNotificationBannerContext();

  useEffect(() => {
    if (isError) {
      setErrorBanner({
        message: message || 'Something went wrong!',
        secondaryMessage: 'Try reloading the page.',
      });

      Sentry.captureException(error);
    }
  }, [isError]);
};
