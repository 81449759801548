import { ComponentPropsWithoutRef } from 'react';

export const StatusInactiveIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="15.9995" cy="16" r="8" fill="#040606" />
    <circle cx="15.9995" cy="16" r="8" fill="url(#paint0_radial_15131_110916)" fillOpacity="0.25" />
    <circle cx="15.9995" cy="16" r="8" stroke="#071810" />
    <circle cx="15.9995" cy="16" r="8" stroke="url(#paint1_radial_15131_110916)" strokeOpacity="0.25" />
    <defs>
      <radialGradient
        id="paint0_radial_15131_110916"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9995 8) rotate(90) scale(23.8214 33.35)"
      >
        <stop stopColor="#1AF8E8" />
        <stop offset="0.458333" stopOpacity="0" />
        <stop offset="1" stopColor="#F5F5F5" stopOpacity="0.6" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_15131_110916"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9995 8) rotate(90) scale(23.8214 33.35)"
      >
        <stop stopColor="#1AF8E8" />
        <stop offset="0.458333" stopOpacity="0" />
        <stop offset="1" stopColor="#F5F5F5" stopOpacity="0.6" />
      </radialGradient>
    </defs>
  </svg>
);
