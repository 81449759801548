import { Eip1193Provider } from 'ethers';
import { walletMessages } from 'constants/walletMessages';
import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { useConnectedAccount } from './use-connected-account';

export const useInjectedWalletProvider = (showBanner = false) => {
  const { connectorName } = useConnectedAccount();
  const { setErrorBanner } = useNotificationBannerContext();

  const injectedProvider: Eip1193Provider = (window as any)?.ethereum;

  if (!injectedProvider || connectorName !== 'MetaMask') {
    if (showBanner) {
      setErrorBanner({
        message: walletMessages.TX_GENERIC_ERROR_MESSAGE,
        secondaryMessage: walletMessages.TX_SWITCH_NETWORK,
      });
    }

    return { injectedProvider: null };
  }

  return { injectedProvider };
};
