import { useEffect, useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { breakpoints, threeCardsBreakpoint } from 'styles/theme';
import { filterDapisBySearchQuery } from 'utils/dapi-search';
import { Table } from 'components/shared';
import { StatusActiveIcon } from 'components/shared/icons';
import { useSimpleSearchParam } from 'hooks/use-simple-search-param';
import { usePageParams } from 'hooks';
import { getActiveOrDeprecatedDapiNames } from 'utils/dapis';
import { fonts } from 'styles/fonts';
import { ChainDApiCard } from '../chain-dapi-card';
import { InactiveDApiRow } from './inactive-dapi-row';

interface Props {
  activeFeeds: string[];
}

const allDapis = getActiveOrDeprecatedDapiNames();

export const ChainDApisSearchResult = (props: Props) => {
  const { activeFeeds } = props;
  const { searchParamQueryValue } = useSimpleSearchParam();
  const { chainAlias = '' } = usePageParams();

  const [currentPage, setCurrentPage] = useState(1);

  const inactiveFeedsUnfiltered = allDapis.filter((d) => !activeFeeds.includes(d));
  const inactiveFeeds = filterDapisBySearchQuery(inactiveFeedsUnfiltered, searchParamQueryValue);

  // Reset pagination when number of items in the table change
  useEffect(() => {
    setCurrentPage(1);
  }, [inactiveFeeds.length]);

  return (
    <Container>
      <ActiveFeeds className={clsx('search-results-row', { 'active-missing': activeFeeds.length === 0 })}>
        <div className="label">
          <StatusActiveIcon />
          Active data feeds:
        </div>
        <ul className="chain-dapis-list">
          {activeFeeds.map((dapiName) => (
            <ChainDApiCard key={dapiName} chainAlias={chainAlias} dapiName={dapiName} />
          ))}
        </ul>
      </ActiveFeeds>

      {inactiveFeeds.length > 0 && (
        <InactiveFeeds className="search-results-row" data-testid="inactive-feeds">
          <div className="label">Activate a data feed:</div>
          <Table
            className="inactive-feeds-table"
            columns={[
              {
                label: '',
                dataKey: 'inactiveFeed',
              },
            ]}
            rows={inactiveFeeds.map((dapiName) => ({
              inactiveFeed: <InactiveDApiRow key={dapiName} chainAlias={chainAlias} dapiName={dapiName} />,
            }))}
            itemsPerPage={5}
            totalItems={inactiveFeeds.length}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </InactiveFeeds>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .label {
    margin: 0 0 24px 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: var(--color-base-light);
    ${fonts.subtitle[11]}

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .search-results-row {
    max-width: 328px;
    margin: 64px auto 0;
  }

  @media (min-width: ${threeCardsBreakpoint}px) {
    .search-results-row {
      max-width: 500px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .search-results-row {
      margin-top: 80px;
      max-width: 960px;
    }

    .label {
      margin: 0 0 40px 24px;
      justify-content: flex-start;
      ${fonts.subtitle[5]}
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    .search-results-row {
      max-width: 1080px;
    }

    .label {
      margin: 0 0 40px 0;
    }
  }
`;

const ActiveFeeds = styled.div`
  .chain-dapis-list {
    animation: slideUp 0.3s ease-in;
    margin: 0;
    justify-content: center;
  }

  &.active-missing {
    display: none;
  }
`;

const InactiveFeeds = styled.div`
  .inactive-feeds-table {
    margin: 0;
    animation: slideUp 0.3s ease-in;

    table {
      margin: 0;
      border-radius: 12px;

      thead {
        display: none;
      }

      tbody {
        td {
          border-bottom: 1px solid var(--color-dark-blue-800);
          padding: 0;
        }

        tr:first-child td {
          padding-top: 8px;
        }

        tr:last-child td {
          padding-bottom: 8px;
          border-bottom: none;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .inactive-feeds-table {
      padding: 0 24px;

      table {
        tbody {
          tr:first-child td {
            padding-top: 16px;
          }
        }
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    .inactive-feeds-table {
      padding: 0;
    }
  }
`;
