import { ComponentProps } from 'react';

export const ExternalLinkIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.9995 11.001H0.999481V1.00098" stroke="currentColor" />
      <path d="M3.84457 8.1579L10.5232 1.47754" stroke="currentColor" />
      <path d="M6.11246 1.47656H10.5229V5.88894" stroke="currentColor" />
    </svg>
  );
};
