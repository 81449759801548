import {
  useDisconnect,
  useSwitchNetwork,
  useConnectedAccount,
  useChain,
  useInjectedWalletProvider,
} from 'hooks/chain-data';
import { Modal, ModalHeader } from 'components/modal';
import { ChainLogo } from '@api3/logos';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { useGeneralStore } from 'stores';
import { usePageParams } from 'hooks';
import { useEffect } from 'react';
import { fonts } from 'styles/fonts';
import { Button } from '../shared';

export const SwitchNetworkModal = () => {
  const { isConnected } = useConnectedAccount();
  const { chainAlias } = usePageParams();
  const { chainId, chainName } = useChain(chainAlias);
  const { isOnDifferentChain } = useGeneralStore();
  const { error, setError, switchNetwork } = useSwitchNetwork();
  const disconnect = useDisconnect();
  const { injectedProvider } = useInjectedWalletProvider();

  useEffect(() => {
    if (!isConnected || !isOnDifferentChain) {
      setError(undefined);
    }
  }, [isConnected, isOnDifferentChain, setError]);

  return (
    <Modal open={isConnected && isOnDifferentChain} hideCloseButton error={error}>
      <ModalContent>
        <ModalHeader>
          <span>Switch your wallet’s network to</span>

          <span>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive */}
            <img src={ChainLogo(String(chainId))} tabIndex={2} alt={`Chain logo for ${chainName}`} />{' '}
            <span className="chain-name">{chainName}</span>
          </span>
        </ModalHeader>

        <span className="modal-description">
          For the best experience, stay connected to&nbsp;the&nbsp;chain associated with the data feeds you’re viewing.
        </span>

        {injectedProvider && (
          <div className="modal-actions">
            <Button variant="link" color="blue" size="small" sm={{ size: 'large' }} onClick={disconnect}>
              Continue without switching networks
            </Button>
            <Button
              variant="primary"
              color="blue"
              size="small"
              sm={{ size: 'large' }}
              onClick={() => switchNetwork(chainId, injectedProvider)}
            >
              Switch Network
            </Button>
          </div>
        )}

        {!injectedProvider && (
          <div className="modal-actions">
            <Button fullWidth variant="link" color="blue" size="small" sm={{ size: 'large' }} onClick={disconnect}>
              Continue without switching networks
            </Button>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  max-width: 312px;

  .modal-header {
    margin-top: 14px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;

    > span {
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      ${fonts.heading[9]};

      & .chain-name {
        ${fonts.subtitle[9]};
      }

      img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin: -3px 0 0 0.25ch;
      }
    }
  }

  .modal-description {
    color: var(--color-gray-200);
    ${fonts.body[9]};
    text-align: center;
    margin: 0;
  }

  .modal-actions {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    justify-content: center;

    > button {
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    padding: 0;
    gap: 64px;
    max-width: 528px;
    margin: 0 64px;
    box-sizing: border-box;

    .modal-header {
      margin-top: 0;
      gap: 8px;

      > span {
        ${fonts.heading[6]};

        & .chain-name {
          ${fonts.subtitle[3]};
        }

        img {
          min-width: 40px;
          min-height: 40px;
        }
      }
    }

    .modal-description {
      ${fonts.description[3]};
    }

    .modal-actions {
      gap: 32px;

      > button {
        width: unset;
        white-space: nowrap;
      }
    }
  }
`;
