export const walletMessages = {
  // Transaction messages
  TX_GENERIC_REJECTED: 'Transaction was rejected.',
  TX_GENERIC_COMPLETED: 'Transaction completed.',
  TX_GENERIC_ERROR: 'Something went wrong! Try reloading the page.',
  TX_GENERIC_ERROR_MESSAGE: 'Something went wrong!',
  TX_GENERIC_ERROR_RELOAD: 'Try reloading the page.',
  TX_PENDING: 'Transaction detected, waiting for confirmation',
  TX_CONFIRMED: 'Transaction confirmed',
  TX_SWITCH_NETWORK: 'Try switching to the network manually in your wallet settings.',
};
