import { useHandleApiError } from 'hooks/use-handle-api-error';
import { THIRTY_SECONDS_CACHING } from 'constants/caching';
import { ethers } from 'ethers';
import { useQuery } from '@tanstack/react-query';
import { encodeDapiName, getApi3Market, getApi3ReaderProxy, computeProxyAddress } from 'utils/contracts';
import { BeaconData } from 'types';
import { useProviderStore } from 'stores';

export interface ChainData {
  value: bigint;
  timestamp: number;
  updateParameters: string[];
  expiringTimestamps: number[];
  airnodes: string[];
  beacons: BeaconData[];
}

interface UseDapiChainDataParams {
  dapiName?: string;
  chainId?: string;
  hideError?: boolean;
}

const readProxyData = async (dapiName: string, chainId: string, provider: ethers.Provider) => {
  const { deployed, proxyAddress } = await computeProxyAddress(chainId, dapiName, provider);

  if (deployed) {
    const api3ReaderProxy = await getApi3ReaderProxy(proxyAddress, provider);
    const data = await api3ReaderProxy.read();
    return {
      value: data.value,
      timestamp: Number(data.timestamp) * 1000,
    };
  }
};

const readChainData = async (
  dapiName: string,
  chainId: string,
  provider: ethers.Provider
): Promise<ChainData | undefined> => {
  const api3Market = await getApi3Market(chainId, provider);
  const encodedDapiName = encodeDapiName(dapiName);
  const data = await api3Market.getDapiData(encodedDapiName);

  const airnodes: string[] =
    data.beaconValues.length > 1
      ? ethers.AbiCoder.defaultAbiCoder().decode(['address[]'], data.dataFeedDetails)[0]
      : ethers.AbiCoder.defaultAbiCoder().decode(['address'], data.dataFeedDetails);

  const beacons = airnodes.map((airnode, index) => ({
    airnodeAddress: airnode,
    value: data.beaconValues[index],
    timestamp: Number(data.beaconTimestamps[index]) * 1000,
  }));

  return {
    value: data.dapiValue,
    timestamp: Number(data.dapiTimestamp) * 1000,
    updateParameters: Array.from(data.updateParameters),
    airnodes,
    expiringTimestamps: (data.endTimestamps as bigint[]).map((timestamp) => Number(timestamp)),
    beacons,
  };
};

const getDapiChainData = async (
  dapiName: string,
  chainId: string,
  provider: ethers.Provider
): Promise<ChainData | undefined> => {
  const [chainData, proxyData] = await Promise.all([
    readChainData(dapiName, chainId, provider),
    readProxyData(dapiName, chainId, provider),
  ]);

  if (!chainData) {
    return undefined;
  }

  return {
    ...chainData,
    value: proxyData?.value || chainData.value,
    timestamp: proxyData?.timestamp || chainData.timestamp,
  };
};

export const useDapiChainData = ({ dapiName, chainId = '', hideError = false }: UseDapiChainDataParams) => {
  const { providerState, providers } = useProviderStore();
  const provider = chainId ? providers[chainId] : undefined;

  const {
    data,
    error,
    isError: isQueryError,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ['dapiChainData', dapiName, chainId],
    queryFn: () => getDapiChainData(dapiName!, chainId!, provider!),
    ...THIRTY_SECONDS_CACHING,
    enabled: !!(dapiName && chainId && provider),
  });

  const isError = isQueryError || (!!chainId && providerState[chainId] === 'error');

  useHandleApiError(error, !hideError && isError, 'Unable to load dAPI chain data.');

  return {
    ...data,
    isLoading,
    isError,
    isSuccess,
  };
};
