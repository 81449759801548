import styled from 'styled-components';
import clsx from 'clsx';
import { breakpoints } from 'styles/theme';
import { Button, Tooltip } from 'components/shared';
import { api3Chains } from '@api3/dapi-management';
import { getDapiPath } from 'utils/utils';
import { useDapiChainData } from 'hooks/chain-data';
import Skeleton from 'components/shared/skeleton';
import { CalendarReminder } from 'components/shared/calendar-reminder';
import { ExclamationTriangleIcon, HelpIcon } from 'components/shared/icons';
import { formatDeviation, formatExpiryDateSimple, formatHeartbeat } from 'utils/format';
import { decodeUpdateParameters } from 'utils/contracts';
import { isDeprecatedDataFeed } from 'utils/dapis';
import { useEffect } from 'react';
import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { ExpiredBanner } from 'components/layout/expired-banner';
import { fonts } from 'styles/fonts';
import { DiscordButton } from './discord-button';

interface Props {
  dapiName: string;
  chain: api3Chains.Chain;
}

export const DetailsPageSidebar = ({ dapiName, chain }: Props) => {
  const { isLoading, isError, updateParameters, expiringTimestamps } = useDapiChainData({
    dapiName,
    chainId: chain.id,
  });
  const { setBanner } = useNotificationBannerContext();

  const plans = expiringTimestamps?.map((timestamp, index) => ({
    from: index === 0 ? 0 : expiringTimestamps[index - 1] * 1000,
    to: timestamp * 1000,
    parameters: decodeUpdateParameters(updateParameters?.[index]),
  }));

  const activateUrl = `${getDapiPath(chain.alias, dapiName)}/activate?default=${plans?.[0]?.parameters?.deviationThreshold}`;
  const purchasePlanButtonDisabled = isDeprecatedDataFeed(chain.id, dapiName);

  useEffect(() => {
    if (!isLoading && !isError && plans && plans.length === 1 && plans[0].to < Date.now()) {
      setBanner({
        content: <ExpiredBanner />,
        type: 'expired',
      });
    }
  }, [isError, isLoading, plans]);

  return (
    <Container>
      <Parameters>
        <h3>Active Plans</h3>
        <div className="active-plans-grid">
          <div className="grid-header-left">Date</div>
          <div className="grid-header-right">
            <div className="parameters-title" data-testid="parameters-title">
              Parameters
              <Tooltip content={<UpdateParametersTooltip />}>
                <Tooltip.Trigger>
                  <HelpIcon className="help-icon" />
                  <span className="sr-only">Help icon</span> {/* For screen readers to announce the tooltip */}
                </Tooltip.Trigger>
              </Tooltip>
            </div>
          </div>
          {isLoading || !plans ? (
            <>
              <Skeleton width="80%" height="22px" enableAnimation={!isError} containerClassName="skeleton-left" />
              <Skeleton width="50%" height="22px" enableAnimation={!isError} containerClassName="skeleton-right" />
              <Skeleton width="40%" height="22px" enableAnimation={!isError} containerClassName="skeleton-left" />
              <Skeleton width="90%" height="22px" enableAnimation={!isError} containerClassName="skeleton-right" />
            </>
          ) : (
            plans?.map(({ from, to, parameters: { deviationThreshold, heartbeatInterval } }, index) => (
              <>
                <div className={clsx('grid-item-left', { highlighted: index === 0 })}>
                  <div className="expiring-timestamp" data-testid="expiring-date">
                    {plans.length > 1 ? (
                      from === 0 ? (
                        'Now to '
                      ) : (
                        `${formatExpiryDateSimple(from)} to `
                      )
                    ) : (
                      <div>
                        <ExclamationTriangleIcon className="warning-icon" /> Expiring
                      </div>
                    )}
                    {formatExpiryDateSimple(to)}
                  </div>
                  <CalendarReminder
                    chainAlias={chain.alias}
                    chainName={chain.name}
                    dapiName={dapiName}
                    date={to}
                    deviationThreshold={deviationThreshold}
                  />
                </div>
                <div className={clsx('grid-item-right', { highlighted: index === 0 })} data-testid="update-parameters">
                  <div>{formatDeviation(deviationThreshold)} deviation</div>
                  <div>{formatHeartbeat(heartbeatInterval)} heartbeat</div>
                </div>
              </>
            ))
          )}
        </div>

        {chain.testnet && (
          <div className="testnet-warning">
            <ExclamationTriangleIcon className="warning-icon" />
            <div>
              Testnet infrastructure is less reliable than production environments, so you might encounter discrepancies
              in the information displayed.
            </div>
          </div>
        )}

        <Button
          href={activateUrl}
          variant="secondary"
          color="gradient"
          size="small"
          sm={{ size: 'medium' }}
          className="upgrade-button"
          fullWidth
          iconEnd={null}
          disabled={purchasePlanButtonDisabled}
          tooltip={purchasePlanButtonDisabled ? `This feed is no longer available on ${chain.name}` : undefined}
        >
          Purchase new plan
        </Button>
      </Parameters>

      <DiscordButton />
    </Container>
  );
};

const UpdateParametersTooltip = () => (
  <StyledTooltipContent>
    <div>
      <span className="bold">Deviation</span> describes the difference between the on-chain value and the reported
      off-chain value. When the difference exceeds the defined threshold, an update will be triggered.
    </div>
    <div>
      <span className="bold">Heartbeat</span> describes the minimum interval when the update will be triggered in case
      the deviation threshold was not reached in the heartbeat interval.
    </div>
  </StyledTooltipContent>
);

const StyledTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 200px;

  .bold {
    font-weight: 600;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 64px;

  @media (min-width: ${breakpoints.sm}px) {
    margin-top: 80px;
  }

  @media (min-width: ${breakpoints.md}px) {
    gap: 32px;
    margin-top: 64px;
  }
`;

const Parameters = styled.div`
  border-radius: 10px;
  border: 1px solid var(--color-dark-blue-200);
  width: 100%;
  max-width: 328px;
  padding: 32px 16px;
  background: var(--color-dark-blue-700);

  h3 {
    ${fonts.heading[9]};
    text-align: center;
    margin: 0 0 24px 0;
  }

  .active-plans-grid {
    display: grid;
    grid-template-columns: 62% 38%;
    ${fonts.body[12]};
    color: var(--color-gray-400);
    margin: 0 8px;

    .grid-header-left {
      text-align: left;
    }

    .grid-header-left,
    .grid-header-right {
      ${fonts.body[15]};
      color: var(--color-dark-blue-25);
      padding: 8px 0;
    }

    .grid-item-left {
      text-align: left;
      padding: 16px 0;
      border-top: 1px solid var(--color-neutral-gray8);
    }

    .grid-item-right {
      text-align: right;
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-top: 1px solid var(--color-neutral-gray8);
    }

    .highlighted {
      color: var(--color-base-light);
      border-top: none;
    }

    .skeleton-right {
      text-align: right;
    }

    .parameters-title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
    }

    .help-icon {
      width: 14px;
      height: 14px;
      margin-left: 4px;
      margin-top: 2px;
      color: var(--color-dark-blue-50);
    }

    .heartbeat-skeleton {
      height: 13px;
      margin-bottom: 8px;
    }

    .deviation-skeleton {
      height: 13px;
      width: 80px;
    }

    .expiring-skeleton {
      height: 17px;
    }
  }

  .expiring-timestamp {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 6px;

    & > div {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  .testnet-warning {
    display: flex;
    align-items: start;
    gap: 8px;
    font-size: 12px;
    line-height: 140%;
    color: var(--color-neutral-gray5);
  }

  .warning-icon {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
  }

  .upgrade-button {
    margin-top: 40px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    h3 {
      ${fonts.heading[7]};
    }

    max-width: unset;
    width: 428px;
    padding: 40px;

    .active-plans-grid {
      ${fonts.body[9]};

      .grid-header-left,
      .grid-header-right {
        ${fonts.body[12]};
      }

      .grid-item-right,
      .grid-item-left {
        padding: 24px 0;
        gap: 4px;
      }

      & .help-icon {
        margin-left: 2px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    width: 344px;
    padding: 32px 16px;
    border-radius: 16px;

    h3 {
      ${fonts.heading[9]};
      margin-bottom: 32px;
    }

    .active-plans-grid {
      ${fonts.body[12]};

      margin-bottom: 8px;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 428px;
    padding: 40px;

    h3 {
      ${fonts.heading[7]};
    }

    .active-plans-grid {
      font-size: 16px;

      .grid-header-left,
      .grid-header-right {
        font-size: 14px;
      }

      .heartbeat-skeleton {
        height: 18px;
      }

      .deviation-skeleton {
        height: 18px;
        width: 110px;
      }

      .expiring-skeleton {
        height: 22px;
      }
    }
  }
`;
