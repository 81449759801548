import { Suspense, lazy } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { VerificationStatus } from 'types';
import { CodeSnippetSkeleton } from './components/code-snippet-skeleton';
import { ProxyParamsBox } from './components/proxy-params-box';

const CodeSnippet = lazy(() => import('./components/code-snippet'));

interface Props {
  proxyAddress?: string;
  verificationStatus: VerificationStatus;
}

export const IntegratePageContent = ({ proxyAddress, verificationStatus }: Props) => {
  return (
    <Container className="code-snippet-view">
      <ProxyParamsBox proxyAddress={proxyAddress} verificationStatus={verificationStatus} />
      <Suspense fallback={<CodeSnippetSkeleton />}>
        <CodeSnippet isAlwaysExpanded />
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  &.code-snippet-view {
    gap: 0;
  }

  @media (min-width: ${breakpoints.sm}px) {
    gap: 40px;

    &.code-snippet-view {
      margin-top: 0;
    }
  }
`;
