import { ethers } from 'ethers';
import { toasts } from 'components/notifications';
import { walletMessages } from 'constants/walletMessages';
import { go } from '@api3/promise-utils';
import { encodeDapiName, getApi3Market, computeProxyAddress } from 'utils/contracts';
import { DEFAULT_PROXY_DAPP_ID, DEFAULT_PROXY_METADATA } from 'constants/constants';

const deployNewProxy = async (
  chainId: string,
  dapiNameHash: string,
  signer: ethers.Signer,
  dappId: number,
  metadata: string
) => {
  const api3Market = await getApi3Market(chainId, signer);
  const tx = await api3Market.deployApi3ReaderProxyV1(dapiNameHash, dappId, metadata);

  // Show pending toast after the transaction has been signed
  toasts.info({
    message: walletMessages.TX_PENDING,
  });

  // Wait for the transaction to be finalized. Throw error if the transaction fails
  const receipt = await tx.wait();

  if (!receipt) {
    throw new Error('Transaction failed');
  }
};

export const deployProxy = async (
  chainId: string,
  dapiName: string,
  provider: ethers.Provider,
  signer: ethers.Signer,
  dappId = DEFAULT_PROXY_DAPP_ID,
  metadata = DEFAULT_PROXY_METADATA
) => {
  const computedProxy = await computeProxyAddress(chainId, dapiName, provider);
  if (computedProxy.deployed) {
    return computedProxy;
  }

  const result = await go(() => deployNewProxy(chainId, encodeDapiName(dapiName), signer, dappId, metadata));

  if (!result.success) {
    if ((result.error as any)?.code === 'ACTION_REJECTED') {
      toasts.info({
        message: walletMessages.TX_GENERIC_REJECTED,
      });
      return { proxyAddress: '', error: false };
    }

    return { proxyAddress: '', error: true };
  }

  toasts.success({
    message: walletMessages.TX_GENERIC_COMPLETED,
  });

  return { proxyAddress: computedProxy.proxyAddress, error: false };
};
