import styled from 'styled-components';
import { keepLastTwoWordsTogether } from 'utils/utils';

interface Props {
  name: string;
  className?: string;
  color?: 'gray' | 'dark-blue' | 'gray-400' | 'blue';
}

export const DapiName = ({ name, className, color = 'gray' }: Props) => {
  const dapiNameParts = name?.split('/');

  const StyledSpan = color === 'gray' ? Gray : color === 'gray-400' ? Gray400 : color === 'blue' ? Blue : DarkBlue;

  return (
    <StyledSpan className={className}>
      {dapiNameParts[0]}
      <span className="dapi-name-slash">/</span>
      {keepLastTwoWordsTogether(dapiNameParts[1])}
    </StyledSpan>
  );
};

const Gray = styled.div`
  color: var(--color-base-light);

  & .dapi-name-slash {
    color: var(--color-gray-600);
  }
`;

const Gray400 = styled.div`
  color: var(--color-base-light);

  & .dapi-name-slash {
    color: var(--color-gray-400);
  }
`;

const DarkBlue = styled.div`
  color: var(--color-dark-blue-900);

  & .dapi-name-slash {
    color: var(--color-dark-blue-25);
  }
`;

const Blue = styled.div`
  color: var(--color-base-light);

  & .dapi-name-slash {
    color: var(--color-dark-blue-25);
  }
`;
