import { ComponentProps } from 'react';

export const DisconnectIcon = (props: ComponentProps<'svg'>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.4991 2V12M18.8591 6.64C20.1175 7.89879 20.9744 9.50244 21.3214 11.2482C21.6685 12.9939 21.4901 14.8034 20.8088 16.4478C20.1275 18.0921 18.974 19.4976 17.494 20.4864C16.014 21.4752 14.2741 22.0029 12.4941 22.0029C10.7142 22.0029 8.9743 21.4752 7.49432 20.4864C6.01434 19.4976 4.86079 18.0921 4.17951 16.4478C3.49823 14.8034 3.31983 12.9939 3.66686 11.2482C4.01389 9.50244 4.87077 7.89879 6.12914 6.64"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
