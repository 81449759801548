import styled from 'styled-components';
import { breakpoints, threeCardsBreakpoint } from 'styles/theme';
import { useSimpleSearchParam } from 'hooks/use-simple-search-param';
import { filterDapisBySearchQuery } from 'utils/dapi-search';
import { ChainDapiSkeleton } from './chain-dapi-card';
import { ChainDApisSuggestions } from './chain-dapis-suggestions';
import { ChainDApisSearchResult } from './chain-search-result/chain-dapis-search-result';

const HIGHLIGHTED_DAPIS_COUNT = 4;

interface Props {
  activeFeeds: string[];
  isError: boolean;
  isLoading: boolean;
}

export const ChainDApisSection = (props: Props) => {
  const { activeFeeds, isError, isLoading } = props;
  const { searchParamQueryValue } = useSimpleSearchParam();

  const filteredFeeds = searchParamQueryValue ? filterDapisBySearchQuery(activeFeeds, searchParamQueryValue) : [];

  if (isLoading) {
    return (
      <Container>
        <ul className="chain-dapis-list loading">
          {Array.from({ length: HIGHLIGHTED_DAPIS_COUNT }).map((_, i) => (
            <ChainDapiSkeleton key={i} />
          ))}
        </ul>
      </Container>
    );
  }

  // No active search
  if (!searchParamQueryValue) {
    return (
      <Container>
        <ChainDApisSuggestions activeFeeds={activeFeeds} isError={isError} />
      </Container>
    );
  }

  // Active search
  return (
    <Container>
      <ChainDApisSearchResult activeFeeds={filteredFeeds} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 328px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 40px;
  margin: 0 auto;

  .chain-dapis-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    padding: 0;

    &.loading {
      margin-top: 200px;
    }
  }

  @media (min-width: ${threeCardsBreakpoint}px) {
    max-width: 500px;
  }

  @media (min-width: ${breakpoints.md}px) {
    gap: 48px;
    max-width: 1008px;

    .chain-dapis-list {
      gap: 40px;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    max-width: 1332px;
  }
`;
