import { ComponentProps } from 'react';
import clsx from 'clsx';

export const ExclamationTriangleIcon = ({ className, ...rest }: ComponentProps<'svg'>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      className={clsx('error-icon', className)}
      {...rest}
    >
      <path
        d="M7.90653 4.99989V7.66661M7.90653 8.99998H7.9132M8.46782 1.53579L13.8877 10.3372C14.2456 10.9183 13.8275 11.6667 13.145 11.6667H2.27244C1.58892 11.6667 1.17102 10.9162 1.53099 10.3351L6.98368 1.53379C7.32526 0.982431 8.12773 0.983512 8.46782 1.53579Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
