import { Helmet } from 'react-helmet-async';
import { canUseAnalytics } from 'utils/analytics';

export const UserTracking = () => {
  if (!canUseAnalytics() || process.env.VITE_APP_CF_ENV !== 'Production') {
    return null;
  }

  // Inform clarity about user's consent
  window.clarity?.('consent');

  return (
    <Helmet>
      <script>
        {`
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "ktlt98sgke");
        `}
      </script>
    </Helmet>
  );
};
