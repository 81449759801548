import { css } from 'styled-components';
import { ButtonLinkStyle } from '../sizes';

const Gray = css`
  color: var(--color-blue-25);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-500);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-300);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-gray-700);
  }
`;

export const LinkSecondaryVariantStyle = css`
  ${ButtonLinkStyle}

  &.gray {
    ${Gray}
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }

  &.no-border {
    border: none !important;
  }
`;
