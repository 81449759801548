import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

export const StatusActiveIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <StyledIcon
      id="eBuIiX4gWOc1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 45 45"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="45"
      height="45"
      {...props}
    >
      <defs>
        <linearGradient
          id="eBuIiX4gWOc7-fill"
          x1="0"
          y1="-8"
          x2="0"
          y2="-0.1686"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eBuIiX4gWOc7-fill-0" offset="0%" stopColor="#dcfbf3" />
          <stop id="eBuIiX4gWOc7-fill-1" offset="100%" stopColor="#48e8c0" />
        </linearGradient>
        <linearGradient
          id="eBuIiX4gWOc15-fill"
          x1="0"
          y1="-8"
          x2="0"
          y2="-0.1686"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eBuIiX4gWOc15-fill-0" offset="0%" stopColor="#dcfbf3" />
          <stop id="eBuIiX4gWOc15-fill-1" offset="100%" stopColor="#48e8c0" />
        </linearGradient>
      </defs>
      <g transform="translate(1.5 1.496099)">
        <g id="eBuIiX4gWOc11_ts" transform="translate(21,21.0039) scale(0.666666,0.666666)">
          <circle id="eBuIiX4gWOc11" r="12" transform="translate(0,0)" opacity="0.5" fill="none" stroke="#48e8c0" />
        </g>
        <g id="eBuIiX4gWOc12_ts" transform="translate(21,21.0039) scale(0.666666,0.666666)">
          <circle id="eBuIiX4gWOc12" r="12" transform="translate(0,0)" opacity="0.5" fill="none" stroke="#48e8c0" />
        </g>
        <g id="eBuIiX4gWOc13_ts" transform="translate(21,21.0039) scale(0.666666,0.666666)">
          <circle id="eBuIiX4gWOc13" r="12" transform="translate(0,0)" opacity="0.5" fill="none" stroke="#48e8c0" />
        </g>
        <g id="eBuIiX4gWOc14_ts" transform="translate(21,21.0039) scale(0.666666,0.666666)">
          <circle id="eBuIiX4gWOc14" r="12" transform="translate(0,0)" opacity="0.5" fill="none" stroke="#48e8c0" />
        </g>
        <circle r="8" transform="translate(21 21)" fill="url(#eBuIiX4gWOc15-fill)" />
      </g>
    </StyledIcon>
  );
};

const StyledIcon = styled.svg`
  #eBuIiX4gWOc12 {
    animation: eBuIiX4gWOc12_c_o 1800ms linear infinite normal forwards;
  }
  @keyframes eBuIiX4gWOc12_c_o {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.5;
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    72.222222% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  #eBuIiX4gWOc13_ts {
    animation: eBuIiX4gWOc13_ts__ts 1800ms linear infinite normal forwards;
  }
  @keyframes eBuIiX4gWOc13_ts__ts {
    0% {
      transform: translate(21px, 21.0039px) scale(0.666666, 0.666666);
    }
    16.666667% {
      transform: translate(21px, 21.0039px) scale(0.666666, 0.666666);
      animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    }
    50% {
      transform: translate(21px, 21.0039px) scale(1.596769, 1.59677);
    }
    100% {
      transform: translate(21px, 21.0039px) scale(1.596769, 1.59677);
    }
  }
  #eBuIiX4gWOc13 {
    animation: eBuIiX4gWOc13_c_o 1800ms linear infinite normal forwards;
  }
  @keyframes eBuIiX4gWOc13_c_o {
    0% {
      opacity: 0.5;
    }
    33.333333% {
      opacity: 0.5;
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    55.555556% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  #eBuIiX4gWOc14_ts {
    animation: eBuIiX4gWOc14_ts__ts 1800ms linear infinite normal forwards;
  }
  @keyframes eBuIiX4gWOc14_ts__ts {
    0% {
      transform: translate(21px, 21.0039px) scale(0.666666, 0.666666);
      animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(21px, 21.0039px) scale(1.596769, 1.59677);
    }
    100% {
      transform: translate(21px, 21.0039px) scale(1.596769, 1.59677);
    }
  }

  #eBuIiX4gWOc14 {
    animation: eBuIiX4gWOc14_c_o 1800ms linear infinite normal forwards;
  }
  @keyframes eBuIiX4gWOc14_c_o {
    0% {
      opacity: 0.5;
    }
    16.666667% {
      opacity: 0.5;
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    38.888889% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;
