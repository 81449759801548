import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { usePageParams } from 'hooks';
import { isDeprecatedDataFeed } from 'utils/dapis';
import { useChain } from 'hooks/chain-data';
import { VerificationStatus } from 'types';
import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { walletMessages } from 'constants/walletMessages';
import { computeProxyAddress } from 'utils/contracts';
import { breakpoints } from 'styles/theme';
import { IntegratePageHeader } from './integrate-page-header';
import { IntegratePageContent } from './integrate-page-content';
import { deployProxy } from './utils/deploy-proxy';

const IntegratePage = () => {
  const navigate = useNavigate();
  const { chainAlias, dapiName = '' } = usePageParams();
  const { setErrorBanner } = useNotificationBannerContext();
  const { chain, provider, signer } = useChain(chainAlias);
  const [proxy, setProxy] = useState<string>();
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>('not-requested');
  const [isDeploying, setIsDeploying] = useState(false);

  useEffect(() => {
    if (!chain || !provider || dapiName === '') return;

    if (isDeprecatedDataFeed(chain.id, dapiName)) {
      navigate('/404', { replace: true });
      return;
    }

    const setProxyAddress = async () => {
      setVerificationStatus('verifying');

      const { success, deployed, proxyAddress } = await computeProxyAddress(chain.id, dapiName, provider);

      if (!success) {
        setErrorBanner({ message: 'Failed to verify the proxy', secondaryMessage: 'Try again later' });
        setVerificationStatus('not-verified');
        return;
      }

      setVerificationStatus(deployed ? 'verified' : 'not-verified');
      setProxy(proxyAddress);
    };

    setProxyAddress();
  }, [dapiName, chain, provider]);

  const handleDeployProxy = async () => {
    if (!chain || !provider || !signer || dapiName === '') return;

    setIsDeploying(true);
    const { error, proxyAddress } = await deployProxy(chain.id, dapiName, provider, signer);
    setIsDeploying(false);

    if (error) {
      // Something went wrong, and it's not a user rejection
      setErrorBanner({
        message: walletMessages.TX_GENERIC_ERROR_MESSAGE,
        secondaryMessage: walletMessages.TX_GENERIC_ERROR_RELOAD,
      });
    } else {
      setProxy(proxyAddress);
    }
  };

  return (
    <div>
      <Helmet>
        <title>API3 Market - Integrate dAPI</title>
      </Helmet>
      <Container className="integrate-page">
        <IntegratePageHeader
          displayButton={verificationStatus === 'not-verified'}
          deploying={isDeploying}
          onButtonClick={handleDeployProxy}
        />
        <IntegratePageContent proxyAddress={proxy} verificationStatus={verificationStatus} />
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 814px;
  min-width: 200px;
  margin: 8px auto 64px auto;

  @media (min-width: ${breakpoints.md}px) {
    margin-top: 0;
  }
`;

export default IntegratePage;
