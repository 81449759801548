import { ComponentProps } from 'react';

export const ArrowLeft = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.875 10H14.125M5.875 10L9.3125 6.5625M5.875 10L9.3125 13.4375"
        stroke="#FAFAFA"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
