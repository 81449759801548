import { ComponentProps } from 'react';

export const CopyIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.15789 4.54049V3.44737C5.15789 2.92415 5.58205 2.5 6.10526 2.5H13.0526C13.5758 2.5 14 2.92415 14 3.44737V10.3947C14 10.918 13.5758 11.3421 13.0526 11.3421H11.9595M2 6.60526V13.5526C2 14.0758 2.42415 14.5 2.94737 14.5H9.89474C10.418 14.5 10.8421 14.0758 10.8421 13.5526V6.60526C10.8421 6.08205 10.418 5.65789 9.89474 5.65789H2.94737C2.42415 5.65789 2 6.08205 2 6.60526Z"
        stroke="currentColor"
      />
    </svg>
  );
};
