import { css } from 'styled-components';
import { NOT_IMPLEMENTED } from 'styles/theme';
import { ButtonTertiaryStyle } from '../sizes';

const Gray = css`
  border: 1px solid;
  border-color: var(--color-gray-500);
  color: var(--color-gray-300);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-gray-500);
    color: var(--color-base-light);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-gray-500);
    color: var(--color-gray-25);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    border-color: var(--color-gray-700);
    color: var(--color-gray-700);
  }
`;

const Blue = css`
  border: 1px solid;
  border-color: var(--color-blue-400);
  color: var(--color-blue-400);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-blue-200);
    color: var(--color-blue-200);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-blue-500);
    color: var(--color-blue-500);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    border-color: var(--color-blue-700);
    color: var(--color-blue-700);
  }
`;

export const TertiaryVariantStyle = css`
  ${ButtonTertiaryStyle}

  &.gray {
    ${Gray}
  }

  &.blue {
    ${Blue}
  }

  &:not(.gray):not(.blue) {
    ${NOT_IMPLEMENTED}
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }

  &.no-border {
    border: none !important;
  }
`;
