import { css } from 'styled-components';
import { breakpoints } from 'styles/theme';

const XSmall = css`
  min-height: 40px;
  height: 40px;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;

  border: none;

  &.icon-only {
    padding: 8px;
  }
`;

const Small = css`
  min-height: 46px;
  height: 46px;
  padding: 11px 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;

  &.icon-only {
    padding: 11px;
  }
`;

const Medium = css`
  min-height: 58px;
  height: 58px;
  padding: 17px 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;

  &.icon-only {
    padding: 17px;
  }
`;

const Large = css`
  min-height: 64px;
  height: 64px;
  padding: 20px 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;

  &.icon-only {
    padding: 20px;
  }
`;

export const ButtonTertiaryStyle = css`
  @media (max-width: ${breakpoints.xs}px) {
    ${XSmall}
  }
  @media (max-width: ${breakpoints.sm}px) {
    ${Small}
  }
  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md}px) {
    ${Medium}
  }
  @media (min-width: ${breakpoints.md}px) {
    ${Large}
  }

  // Override default size if specific size is provided
  &.x-small {
    ${XSmall}
  }
  &.small {
    ${Small}
  }
  &.medium {
    ${Medium}
  }
  &.large {
    ${Large}
  }
`;
