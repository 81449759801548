import { KeyboardEvent, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { ChevronDown } from 'components/shared/icons';
import { fonts } from 'styles/fonts';

interface Props {
  children: ReactNode;
  txInProgress: boolean;
}

export const OrderDetailsWrapper = ({ children, txInProgress }: Props) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((s) => !s);

  const handleKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      toggleOpen();
    }
  };

  return txInProgress ? (
    <Container>
      <div
        className={clsx('order-details-header', { open })}
        role="button"
        tabIndex={0}
        onKeyUp={handleKeyUp}
        onClick={toggleOpen}
      >
        <h2>Order Details</h2>
        <ChevronDown />
      </div>
      {open && children}
    </Container>
  ) : (
    children
  );
};

const Container = styled.div`
  .order-details-header {
    border-bottom: 1px solid var(--color-dark-blue-400);
    padding: 8px 0px 16px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  h2 {
    ${fonts.body[8]}
    margin: 0;
  }

  svg {
    width: 16px;
    height: 16px;
    transition: var(--animation-flip-chevron);
  }

  .open {
    svg {
      transform: rotate(180deg);
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    .order-details-header {
      padding-top: 16px;
    }

    h2 {
      font-size: 20px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .order-details-header {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    h2 {
      ${fonts.subtitle[5]}
    }
  }
`;
