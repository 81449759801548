import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

export const ErrorFallback = () => {
  return (
    <FallbackContainer>
      <h1>Something went wrong</h1>
      <p>Try reloading the page or try again in a couple of minutes.</p>
    </FallbackContainer>
  );
};

const FallbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 400px);
  padding: 1rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
    color: var(--color-neutral-gray3);
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }

  @media (min-width: ${breakpoints.sm}px) {
    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 18px;
    }
  }
`;
