import { API3_CHAINS, HIDDEN_CHAINS, activeOrDeprecatedChainIds } from 'utils/dapis';
import { useMemo } from 'react';
import { checkPhraseMatchesSearchQuery } from 'utils/dapi-search';
import { usePageParams } from './use-page-params';

export const useAllChains = (searchValue?: string) => {
  const { showHidden } = usePageParams();

  return useMemo(() => {
    const activeOrDeprecatedChains = API3_CHAINS.filter((chain) => {
      const isActiveOrDeprecated = !!activeOrDeprecatedChainIds[chain.id];

      if (showHidden) {
        return isActiveOrDeprecated;
      }

      return !HIDDEN_CHAINS.includes(chain.alias) && isActiveOrDeprecated;
    });

    const allChains = searchValue
      ? activeOrDeprecatedChains.filter((chain) => checkPhraseMatchesSearchQuery(chain.name, searchValue))
      : activeOrDeprecatedChains;

    return { allChains };
  }, [showHidden, searchValue]);
};
