import { create } from 'zustand';
import { FallbackProvider } from 'ethers';

type ProviderState = 'uninitialized' | 'initializing' | 'initialized' | 'error';

interface Props {
  providers: Record<string, FallbackProvider | undefined>;
  providerState: Record<string, ProviderState>;
  setProviderState: (chainId: string, state: ProviderState) => void;
  setProviderForChain: (chainId: string, provider: FallbackProvider) => void;
}

export const useProviderStore = create<Props>()((set) => ({
  providers: {},
  providerState: {},
  setProviderState: (chainId, providerState) => {
    set((state) => ({
      providerState: {
        ...state.providerState,
        [chainId]: providerState,
      },
    }));
  },
  setProviderForChain: (chainId, provider) => {
    set((state) => ({
      providers: {
        ...state.providers,
        [chainId]: provider,
      },
    }));
  },
}));
