import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { go } from '@api3/promise-utils';
import { toasts } from 'components/notifications';
import { useConnectedAccount } from './use-connected-account';
import { useDisconnect } from './use-disconnect';

interface ChainAlysisResponse {
  identifications: any[];
}

// Check if connected account is on sanctions lists. We are using Chainalysis API (https://public.chainalysis.com/docs/index.html)
// to check if the address is sanctioned. Because of their CORS policy, we are proxying the request through Cloudflare Worker.
export const useCheckSanctionedAddress = () => {
  const { connectedAddress } = useConnectedAccount();
  const disconnect = useDisconnect();

  useEffect(() => {
    const checkIfAddressIsSanctioned = async (address: string) => {
      const result = await go(() => fetch(`https://sanctioned-addresses-api.api3dao.workers.dev/?address=${address}`));

      if (result.error) {
        Sentry.captureException(new Error(`Error checking if address is sanctioned: ${result.error}`));
        return;
      }

      const response: ChainAlysisResponse = await result.data.json();

      if (response.identifications.length > 0) {
        toasts.error({
          message: 'API3 is unable to serve this address.',
        });
        disconnect();
      }
    };

    if (connectedAddress) {
      checkIfAddressIsSanctioned(connectedAddress);
    }
  }, [connectedAddress]);
};
