import styled from 'styled-components';
import clsx from 'clsx';
import { breakpoints } from 'styles/theme';
import { formatPrice, formatSubscriptionPeriod } from 'utils/format';
import { api3Chains } from '@api3/dapi-management';
import { Subscription } from 'types';
import { fonts } from 'styles/fonts';

interface Props {
  subscription: Subscription;
  chain: api3Chains.Chain;
}

export const PriceBox = ({ subscription, chain }: Props) => {
  const { discountedPrice, discount, duration, price } = subscription;

  return (
    <Container className={clsx({ 'with-discount': discount && discountedPrice })}>
      {discountedPrice && discount && BigInt(discount) !== 0n && (
        <StyledDiscount>
          <div className="row">
            <div>Price</div>
            <div>
              {formatPrice(price)}
              <span className="currency">{chain.symbol}</span>
            </div>
          </div>
          <div className="row" data-testid="discount">
            <div>Order Discount</div>
            <div>
              -{formatPrice(discount)}
              <span className="currency">{chain.symbol}</span>
            </div>
          </div>
          <div className="row note">Adjustment based on the funds remaining from previous orders</div>
        </StyledDiscount>
      )}
      <div className="row" data-testid="total-price">
        <div className="label">
          <span className="emphasized">Total</span> for {formatSubscriptionPeriod(duration)}
        </div>
        <div className="price">
          <span className="emphasized">{formatPrice(discountedPrice || price)}</span>
          <span className="currency">{chain.symbol}</span>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 16px 12px 24px 12px;
  border-radius: 8px;
  background-color: var(--color-dark-blue-800);
  color: var(--color-dark-blue-25);
  ${fonts.body[12]}

  .row {
    display: flex;
    justify-content: space-between;
  }

  .emphasized {
    ${fonts.body[10]}
    color: var(--color-base-light);
  }

  .currency {
    font-size: 8px;
    padding-left: 4px;
    color: var(--color-base-light);
  }

  &.with-discount {
    padding: 24px 12px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    padding: 16px 24px 24px 24px;

    &.with-discount {
      padding: 24px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    ${fonts.body[6]}
    padding: 24px 24px 32px 24px;

    &.with-discount {
      padding: 32px 24px;
    }

    .emphasized {
      ${fonts.body[4]}
    }

    .price {
      font-size: 18px;
    }

    .currency {
      font-size: 12px;
    }
  }
`;

const StyledDiscount = styled.div`
  color: var(--color-blue-10);
  ${fonts.body[15]}
  border-bottom: 1px solid var(--color-dark-blue-500);
  padding-bottom: 8px;
  margin-bottom: 16px;

  .row {
    padding-bottom: 8px;
  }

  .currency {
    ${fonts.overline[2]}
  }

  .note {
    color: var(--color-dark-blue-25);
    ${fonts.overline[2]}
  }

  @media (min-width: ${breakpoints.md}px) {
    ${fonts.body[9]}
    padding-bottom: 16px;
    margin-bottom: 24px;

    .currency {
      ${fonts.overline[1]}
    }

    .note {
      ${fonts.body[12]}
      max-width: 300px;
    }
  }
`;
