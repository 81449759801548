import styled from 'styled-components';
import { ExclamationTriangleFullIcon } from 'components/shared/icons';
import { breakpoints } from 'styles/theme';
import { fonts } from 'styles/fonts';

interface Props {
  title: string;
  subtitle?: string;
}

export const WarningBanner = ({ title, subtitle }: Props) => {
  return (
    <Container>
      <div className="banner-content">
        <ExclamationTriangleFullIcon />

        <div className="message">
          <span className="title">{title}</span>
          {subtitle && <span className="subtitle">{subtitle}</span>}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 100%;

  .banner-content {
    display: flex;
    justify-content: center;
    gap: 8px;
    width: 100%;
  }

  svg {
    color: var(--color-action-warning-700);
    margin-top: -2px;
    min-width: 22px;
    min-height: 22px;
  }

  .message {
    display: flex;
    flex-direction: column;
  }

  .title {
    ${fonts.body[10]};
    padding-right: 0.5ch;
  }

  .subtitle {
    ${fonts.link[3]};
  }

  @media (min-width: ${breakpoints.sm}px) {
    svg {
      min-width: 26px;
      min-height: 26px;
    }

    .banner-content {
      height: 24px;
      align-items: center;
    }

    .message {
      flex-direction: row;
      align-items: baseline;
    }

    .title {
      ${fonts.body[8]};
    }

    .subtitle {
      ${fonts.link[2]};
    }
  }
`;
