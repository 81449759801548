import { css } from 'styled-components';
import { gradientBorder, NOT_IMPLEMENTED } from 'styles/theme';
import { ButtonDefaultStyle } from '../sizes/default';

const Gray = css`
  border: 1px solid;
  border-color: var(--font-color-white);
  color: var(--color-neutral-gray3);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-blue-200);
    color: var(--color-blue-200);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-blue-300);
    color: var(--color-blue-300);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    border-color: var(--color-neutral-gray8);
    color: var(--color-neutral-gray7);
  }
`;

const Blue = css`
  border: 1px solid;
  border-color: var(--color-blue-400);
  color: var(--color-blue-400);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-blue-200);
    color: var(--color-blue-200);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-blue-500);
    color: var(--color-blue-500);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    border-color: var(--color-blue-700);
    color: var(--color-blue-700);
  }
`;

const Gradient = css`
  color: var(--color-base-light);
  ${gradientBorder('general-soft')};

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-200);

    &:before {
      background: var(--color-blue-200);
    }
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-300);

    &:before {
      background: var(--color-blue-300);
    }
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    border-color: var(--color-blue-700);
    color: var(--color-gray-600);
    ${gradientBorder('general-soft-disable')};
  }
`;

const WhiteLightTheme = css`
  border: 1px solid;
  border-color: var(--color-blue-500);
  color: var(--color-blue-500);
  background: var(--color-base-light);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-blue-200);
    color: var(--color-blue-200);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    border-color: var(--color-blue-600);
    color: var(--color-blue-600);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    border-color: var(--color-blue-50);
    color: var(--color-blue-50);
  }
`;

export const SecondaryVariantStyle = css`
  ${ButtonDefaultStyle}

  &.gray {
    ${Gray}
  }

  &.blue {
    ${Blue}
  }

  &.white-light-theme {
    ${WhiteLightTheme}
  }

  &.gradient {
    ${Gradient}
  }

  &:not(.gray):not(.blue):not(.white-light-theme):not(.gradient) {
    ${NOT_IMPLEMENTED}
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }

  &.no-border {
    border: none !important;
  }
`;
