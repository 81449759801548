import { useRef, useState } from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';
import { abbrStr } from 'utils/utils';
import Jazzicon from 'react-jazzicon';
import { CaretDown } from 'components/shared/icons';
import { useConnectedAccount } from 'hooks/chain-data';
import { useWindowDimensions } from 'hooks';
import { breakpoints } from 'styles/theme';
import { WalletDetails } from './wallet-details';

interface Props {
  onMobileClick: () => void;
}

export const HeaderWallet = ({ onMobileClick }: Props) => {
  const { connectedAddress, connectedAccountName } = useConnectedAccount();
  const { isTablet } = useWindowDimensions();

  const [open, setOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const jazziconSeed = parseInt(connectedAddress || '', 16);

  return (
    <Container ref={containerRef}>
      <Popover.Root open={open} onOpenChange={isTablet ? () => onMobileClick() : setOpen}>
        <StyledTrigger>
          <div className="trigger-user-info">
            {isTablet ? (
              <Jazzicon diameter={24} seed={jazziconSeed} />
            ) : (
              <>
                <Jazzicon diameter={32} seed={jazziconSeed} />
                {connectedAccountName || abbrStr(connectedAddress || '', 5)}
                <CaretDown width={24} height={24} className="trigger-chevron" />
              </>
            )}
            <span className="sr-only">Connected account</span>
          </div>
        </StyledTrigger>

        <Popover.Portal container={containerRef.current}>
          <StyledPopoverContent
            ref={contentRef}
            side="bottom"
            sideOffset={5}
            onOpenAutoFocus={(ev) => {
              // We don't want to focus the disconnect button (the default behaviour)
              ev.preventDefault();
              contentRef.current!.focus();
            }}
          >
            <WalletDetails onDisconnect={() => setOpen(false)} />
          </StyledPopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  justify-content: center;

  > button {
    padding: 0;
  }
`;

const StyledTrigger = styled(Popover.Trigger)`
  justify-content: center;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 6px;
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.3px;
  gap: 8px;

  .trigger-user-info {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 4px 0 4px 8px;
  }

  .trigger-chevron {
    color: var(--font-color-white);
    transition: transform 0.2s ease-in-out;
  }

  &[data-state='open'] {
    .trigger-chevron {
      transform: rotate(180deg);
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .trigger-user-info {
      padding: 7px 0 7px 8px;
    }
  }
`;

const StyledPopoverContent = styled(Popover.Content)`
  background-color: var(--color-neutral-gray9);
  z-index: var(--z-index-dropdown);
  border-radius: 10px;
  margin-right: 12px;

  &[data-state='open'] {
    animation: var(--animation-slide-down);
  }

  &:not([data-state='open']) {
    animation: var(--animation-slide-up);
  }
`;
