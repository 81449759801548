import styled from 'styled-components';
import Skeleton from 'components/shared/skeleton';
import { IntegrateFlowBox } from './integrate-flow-box';

export const CodeSnippetSkeleton = () => {
  return (
    <IntegrateFlowBox
      title="Example integration code snippet"
      subtitle="How to read the data feed via a proxy address"
      isAlwaysExpanded
    >
      <Container className="snippet-loading">
        <Skeleton height="200px" width="100%" enableAnimation />
      </Container>
    </IntegrateFlowBox>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;

  // Full width skeleton
  > span {
    width: 100%;
  }
`;
