import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { usePrevious } from 'hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { WarningBanner } from './warning-banner';

export const ExpiredBanner = () => {
  const { setBanner } = useNotificationBannerContext();
  const location = useLocation();

  const hideBanner = () => {
    setBanner({ content: null, type: 'expired' });
  };

  const initialPath = usePrevious(location.pathname);

  useEffect(() => {
    if (location.pathname !== initialPath && initialPath) {
      hideBanner();
    }
  }, [location.pathname, initialPath]);

  return (
    <WarningBanner
      title="This feed has expired and will soon stop updating."
      subtitle="Purchase a new plan to avoid interruption."
    />
  );
};
