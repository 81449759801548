import { Helmet } from 'react-helmet-async';
import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { usePageParams } from 'hooks';
import { useChain } from 'hooks/chain-data';
import { ActivateDataFeedSection } from './activate-data-feed-section';

const PurchasePage = () => {
  const { chainAlias, dapiName } = usePageParams();
  const { chain } = useChain(chainAlias);

  if (!chain || !dapiName) return null; // Just TS guard

  return (
    <StyledWrapper>
      <Helmet>
        <title>API3 Market - Activate dAPI</title>
      </Helmet>
      <ActivateDataFeedSection chain={chain} dapiName={dapiName} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.md}px) {
    justify-content: end;
  }
`;

export default PurchasePage;
