import { css } from 'styled-components';
import { NOT_IMPLEMENTED } from 'styles/theme';
import { ButtonMenuLinkStyle } from '../sizes';

const Gray = css`
  color: var(--color-base-light);

  > svg {
    color: var(--color-base-light);
  }

  &:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    &:hover,
    &:active,
    &.active {
      color: var(--color-green-500);

      > svg {
        color: var(--color-green-500);
      }
    }

    &:focus {
      color: var(--color-green-400);

      > svg {
        color: var(--color-green-400);
      }
    }
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-gray-700);

    > svg {
      color: var(--color-gray-700);
    }
  }
`;

export const MenuLinkVariantStyle = css`
  ${ButtonMenuLinkStyle}

  &.gray {
    ${Gray}
  }

  &:not(.gray) {
    ${NOT_IMPLEMENTED}
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }

  &.no-border {
    border: none !important;
  }
`;
