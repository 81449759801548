import styled from 'styled-components';
import { KeyboardEvent, ReactNode } from 'react';
import clsx from 'clsx';
import { breakpoints } from 'styles/theme';
import { fonts } from 'styles/fonts';
import { CheckIcon } from './icons';

interface Props {
  label?: string;
  checked: boolean;
  children?: ReactNode;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

export const CheckBox = ({ label, checked, children, disabled, onChange }: Props) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange(!checked);
    }
  };

  return (
    <Container
      id={label}
      className={clsx({ checked, disabled })}
      tabIndex={0}
      role="checkbox"
      aria-checked={checked}
      aria-disabled={disabled}
      onClick={() => {
        onChange(!checked);
      }}
      onKeyDown={handleKeyDown}
    >
      <span className="checkmark">{checked && <CheckIcon />}</span>

      <div className="checkbox-text-block">
        <label htmlFor={label}>{label}</label>
        {children && <div className="description">{children}</div>}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  &,
  * {
    cursor: pointer;
    transition: all 0.1s;
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 16px;
    min-width: 16px;
    height: 16px;
    width: 16px;
    background-color: var(--color-dark-blue-700);
    border: 1px solid var(--color-dark-blue-10);
    border-radius: 1px;
    margin-top: 2px;

    svg {
      margin: auto;
      width: 12px;
      height: 12px;
      color: var(--color-dark-blue-600);
    }
  }

  .checkbox-text-block {
    display: flex;
    flex-direction: column;
    ${fonts.body[12]};

    label {
      color: var(--color-base-light);
    }
    .description {
      color: var(--color-gray-400);
      ${fonts.body[15]};
    }
  }

  &.checked {
    .checkmark {
      background-color: var(--color-dark-blue-10);
      border: 1px solid var(--color-dark-blue-10);
    }
  }

  &:hover:not(.disabled) {
    .checkmark {
      background-color: var(--color-dark-blue-700);
      border: 1px solid var(--color-green-500);

      svg {
        color: var(--color-green-500);
      }
    }

    .checkbox-text-block {
      .description {
        color: var(--color-gray-300);
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .checkmark {
      background-color: var(--color-dark-blue-800);
      border: 1px solid var(--color-dark-blue-400);

      svg {
        color: var(--color-dark-blue-400);
      }
    }

    .checkbox-text-block {
      label {
        color: var(--color-dark-blue-100);
      }
      .description {
        color: var(--color-dark-blue-200);
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    gap: 12px;

    .checkmark {
      min-height: 20px;
      min-width: 20px;
      height: 20px;
      width: 20px;
      margin-top: 2px;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    .checkbox-text-block {
      ${fonts.body[9]};

      .description {
        ${fonts.body[12]};
      }
    }
  }
`;
