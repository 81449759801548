import { useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { initSentry } from 'utils/error-reporting';
import { CloseButton } from 'components/shared/close-button/close-button';
import { ALLOW_ANALYTICS, ALLOW_ERROR_REPORTING, initAnalytics } from 'utils/analytics';
import { Button } from 'components/shared';
import { links } from 'constants/links';
import { fonts } from 'styles/fonts';
import { PrivacySettingsModal } from './privacy-settings-modal';

export const ErrorReportingNotice = () => {
  const [showNotice, setShowNotice] = useState(
    () => localStorage.getItem(ALLOW_ERROR_REPORTING) === null || localStorage.getItem(ALLOW_ANALYTICS) === null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (allowAnalytics: boolean, allowReporting: boolean) => {
    localStorage.setItem(ALLOW_ERROR_REPORTING, allowReporting.toString());
    localStorage.setItem(ALLOW_ANALYTICS, allowAnalytics.toString());

    if (allowAnalytics) {
      initAnalytics();
      window.clarity?.('consent');
    }

    if (allowReporting) {
      initSentry();
    }

    setShowNotice(false);
    setIsModalOpen(false);
  };

  if (!showNotice) {
    return null;
  }

  return (
    <FixedContainer>
      <RelativeContainer>
        <div className="notice__close__button--desktop-container">
          <CloseButton color="gray" onClick={() => setShowNotice(false)} />
        </div>
        <ContentContainer>
          <div className="notice__close__button--mobile-container">
            <CloseButton color="gray" onClick={() => setShowNotice(false)} />
          </div>
          <div className="notice__prose" data-testid="error-reporting-notice">
            In order to provide the best services for you, we collect anonymized error data through{' '}
            <b key="sentry">
              <Button
                href={links.SENTRY}
                variant="link"
                color="blue"
                theme="light"
                size="small"
                md={{ size: 'medium' }}
              >
                Sentry
              </Button>
            </b>{' '}
            and use analytics cookies to improve our products.
          </div>
          <PrivacySettingsModal open={isModalOpen} onCancel={() => setIsModalOpen(false)} onSubmit={handleSubmit} />
          <div className="error__reporting--buttons">
            <Button
              variant="text"
              color="blue"
              theme="light"
              size="small"
              className="error__reporting--manage"
              onClick={() => setIsModalOpen(true)}
            >
              Manage Settings
            </Button>
            <Button
              className="error__reporting--accept"
              variant="primary"
              color="blue"
              size="small"
              onClick={() => handleSubmit(true, true)}
            >
              Accept all
            </Button>
          </div>
        </ContentContainer>
      </RelativeContainer>
    </FixedContainer>
  );
};

const FixedContainer = styled.div`
  position: fixed;
  z-index: var(--z-index-cookies-banner);
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-base-light);

  .notice__close__button--desktop-container {
    display: none;
  }

  .notice__close__button--mobile-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 64px;

    > button {
      margin: auto 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .notice__close__button--desktop-container {
      display: flex;
      position: absolute;
      top: 16px;
      right: 24px;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    .notice__close__button--mobile-container {
      display: none;
    }
  }
`;

const RelativeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 0 24px 32px 24px;
  line-height: 24px;
  max-width: 80ch;

  .notice__prose {
    color: var(--color-dark-blue-700);
    ${fonts.body[15]};

    > b {
      white-space: nowrap;
    }
  }

  a {
    color: inherit;
  }

  input {
    accent-color: #7ce3cb;
    height: 18px;
    width: 18px;
  }

  .error__reporting--buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;

    > button {
      white-space: nowrap;
    }
  }

  .error__reporting--manage {
    margin: auto 0;
  }

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    padding: 24px 0;
    max-width: 110ch;
    gap: 24px;

    .notice__prose {
      ${fonts.body[12]};
    }

    .error__reporting--buttons {
      justify-content: flex-end;
      width: unset;
      margin: auto 0;
      gap: 32px;
    }
  }
`;
