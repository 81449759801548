/* eslint-disable no-param-reassign */
/* eslint-disable functional/immutable-data */
import { ReactNode, FormEvent } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { CheckCircleFillIcon, ExclamationTriangleIcon } from 'components/shared/icons';
import { breakpoints } from 'styles/theme';
import { Spinner } from '../spinner/spinner';

interface Props {
  ariaLabel?: string;
  label?: string | ReactNode;
  id: string;
  value: string;
  onChange: (newValue: string) => void;
  error?: {
    show: boolean;
    message: string;
  };
  loading?: boolean;
  success?: boolean;
  type?: string;
  className?: string;
  placeholder?: string;
  inputProps?: Record<string, any>;
  inline?: boolean;
  disabled?: boolean;
  isTextarea?: boolean;
}

export const TextInput = (props: Props) => {
  const {
    ariaLabel,
    type = 'text',
    label = '',
    value,
    id,
    error = {
      show: false,
      message: '',
    },
    loading = false,
    success = false,
    onChange,
    placeholder,
    className = '',
    inputProps = {},
    inline = false,
    disabled = false,
    isTextarea = false,
  } = props;

  const handleChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Adjust height of textarea dynamically based on content
    // Used only on whitelisting for now
    if (event.target instanceof HTMLTextAreaElement) {
      const { value } = event.target;
      event.target.style.height = value === '' ? '60px' : `${Math.min(event.target.scrollHeight, 88)}px`;
    }

    if (!!onChange && typeof onChange === 'function') {
      onChange(event.currentTarget.value);
    } else {
      console.error('Component should have a onChange handler');
    }
  };

  return (
    <Container className={className}>
      <StyledInputWrapper className={clsx('text-input-wrapper', { inline })}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className="text-input">
          {isTextarea ? (
            <textarea
              spellCheck="false"
              className="textarea-input-box"
              aria-label={ariaLabel}
              id={id}
              value={value}
              onChange={handleChange}
              placeholder={placeholder}
              disabled={disabled}
              style={{ overflow: value.length === 0 ? 'hidden' : 'auto' }}
              {...inputProps}
            />
          ) : (
            <input
              aria-label={ariaLabel}
              disabled={disabled}
              type={type}
              id={id}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              {...{
                ...inputProps,
                className: clsx(inputProps.className, { 'icon-visible': loading || success || error.show }),
              }}
            />
          )}
          {error.show && <ExclamationTriangleIcon className="input-icon error-icon" />}
          {loading && <Spinner className="input-icon loading-icon" />}
          {success && <CheckCircleFillIcon className="input-icon success-icon" />}
          <span className="sr-only">{loading ? 'Loading' : success ? 'Success' : error.show ? 'Error' : ''}</span>
        </div>
      </StyledInputWrapper>
      {error && error.show && (
        <div className="input-error" role="alert">
          {error.message}
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  label {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--color-neutral-gray3);
    margin-right: 13px;
  }

  .input-error {
    margin-top: 12px;
    color: var(--color-action-error-500);
  }
`;

const StyledInputWrapper = styled.div`
  position: relative;
  height: 100%;

  .text-input {
    position: relative;
  }

  &.inline {
    display: flex;
    align-items: center;

    .text-input {
      &:after {
        width: 100%;
      }
    }
  }

  .input-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
  }

  .textarea-input-box {
    resize: none;
    height: 60px;
  }

  input,
  .textarea-input-box {
    width: 100%;
    background-color: var(--color-neutral-gray9);
    font-weight: 300;
    font-size: 18px;
    line-height: 0px;
    color: var(--color-neutral-gray6);
    border: 1px solid transparent;
    transition: all 0.3s;

    &:focus {
      outline: none;
      border: 1px solid rgba(135, 136, 136, 1);
      box-shadow: none;
      color: var(--font-color-white);
    }

    &:focus-visible {
      outline: 1px solid var(--focus-outline);
    }

    &::placeholder {
      color: var(--color-neutral-gray6);
      transition: 0.3s color;
      opacity: 1;
    }

    border: 1px solid var(--color-neutral-gray7);
    border-radius: 8px;
    padding: 15px 16px 14px 16px;
    color: var(--color-neutral-gray3);
    font-weight: 400;
    line-height: 27px;

    &.icon-visible {
      padding-right: 32px;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    .input-icon {
      right: 20px;
      width: 20px;
      height: 20px;
    }

    input,
    .textarea-input-box {
      &.icon-visible {
        padding-right: 48px;
      }
    }
  }
`;
