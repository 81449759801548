import { ReactNode } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { useGoOneLevelUp } from 'hooks/use-go-one-level-back';
import { ArrowLeft } from './shared/icons';

interface Props {
  children?: ReactNode | string;
}

export const BackButton = ({ children }: Props) => {
  const { goOneLevelUp } = useGoOneLevelUp();

  return (
    <StyledBackButton onClick={goOneLevelUp} className="back-button" data-testid="back-button">
      <ArrowLeft /> {children || 'Back'}
    </StyledBackButton>
  );
};

const StyledBackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
  background: transparent;
  border: none;
  font-size: 12px;
  line-height: 140%;
  color: var(--color-blue-25);
  margin-left: -4px;

  &:hover {
    color: var(--color-base-light);
  }

  & svg {
    width: 20px;
    height: 20px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    gap: 8px;
    font-size: 14px;
  }

  @media (min-width: ${breakpoints.md}px) {
    font-size: 16px;
  }

  @media (min-width: ${breakpoints.md}px) {
    & svg {
      width: 24px;
      height: 24px;
    }
  }
`;
