import { Modal, ModalHeader } from 'components/modal';
import styled from 'styled-components';
import clsx from 'clsx';
import { breakpoints } from 'styles/theme';
import { Button } from 'components/shared';
import { useState } from 'react';
import { CheckBox } from 'components/shared/check-box';
import { links } from 'constants/links';
import { fonts } from 'styles/fonts';
import { ALLOW_ANALYTICS, ALLOW_ERROR_REPORTING } from 'utils/analytics';

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: (allowAnalytics: boolean, allowReporting: boolean) => void;
}

export const PrivacySettingsModal = (props: Props) => {
  const { open, onCancel, onSubmit } = props;

  const storedErrorReportingFlag = localStorage.getItem(ALLOW_ERROR_REPORTING);
  const storedAnalyticsFlag = localStorage.getItem(ALLOW_ANALYTICS);

  const [allowReporting, setAllowReporting] = useState(() => storedErrorReportingFlag === 'true');
  const [allowAnalytics, setAllowAnalytics] = useState(() => storedAnalyticsFlag === 'true');

  return (
    <Modal open={open} onClose={onCancel}>
      <StyledModalContent>
        <ModalHeader>Privacy Settings</ModalHeader>

        <div className="privacy-description">
          <span>
            In order to provide the best services for you, we collect anonymized error data through Sentry and use
            analytics cookies to improve our products. We do not gather IP address or user agent information.{' '}
          </span>
          <Button
            className="privacy-policy-button"
            variant="link"
            color="blue"
            size="large"
            href={links.PRIVACY_POLICY}
          >
            Visit our Privacy Policy
          </Button>
        </div>

        <div className={clsx('privacy__settings--checkbox-container', { selected: allowReporting })}>
          <CheckBox checked={allowReporting} onChange={setAllowReporting} label="Allow error reporting">
            <Button className="sentry-button" href={links.SENTRY} variant="link" color="blue" sm={{ size: 'medium' }}>
              Sentry
            </Button>{' '}
            collects error data to improve the performance and reliability of our services, and helps us identify and
            fix issues quickly, ensuring a smoother experience for you.
          </CheckBox>
        </div>

        <div className={clsx('privacy__settings--checkbox-container', { selected: allowAnalytics })}>
          <CheckBox checked={allowAnalytics} onChange={setAllowAnalytics} label="Allow analytics cookies">
            Analytics cookies help us to improve our website by collecting and reporting information on how you use it.
          </CheckBox>
        </div>

        <Button
          className="save-settings-button"
          variant="primary"
          color="blue"
          sm={{ size: 'large' }}
          onClick={() => onSubmit(allowAnalytics, allowReporting)}
        >
          Save Settings
        </Button>
      </StyledModalContent>
    </Modal>
  );
};

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;

  .modal-header {
    margin-bottom: 48px;
  }

  .privacy-description {
    margin-bottom: 40px;

    > span {
      color: var(--color-gray-200);
      ${fonts.body[9]};
      margin: 0;
    }

    .privacy-policy-button {
      white-space: nowrap;
    }
  }

  .privacy__settings--checkbox-container {
    margin-bottom: 24px;

    .checkbox-text-block {
      gap: 8px;
    }

    .sentry-button {
      svg {
        margin-bottom: -2px;
      }
    }
  }

  .save-settings-button {
    width: 100%;
    margin: 24px auto 2px auto;
  }

  @media (min-width: ${breakpoints.sm}px) {
    max-width: 510px;
    margin: 0 64px;

    .privacy-description {
      > span {
        ${fonts.body[6]};
      }
    }

    .save-settings-button {
      width: 200px;
    }
  }
`;
