import { css } from 'styled-components';
import { breakpoints } from './theme';

export const scrollBehaviourCss = css`
  position: sticky;
  transition: top var(--default-transition-duration);
  top: 0;
  z-index: var(--z-index-sticky-container);
  background-color: transparent;

  &.scroll-direction-up {
    top: var(--header-height-xs);
  }

  @media (min-width: ${breakpoints.sm}px) {
    &.scroll-direction-up {
      top: var(--header-height-sm);
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    &.scroll-direction-up {
      top: var(--header-height-md);
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    &.scroll-direction-up {
      top: var(--header-height-lg);
    }
  }
`;
