import { ComponentProps } from 'react';

export const SearchIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none" {...props}>
      <path
        d="M23.2506 25L17.1757 18.925L16.6069 18.35M16.6069 18.35C18.8278 16.563 20.2491 13.8224 20.2491 10.7498C20.2491 5.36515 15.8839 1 10.4992 1C5.11454 1 0.74939 5.36515 0.74939 10.7498C0.74939 16.1345 5.11454 20.4997 10.4992 20.4997C12.8114 20.4997 14.9356 19.6948 16.6069 18.35Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
