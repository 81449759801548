import styled from 'styled-components';
import shuffle from 'lodash/shuffle';
import { breakpoints } from 'styles/theme';
import { usePageParams } from 'hooks';
import { ExclamationTriangleFullIcon } from 'components/shared/icons';
import ConnectButton from 'components/sign-in/connect-button';
import { useConnectedAccount } from 'hooks/chain-data';
import { fonts } from 'styles/fonts';
import { ChainDApiCard } from './chain-dapi-card';
import { CatalogButton } from './chain-catalog-button';

const HIGHLIGHTED_DAPIS_COUNT = 4;
interface Props {
  activeFeeds: string[];
  isError: boolean;
}

export const ChainDApisSuggestions = ({ activeFeeds, isError }: Props) => {
  const { chainAlias = '' } = usePageParams();
  const { isConnected } = useConnectedAccount();

  const hasActiveFeeds = activeFeeds.length > 0;

  return (
    <InnerContainer>
      {hasActiveFeeds ? <div className="dapis-title">Featured Feeds</div> : <CatalogButton />}

      <ul>
        {shuffle(activeFeeds)
          .slice(0, HIGHLIGHTED_DAPIS_COUNT)
          .map((dapiName) => (
            <ChainDApiCard key={dapiName} chainAlias={chainAlias} dapiName={dapiName} onlySmall />
          ))}
      </ul>
      {isError && (
        <div className="active-feeds-error" data-testid="active-feeds-error">
          <div className="active-feeds-error-title">
            <ExclamationTriangleFullIcon />
            Unable to load featured feeds.
          </div>
          <div className="active-feeds-error-subtitle">
            Try reloading the page{' '}
            {!isConnected && (
              <span>
                or{' '}
                <ConnectButton
                  variant="link"
                  color="blue"
                  size="small"
                  md={{ size: 'medium' }}
                  className="connect-button"
                >
                  connecting your wallet.
                </ConnectButton>
              </span>
            )}
          </div>
        </div>
      )}
    </InnerContainer>
  );
};

const InnerContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .dapis-title {
    color: var(--color-base-light);
    ${fonts.heading[9]}
  }

  ul {
    margin-top: 24px;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
  }

  .active-feeds-error {
    color: var(--color-gray-200);
    font-size: 14px;
    line-height: 140%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .active-feeds-error-title {
      display: flex;
      font-weight: 700;

      svg {
        align-self: center;
      }
    }

    .active-feeds-error-subtitle {
      font-size: 12px;
      line-height: 140%;
    }

    .connect-button {
      display: inline;
      padding: 0;
      height: unset;
      min-height: unset;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    margin-top: 120px;
    ul {
      margin-top: 40px;
      gap: 40px;
    }

    .dapis-title {
      ${fonts.heading[7]}
    }

    .active-feeds-error {
      flex-direction: row;
      align-items: baseline;

      .active-feeds-error-title {
        align-items: baseline;
        padding-right: 4px;

        svg {
          align-self: end;
        }
      }
      .active-feeds-error-subtitle {
        font-size: 14px;
      }
    }
  }
`;
