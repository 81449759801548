import { Button } from 'components/shared/button/button';
import { NetworkType } from 'types';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { links } from 'constants/links';

interface Props {
  networkType: NetworkType;
  searchValue: string;
  onChangeNetworkType: () => void;
}

export const HomepageNoResults = (props: Props) => {
  const { networkType, searchValue, onChangeNetworkType } = props;

  return (
    <StyledNoResults data-testid="no-results">
      <div className="no-results-title">{`We couldn’t find any matches for "${searchValue}"`}</div>

      <div className="no-results-alternative">
        <span>Can’t find what you’re looking for?</span>

        <Button variant="secondary" color="gray" size="small" onClick={onChangeNetworkType}>
          {networkType === 'mainnet' ? 'Search Testnets' : 'Search Mainnets'}
        </Button>
        <Button
          variant="link"
          color="gray"
          size="small"
          sm={{ size: 'medium' }}
          href={links.FORM_REQUEST_CHAIN}
          iconEnd={null}
        >
          Request a new chain
        </Button>
      </div>
    </StyledNoResults>
  );
};

const StyledNoResults = styled.div`
  .no-results-title {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 24px;
    color: var(--color-base-light);
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
  }

  .no-results-alternative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    width: 100%;

    span {
      color: var(--color-gray-200);
      font-size: 12px;
      line-height: 140%;
    }

    button {
      margin: 0 auto;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    .no-results-title {
      font-size: 22px;
      line-height: 130%;
    }

    .no-results-alternative {
      span {
        font-size: 16px;
      }
    }
  }
`;
