import { allChainsByAlias } from 'utils/dapis';
import { IS_PURCHASE_TEST } from '__test_utils__/purchase-test';
import { useProviderStore } from 'stores';
import { getHardhatSigner, useSigner } from './use-signer';

export const useChain = (chainAlias: string | undefined) => {
  const { providers } = useProviderStore();
  const chain = chainAlias ? allChainsByAlias[chainAlias] : undefined;
  const chainIdStr = chain?.id;
  const chainId = chainIdStr ? Number(chainIdStr) : undefined;
  const provider = chainIdStr ? providers[chainIdStr] : undefined;
  const testing = process.env.VITE_APP_MOCK_ENV === 'true' || IS_PURCHASE_TEST;
  const signer = testing ? getHardhatSigner(provider) : useSigner(); // Signer only for the connected account

  return {
    chain,
    chainAlias,
    chainId,
    chainName: chain?.name,
    provider,
    signer,
  };
};
