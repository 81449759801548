import styled from 'styled-components';
import { ChevronDown } from 'components/shared/icons';
import clsx from 'clsx';
import { useSelect } from 'downshift';
import { isNullish } from 'utils/utils';
import { breakpoints } from 'styles/theme';
import { Subscription } from 'types';
import { formatDeviation, formatHeartbeat } from 'utils/format';
import { fonts } from 'styles/fonts';

interface Props {
  selected: Subscription;
  options: Subscription[];
  onSelect: (selectedIndex: number) => void;
}

export const UpgradeOptionDropdown = (props: Props) => {
  const { selected, options, onSelect } = props;

  const { highlightedIndex, isOpen, getItemProps, getMenuProps, getToggleButtonProps } = useSelect({
    items: options,
    selectedItem: selected,
    onSelectedItemChange({ selectedItem: newSelectedItem }) {
      if (!isNullish(newSelectedItem)) {
        onSelect(options.findIndex((item) => item === newSelectedItem));
      }
    },
  });

  return (
    <Container>
      <DropdownToggle
        className={clsx({ opened: isOpen })}
        aria-label="Upgrade options"
        {...getToggleButtonProps({ 'aria-labelledby': undefined })}
      >
        <div className="selected">
          <div className="option-text">
            {formatDeviation(selected.updateParameters.deviationThreshold)} deviation
            <span className="separator">|</span>
            {formatHeartbeat(selected.updateParameters.heartbeatInterval)} heartbeat
          </div>
          <ChevronDown />
        </div>
      </DropdownToggle>
      <DropdownOptions className={clsx({ opened: isOpen })}>
        <ul {...getMenuProps({ 'aria-labelledby': undefined })} tabIndex={-1} className="custom-scrollbar">
          {isOpen &&
            options?.map((item, index) => {
              const isSelected = item === selected;
              const isHovered = highlightedIndex === index;

              return (
                <li
                  className={clsx((isSelected || isHovered) && 'current-item')}
                  key={index}
                  {...getItemProps({ item, index })}
                >
                  {formatDeviation(item.updateParameters.deviationThreshold)} deviation
                  <span className="separator">|</span>
                  {formatHeartbeat(item.updateParameters.heartbeatInterval)} heartbeat
                </li>
              );
            })}
        </ul>
      </DropdownOptions>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  white-space: nowrap;
  position: relative;

  .separator {
    color: var(--color-gray-500);
    padding: 0 4px;
  }
`;

const DropdownToggle = styled.div`
  cursor: pointer;
  padding: 12px 12px 12px 16px;
  height: 48px;
  background-color: var(--color-dark-blue-800);
  border-radius: 8px;
  ${fonts.body[12]}

  .selected {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .option-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      flex-shrink: 1;
    }

    svg {
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      margin-left: auto;
      transition: var(--animation-flip-chevron);
    }
  }

  &.opened {
    border-radius: 8px 8px 0 0;

    &.selected {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    ${fonts.body[6]}
    padding: 16px;
    height: 58px;
  }
`;

const DropdownOptions = styled.div`
  &:not(.opened) {
    display: none;
  }

  ul {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 8px 12px;
    z-index: var(--z-index-dropdown);
    background-color: var(--color-dark-blue-800);
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 4px 20px 0px rgba(16, 18, 17, 0.9);
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;

    &:empty {
      padding: 0;
    }
  }

  li {
    display: flex;
    align-items: center;
    padding: 12px 8px 12px 10px;
    cursor: pointer;
    border-bottom: 1px solid var(--color-dark-blue-500);

    &:last-of-type {
      border-bottom: none;
    }

    &.current-item {
      background-color: var(--color-dark-blue-600);
    }
  }
`;
