import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useURLWithShowHidden } from './use-url-with-show-hidden';

const NavigationHistoryContext = createContext<{
  navigateBack: () => void;
  navigationHistory: string[];
}>({
  navigateBack: () => null,
  navigationHistory: [],
});

export const NavigationHistoryContextProvider = (props: { children: ReactNode }) => {
  const [paths, setPaths] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { getURL } = useURLWithShowHidden();

  const ctx = useMemo(
    () => ({
      navigateBack: () => {
        const newPath = paths.length > 1 ? paths[paths.length - 2] : '/';

        // Go back to the previous page
        navigate(getURL(newPath));
      },
      navigationHistory: paths,
    }),
    [paths]
  );

  useEffect(() => {
    setPaths((prevPaths: string[]) => {
      // last element from the stack
      const lastPath = prevPaths[prevPaths.length - 1];
      // if the last element is the same as the current one, return the previous stack
      if (lastPath === location.pathname) return prevPaths;
      // otherwise, return the previous stack plus the current path but limited to 20 elements
      return [...prevPaths.slice(-20), location.pathname];
    });
  }, [location]);

  return <NavigationHistoryContext.Provider value={ctx}>{props.children}</NavigationHistoryContext.Provider>;
};

export const useNavigationHistoryContext = () => {
  return useContext(NavigationHistoryContext);
};
