import { ComponentProps } from 'react';
import clsx from 'clsx';

export const ExclamationTriangleFullIcon = (props: ComponentProps<'svg'>) => {
  const { className, ...rest } = props;
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('error-icon', className)}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4365 13.7728H2.56392C1.8804 13.7728 1.46249 13.0223 1.82247 12.4413L7.27516 3.63991C7.61674 3.08856 8.4192 3.08964 8.75929 3.64191L14.1792 12.4433C14.5371 13.0244 14.119 13.7728 13.4365 13.7728ZM8.50002 7.10602C8.50002 6.82988 8.27616 6.60602 8.00002 6.60602C7.72387 6.60602 7.50002 6.82988 7.50002 7.10602V9.77274C7.50002 10.0489 7.72387 10.2727 8.00002 10.2727C8.27616 10.2727 8.50002 10.0489 8.50002 9.77274V7.10602ZM8.00002 10.6061C7.72387 10.6061 7.50002 10.83 7.50002 11.1061C7.50002 11.3822 7.72387 11.6061 8.00002 11.6061H8.00668C8.28282 11.6061 8.50668 11.3822 8.50668 11.1061C8.50668 10.83 8.28282 10.6061 8.00668 10.6061H8.00002Z"
        fill="currentColor"
      />
    </svg>
  );
};
