/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePrevious } from './use-previous';

export const useClearToasts = () => {
  const location = useLocation();
  const clearToasts = () => {
    if (document && document.visibilityState === 'hidden') {
      toast.dismiss();
    }
  };

  // Dismiss the toasts when the user navigates to a different page
  const initialPath = usePrevious(location.pathname);
  useEffect(() => {
    if (location.pathname !== initialPath && initialPath) {
      toast.dismiss();
    }
  }, [location.pathname, initialPath]);

  useEffect(() => {
    !!document && document.addEventListener('visibilitychange', clearToasts);

    return () => {
      !!document && document.removeEventListener('visibilitychange', clearToasts);
    };
  }, []);
};
