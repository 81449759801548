import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

// Prevent keeping scroll position when navigating between pages
export function useScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
