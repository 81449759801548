import { ReactNode } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import clsx from 'clsx';

export interface BannerProps {
  content: ReactNode | null;
  type: 'error' | 'maintenance' | 'deprecated' | 'expired' | 'neutral';
  fullBorder?: boolean;
}

export const Banner = (props: BannerProps) => {
  const { content, type, fullBorder } = props;

  return (
    <Container
      className={clsx('banner-container', {
        'full-border': fullBorder,
        neutral: type === 'neutral',
        warning: type === 'deprecated' || type === 'maintenance' || type === 'expired',
        error: type === 'error',
      })}
      data-testid={`${type}-banner`}
    >
      <div className="banner-section">{content}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 100%;
  z-index: var(--z-index-banner);
  align-items: stretch;
  box-sizing: border-box;
  background-color: var(--color-gray-900);

  &.error {
    background-color: var(--color-action-error-25);
    border-top: 1px solid var(--color-action-error-500);
    border-bottom: 2px solid var(--color-action-error-500);

    &.full-border {
      border: 2px solid var(--color-action-error-500);
    }
  }

  &.warning {
    background-color: var(--color-action-warning-25);
    color: var(--color-base-dark);
    border-top: 1px solid var(--color-action-warning-500);
    border-bottom: 2px solid var(--color-action-warning-500);
  }

  .banner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 24px;
    box-sizing: border-box;
    height: 72px;
  }

  &.warning,
  &.error {
    .banner-section {
      padding: 16px 24px;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    .banner-section {
      margin: 0 auto;
      height: 72px;
    }

    &.warning,
    &.error {
      padding: 0; // Override containerStyles

      .banner-section {
        padding: 24px 32px;
      }
    }
  }
`;
