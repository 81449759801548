import { css } from 'styled-components';
import { breakpoints } from 'styles/theme';

const Medium = css`
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
`;

const Large = css`
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
`;

export const ButtonMenuDropdownStyle = css`
  width: fit-content;

  @media (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.lg}px) {
    ${Medium}
  }
  @media (min-width: ${breakpoints.lg}px) {
    ${Large}
  }

  // Override default size if specific size is provided
  &.medium {
    ${Medium}
  }
  &.large {
    ${Large}
  }
`;
