import { go } from '@api3/promise-utils';
import { allChainsById } from 'utils/dapis';
import { walletMessages } from 'constants/walletMessages';
import { useDocumentVisibility } from 'hooks/use-document-visibility';
import { useGeneralStore } from 'stores';
import { getApi3RpcUrls } from 'utils/utils';
import { Eip1193Provider } from 'ethers';
import { useSwitchChain } from 'wagmi';
import { useState } from 'react';
import { ErrorMessage, RpcError } from 'types';

export function useSwitchNetwork() {
  const { setChainSwitchStatus } = useGeneralStore();
  const { isTabActive } = useDocumentVisibility();
  const { switchChainAsync } = useSwitchChain();
  const [error, setError] = useState<ErrorMessage>();

  const handleSwitch = async (newChainId: number | undefined, injectedProvider: Eip1193Provider) => {
    if (!newChainId || !isTabActive) {
      return;
    }

    const newChain = allChainsById[newChainId];

    if (!newChain) {
      setError({
        message: walletMessages.TX_GENERIC_ERROR_MESSAGE,
        secondaryMessage: 'Unsupported chain',
      });
      return;
    }

    const newChainIdHex = `0x${newChainId.toString(16)}`;

    // Attempt to switch the chain from the user's MetaMask
    const switchChainResponse = await go<any, RpcError>(() =>
      switchChainAsync({
        chainId: newChainId,
        addEthereumChainParameter: {
          chainName: newChain.name,
          nativeCurrency: {
            name: newChain.name,
            symbol: newChain.symbol,
            decimals: newChain.decimals,
          },
          rpcUrls: getApi3RpcUrls(newChainId),
          blockExplorerUrls: [newChain.explorer.browserUrl],
        },
      })
    );

    // Chain switched successfully
    if (switchChainResponse.success) {
      setChainSwitchStatus('idle');
      setError(undefined);
      return;
    }

    // Chain switch failed
    const switchWalletErrorCode = switchChainResponse.error.code;

    if (switchWalletErrorCode === -32002) {
      // MetaMask popup already opened, so we don't show any error
      return;
    }

    // Chain is not found in user's MetaMask, so we prompt him to add it now
    if (switchWalletErrorCode === 4902) {
      const addChainResponse = await go<any, RpcError>(() => {
        const newChain = allChainsById[newChainId];

        if (!newChain) {
          throw new Error('Unknown chain');
        }

        return injectedProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: newChainIdHex,
              chainName: newChain.name,
              nativeCurrency: {
                name: newChain.name,
                symbol: newChain.symbol,
                decimals: newChain.decimals,
              },
              rpcUrls: getApi3RpcUrls(newChainId),
              blockExplorerUrls: [newChain.explorer.browserUrl],
            },
          ],
        });
      });

      // Chain added successfully
      if (addChainResponse.success) {
        setChainSwitchStatus('idle');
        return;
      }

      setChainSwitchStatus('rejected');

      const addChainErrorCode = addChainResponse.error.code;

      // User cancelled the chain add, so we don't show any error
      if (addChainErrorCode === 4001) {
        return;
      }
    }

    setChainSwitchStatus('rejected');

    // User cancelled the chain switch, so we don't show any error
    if (switchWalletErrorCode === 4001) {
      return;
    }

    // Something went wrong on adding or switching
    setError({
      message: walletMessages.TX_GENERIC_ERROR_MESSAGE,
      secondaryMessage: walletMessages.TX_SWITCH_NETWORK,
    });
  };

  return {
    switchNetwork: handleSwitch,
    error,
    setError,
  };
}
