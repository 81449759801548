import { ChainLogo } from '@api3/logos';
import { Button, Tooltip } from 'components/shared';
import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { api3Chains } from '@api3/dapi-management';
import { DapiName } from 'components/shared/datafeed/dapi-name';
import DatafeedLogos from 'components/shared/datafeed/datafeed-logos';
import { getDapiPath } from 'utils/utils';
import { BackButton } from 'components/back-button';
import { dapisByName, isDeprecatedDataFeed } from 'utils/dapis';
import { useDapiChainData } from 'hooks/chain-data';
import { InfoIcon } from 'components/shared/icons';
import { useWindowDimensions } from 'hooks';
import { fonts } from 'styles/fonts';
import { DapiNameTooltip } from './dapi-name-tooltip';

interface Props {
  dapiName: string;
  chain: api3Chains.Chain;
}

export const FeedDetailsHeader = ({ dapiName, chain }: Props) => {
  const { isLoading, isError } = useDapiChainData({
    dapiName,
    chainId: chain.id,
  });

  const { isTablet } = useWindowDimensions();

  const dapiDescription = dapisByName[dapiName].metadata?.description;
  const integrateUrl = `${getDapiPath(chain.alias, dapiName)}/integrate`;
  const integrateButtonDisabled = isLoading || isError || isDeprecatedDataFeed(chain.id, dapiName);

  return (
    <Container>
      <BackButton>
        {chain && (
          <StyledChainName>
            <img src={ChainLogo(chain.id)} alt={`${chain.name} logo`} />
            <div className="chain-title">
              {chain.name}
              <span className="chain-id">(ID: {chain.id})</span>
            </div>
          </StyledChainName>
        )}
      </BackButton>
      <div className="top-bar">
        <div className="feed-title" data-testid="feed-title">
          <DatafeedLogos dapiName={dapiName} size="md" sm={{ size: 'lg' }} md={{ size: 'xxl' }} />

          <div className="dapi-name-wrapper">
            <DapiName name={dapiName} className="dapi-name" color="gray-400" />

            {dapiDescription && (
              <Tooltip
                content={<DapiNameTooltip text={dapiDescription} />}
                infoTooltip
                placement={isTablet ? 'bottom' : 'right'}
              >
                <Tooltip.Trigger>
                  <InfoIcon className="info-icon" />
                  <span className="sr-only">Info icon</span> {/* For screen readers to announce the tooltip */}
                </Tooltip.Trigger>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="integrate-button">
          <Button
            href={integrateUrl}
            variant="primary"
            size="small"
            md={{ size: 'medium' }}
            color="blue"
            iconEnd={null}
            disabled={integrateButtonDisabled}
            tooltip={integrateButtonDisabled ? `This feed is no longer available on ${chain.name}` : undefined}
          >
            Integrate
          </Button>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 468px;

  .top-bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
    padding: 10px 0 64px 0;

    .feed-title {
      display: flex;
      align-items: flex-start;
      padding-left: 2px;
    }

    .dapi-name-wrapper {
      display: inline;
      white-space: nowrap;
      margin: auto 0;
      padding: 2px 0 0 8px;

      .dapi-name {
        display: inline;
        white-space: wrap;
        margin-right: 8px;
        ${fonts.heading[7]};
      }

      svg {
        transform: translateY(4px);
        width: 20px;
        height: 20px;
      }
    }
  }

  .integrate-button {
    width: 100%;

    a {
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    margin: 32px auto 0 auto;
    max-width: 640px;

    .top-bar {
      padding: 16px 0 32px 0;
      gap: 32px;

      & .feed-container {
        padding-top: 2px;
      }
    }

    .feed-title {
      .dapi-name-wrapper {
        padding: 0 0 0 16px;

        .dapi-name {
          ${fonts.heading[6]};
          margin-right: 16px;
        }

        svg {
          transform: translateY(2px);
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    margin: 0;
    max-width: unset;

    .top-bar {
      flex-direction: row;
      padding: 16px 0 64px 0;
      border-bottom: 1px solid var(--color-dark-blue-300);
    }

    .feed-title {
      .dapi-name-wrapper {
        .dapi-name {
          ${fonts.heading[2]}
          line-height: 110%;
        }

        svg {
          transform: translateY(0px);
          width: 32px;
          height: 32px;
        }
      }
    }

    .integrate-button {
      width: unset;

      a {
        width: 200px;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    .top-bar {
      padding: 24px 0 36px 0;
    }
  }
`;

const StyledChainName = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--color-base-light);
  text-align: left;

  ${fonts.body[15]}

  & img {
    width: 24px;
    height: 24px;
  }

  .chain-title {
    display: flex;
    align-items: baseline;
    gap: 4px;

    .chain-id {
      color: var(--color-gray-400);
      ${fonts.overline[2]}
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    ${fonts.body[12]}
    gap: 4px;

    .chain-title {
      gap: 8px;

      .chain-id {
        ${fonts.body[15]}
      }
    }
  }
`;
