import { useEffect } from 'react';
import { useGeneralStore } from 'stores';
import { usePageParams } from 'hooks/use-page-params';
import { useConnectedAccount } from './use-connected-account';
import { useChain } from './use-chain';

export const useCheckIfDifferentChain = () => {
  const { connectedChainId, isConnected } = useConnectedAccount();
  const { setChainSwitchStatus, setIsOnDifferentChain } = useGeneralStore();
  const { chainAlias } = usePageParams();
  const { chainId } = useChain(chainAlias);

  useEffect(() => {
    setChainSwitchStatus('changed-from-wallet');
    setIsOnDifferentChain(!!chainId && connectedChainId !== chainId && isConnected);
  }, [connectedChainId, isConnected]);

  useEffect(() => {
    setChainSwitchStatus('changed-on-navigation');
    setIsOnDifferentChain(!!chainId && connectedChainId !== chainId && isConnected);
  }, [chainId, isConnected]);
};
