import { useQuery } from '@tanstack/react-query';
import { LIMITED_CACHING } from 'constants/caching';
import { useConnectedAccount } from './use-connected-account';
import { useChain } from './use-chain';

export function useGetUserBalance() {
  const { connectedAddress, connectedChainAlias } = useConnectedAccount();
  const { provider } = useChain(connectedChainAlias);

  const { data, isSuccess, isLoading, isError } = useQuery({
    queryKey: ['userBalance', connectedAddress, connectedChainAlias],
    queryFn: () => {
      return provider!.getBalance(connectedAddress!).then((balance: bigint) => ({ userBalance: balance }));
    },
    ...LIMITED_CACHING,
    enabled: !!(provider && connectedAddress && connectedChainAlias),
    refetchInterval: 10 * 1000, // Every ten seconds
  });

  return {
    userBalance: data?.userBalance ?? 0n,
    isSuccess,
    isLoading,
    isError,
  };
}
