import { useState, useEffect } from 'react';
import { breakpoints } from 'styles/theme';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  const isMobile = width < breakpoints.sm;
  const isTablet = width < breakpoints.md;

  return {
    width,
    height,
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
