import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import DatafeedLogos from 'components/shared/datafeed/datafeed-logos';
import { Button, DapiName } from 'components/shared';
import { allChainsByAlias, isDeprecatedDataFeed } from 'utils/dapis';
import { getDapiPath } from 'utils/utils';
import { fonts } from 'styles/fonts';

interface InactiveDApiRowProps {
  chainAlias: string;
  dapiName: string;
}

export const InactiveDApiRow = (props: InactiveDApiRowProps) => {
  const { chainAlias, dapiName } = props;
  const chainId = allChainsByAlias[chainAlias]?.id;
  const chainName = allChainsByAlias[chainAlias]?.name;

  const activateUrl = `${getDapiPath(chainAlias, dapiName)}/activate?default=1`;

  const isDeprecated = isDeprecatedDataFeed(chainId, dapiName);

  return (
    <StyledInactiveDApiRow>
      <div className="inactive-dapi-row-header">
        <DatafeedLogos dapiName={dapiName} size="md" md={{ size: 'xl' }} />
        <DapiName name={dapiName} color="gray-400" />
      </div>

      <Button
        variant="primary"
        color="blue"
        href={activateUrl}
        size="x-small"
        md={{ size: 'small' }}
        className="activate-button"
        iconEnd={null}
        disabled={isDeprecated}
        tooltip={isDeprecated ? `This feed is no longer available on ${chainName}` : undefined}
      >
        Activate
      </Button>
    </StyledInactiveDApiRow>
  );
};

const StyledInactiveDApiRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px 16px;
  gap: 16px;
  text-decoration: none;
  cursor: default;

  .inactive-dapi-row-header {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 40px;
    ${fonts.body[12]}
  }

  a.activate-button {
    width: 100%;
  }

  @media (min-width: ${breakpoints.md}px) {
    padding: 24px 40px;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    .inactive-dapi-row-header {
      ${fonts.body[6]}
      gap: 24px;
    }

    a.activate-button {
      width: 200px;
    }
  }
`;
