import { useRef, useState } from 'react';
import styled from 'styled-components';
import { SmartLink } from 'components/smart-link';
import { breakpoints, threeCardsBreakpoint } from 'styles/theme';
import clsx from 'clsx';
import { SearchBox } from 'components/shared/search-box/search-box';
import { useAllChains, useDetectPageScroll, useWindowDimensions } from 'hooks';
import { scrollBehaviourCss } from 'styles/scroll-behaviour-css';
import { NetworkType } from 'types';
import { fonts } from 'styles/fonts';
import { NetworkCard } from './network-card';
import { MainnetTestnetToggle } from './mainnet-testnet-toggle';
import { HomepageNoResults } from './homepage-no-results';

export const NetworkGrid = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [networkType, setNetworkType] = useState<NetworkType>('mainnet');

  const { isDesktop } = useWindowDimensions();

  const contentRef = useRef<HTMLDivElement>(null);
  const { pageOffset, pageScrollDirection } = useDetectPageScroll(contentRef);

  const { allChains } = useAllChains(searchValue);
  const testnets = allChains.filter((chain) => chain.testnet);
  const mainnets = allChains.filter((chain) => !chain.testnet);

  const searchActive = searchValue.length > 0;

  const toggleNetworkType = () => setNetworkType((prev) => (prev === 'mainnet' ? 'testnet' : 'mainnet'));

  return (
    <Container>
      <StyledNetworkGridToolbox
        data-testid="network-grid-toolbox"
        className={clsx({
          [`scroll-direction-${pageScrollDirection}`]: pageOffset > 0,
        })}
      >
        <SearchBox
          className="search-chain-box"
          placeholder="Search for a Chain"
          searchValue={searchValue}
          userInput={searchValue}
          simpleSearch
          onSearchChange={(value) => setSearchValue(value)}
          onSearchClear={() => setSearchValue('')}
        />
        <div className="mainnet-testnet-toggle">
          <MainnetTestnetToggle value={networkType} onChange={toggleNetworkType} />
        </div>
        {isDesktop && <div className="select-chain-label-tablet">Select Chain:</div>}
      </StyledNetworkGridToolbox>

      <div ref={contentRef} className="content-wrapper">
        {!isDesktop && <div className="select-chain-label-mobile">Select Chain:</div>}

        <StyledNetworkGrid
          className={clsx({
            'search-active': searchActive,
            hidden: networkType !== 'mainnet',
            'no-results': !mainnets.length,
          })}
          data-testid="network-grid"
        >
          {mainnets.length > 0 ? (
            mainnets.map((chain, index) => (
              <SmartLink href={`/${chain.alias}`} key={index}>
                <NetworkCard chain={chain} key={index} />
              </SmartLink>
            ))
          ) : (
            <HomepageNoResults
              networkType={networkType}
              searchValue={searchValue}
              onChangeNetworkType={toggleNetworkType}
            />
          )}
        </StyledNetworkGrid>

        <StyledNetworkGrid
          className={clsx({
            'search-active': searchActive,
            hidden: networkType !== 'testnet',
            'no-results': !testnets.length,
          })}
          data-testid="network-grid"
        >
          {testnets.length > 0 ? (
            testnets.map((chain, index) => (
              <SmartLink href={`/${chain.alias}`} key={index}>
                <NetworkCard chain={chain} key={index} />
              </SmartLink>
            ))
          ) : (
            <HomepageNoResults
              networkType={networkType}
              searchValue={searchValue}
              onChangeNetworkType={toggleNetworkType}
            />
          )}
        </StyledNetworkGrid>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100%;

  .select-chain-label-mobile {
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 16px;
  }

  .network-grid {
    height: 100%;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;

    // hide the scrolled content
    --clipped-content: calc(var(--content-clip-height) - 300px + var(--header-height-xs) * var(--header-displayed));
    clip-path: inset(var(--clipped-content) 0 0 0);

    // add fade effect but only when the page is scrolled
    --starting-opacity: calc(1 - var(--page-scrolled));

    -webkit-mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, var(--starting-opacity)) var(--clipped-content),
      rgba(0, 0, 0, 1) calc(var(--clipped-content) + 100px)
    );
    mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, var(--starting-opacity)) var(--clipped-content),
      rgba(0, 0, 0, 1) calc(var(--clipped-content) + 100px)
    );
  }

  @media (min-width: ${breakpoints.sm}px) {
    .content-wrapper {
      --clipped-content: calc(var(--content-clip-height) - 334px + var(--header-height-sm) * var(--header-displayed));
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .content-wrapper {
      --clipped-content: calc(var(--content-clip-height) - 476px + var(--header-height-md) * var(--header-displayed));
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    .content-wrapper {
      --clipped-content: calc(var(--content-clip-height) - 490px + var(--header-height-lg) * var(--header-displayed));
    }
  }
`;

const StyledNetworkGrid = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-self: center;
  max-width: 328px;

  a {
    text-decoration: none;
  }

  @media (min-width: ${threeCardsBreakpoint}px) {
    max-width: 500px;
  }

  @media (min-width: ${breakpoints.md}px) {
    max-width: unset;
    gap: 40px;
  }

  &.hidden {
    display: none;
  }

  &.search-active,
  &.no-results {
    align-content: center;
    padding: 80px 0;
    height: 100%;
    width: 100%;
    border-top: 1px solid var(--color-dark-blue-100);
    border-bottom: 1px solid var(--color-dark-blue-100);
    margin-top: 24px;

    @media (min-width: ${breakpoints.md}px) {
      margin-top: 40px;
    }
  }
`;

const StyledNetworkGridToolbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
  padding-top: 16px;

  ${scrollBehaviourCss};

  padding-left: 0;
  padding-right: 0;

  .search-chain-box {
    width: 100%;
    margin-bottom: 32px;
    max-width: 328px;
  }

  &.scroll-direction-up,
  &.scroll-direction-down {
    .search-chain-box {
      margin-bottom: 16px;
    }
  }

  @media (min-width: ${threeCardsBreakpoint}px) {
    .search-chain-box {
      max-width: 500px;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    .search-chain-box {
      .search-box-inner {
        padding: 16px 24px;

        input,
        input::placeholder {
          ${fonts.body[9]}
        }

        .search-box-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    margin-bottom: 32px;

    .select-chain-label-tablet {
      font-size: 22px;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 22px;
      order: 0;
      margin: 0;
    }

    .search-chain-box {
      order: 1;
      max-width: 400px;
      padding: 0;
      margin: 0 96px 0 auto;

      .search-box-inner {
        padding: 18px 16px 18px 24px;
        height: 68px;

        input,
        input::placeholder {
          ${fonts.body[6]}
        }
      }
    }

    .mainnet-testnet-toggle {
      order: 2;
      margin: 0;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    .search-chain-box {
      max-width: 443px;
    }
  }
`;
