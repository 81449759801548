import * as mockDapis from '../__test_utils__/mock-dapis';
import * as prodDapis from './dapis-prod';

const dapi = process.env.VITE_APP_MOCK_ENV === 'true' ? mockDapis : prodDapis;

export const {
  api3ApiIntegrations,
  getDapiManagementMerkleTreeValues,
  getActiveOrDeprecatedChainIds,
  getActiveOrDeprecatedDapiNames,
  getBeaconByAirnodeAddress,
  getProviderAliasesToNames,
  isDeprecatedDataFeed,
  isDeprecatedChain,
  isDeprecatedDapi,
  API3_CHAINS,
  dapisBySlug,
  dapisByName,
  allChainsByAlias,
  allChainsById,
  activeOrDeprecatedChainIds,
  HIDDEN_CHAINS,
} = dapi;
