import styled from 'styled-components';
import { SearchBox } from 'components/shared/search-box/search-box';
import clsx from 'clsx';
import { breakpoints } from 'styles/theme';
import { CaretRight } from 'components/shared/icons';
import { filterDapisBySearchQuery, sortMatchingDapisByPriority } from 'utils/dapi-search';
import { getActiveOrDeprecatedDapiNames, isDeprecatedDapi } from 'utils/dapis';
import { Button } from 'components/shared';
import { useActiveDapis } from 'hooks/chain-data';
import { api3Chains } from '@api3/dapi-management';
import { useNavigate } from 'react-router-dom';
import { useURLWithShowHidden } from 'hooks';
import { getDapiPath } from 'utils/utils';
import { FocusEvent, useState } from 'react';

interface Props {
  chain: Pick<api3Chains.Chain, 'id' | 'alias'>;
  isInSearchMode: boolean;
  userInput: string;
  searchParamQueryValue: string;
  onSearchChange: (newValue: string, shouldSubmit?: boolean) => void;
  onSearchSubmit: () => void;
  onUserInputClear: () => void;
  setSearchValue: (value: string) => void;
}

const allDapis = getActiveOrDeprecatedDapiNames();

export const ChainSearch = (props: Props) => {
  const {
    chain,
    isInSearchMode,
    userInput,
    searchParamQueryValue,
    onSearchChange,
    onSearchSubmit,
    onUserInputClear,
    setSearchValue,
  } = props;
  const { activeFeeds = [] } = useActiveDapis(chain.id);
  const navigate = useNavigate();
  const { getURL } = useURLWithShowHidden();

  const matchingDapis = filterDapisBySearchQuery(allDapis, userInput);
  const dropdownOptions = sortMatchingDapisByPriority(matchingDapis, userInput);

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(event.type === 'focus');
  };

  const handleSearchSubmit = (value: string) => {
    const filteredFeeds = filterDapisBySearchQuery(allDapis, value);
    const firstDapiName = filteredFeeds[0];
    // If user enters exact feed name in search box navigate to the Feed details page / Purchase page
    if (filteredFeeds.length === 1 && firstDapiName.toLowerCase() === value.toLowerCase()) {
      searchOrRedirect(firstDapiName);
      return;
    }

    onSearchSubmit();
    // Scroll to top smooth
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const searchOrRedirect = (dapiName: string) => {
    if (activeFeeds.includes(dapiName)) {
      navigate(getURL(getDapiPath(chain.alias, dapiName)));
    } else if (allDapis.includes(dapiName) && !isDeprecatedDapi(dapiName)) {
      navigate(getURL(`${getDapiPath(chain.alias, dapiName)}/activate?default=1`));
    } else {
      setSearchValue(dapiName);
    }
  };

  return (
    <Container className={clsx({ 'search-mode': isInSearchMode })} onBlur={handleFocus} onFocus={handleFocus}>
      <SearchBox
        placeholder="Search for data feeds"
        className="chain-search-box"
        dropdownOptions={dropdownOptions}
        parentFocused={isFocused}
        userInput={userInput}
        searchValue={searchParamQueryValue}
        showIcons
        searchResultsCount={matchingDapis.length}
        onSearchChange={onSearchChange}
        onSearchClear={onUserInputClear}
        onSearchSubmit={handleSearchSubmit}
        onDropdownOptionClick={searchOrRedirect}
      />
      <SearchButton
        variant="primary"
        color="blue"
        iconOnly
        iconEnd={<CaretRight />}
        className={clsx('search-button', { focused: isFocused || isInSearchMode })}
        size="medium"
        md={{ size: 'large' }}
        onClick={onSearchSubmit}
      />
    </Container>
  );
};

const SearchButton = styled(Button)`
  &.primary.search-button {
    min-height: 48px;
    height: 48px;
    min-width: 48px;
    border-radius: 0 24px 24px 0;
    border: 1px solid var(--color-blue-400);

    &.focused {
      border: 1px solid var(--color-blue-25);
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    &.primary.large.search-button {
      min-height: 64px;
      min-width: 64px;

      &.focused {
        border: 1px solid var(--color-blue-25);
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;

  .chain-search-box {
    .search-box-icon {
      min-width: unset;
      min-height: unset;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    .search-box-inner {
      border-radius: 32px 0 0 32px;
      padding-left: 24px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    margin-top: 8px;

    &.search-mode {
      margin-top: 0;
    }

    .chain-search-box {
      .search-box-inner {
        padding: 16px 16px 16px 24px;

        .search-box-icon {
          width: 27px;
          height: 27px;
        }
      }
    }
  }
`;
