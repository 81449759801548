import { ComponentProps } from 'react';

export const CrossIcon = (props: ComponentProps<'svg'>) => {
  const { height = 36, width = 36, strokeWidth = 2 } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M9.25 9.25L26.7501 26.7499"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25195 26.7515L18.0013 18.0021L26.752 9.25146"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
