import { ComponentProps } from 'react';

export const CaretRight = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none" {...props}>
      <path
        d="M10.9502 5H1.0502M10.9502 5L6.8252 0.875M10.9502 5L6.8252 9.125"
        stroke="#FAFAFA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
