import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import { addDays, differenceInCalendarDays } from 'date-fns';

export type CalendarType = keyof typeof calendars;

export const calendars = {
  google: {
    name: 'Google calendar',
    fn: google,
  },
  outlook: {
    name: 'Outlook calendar',
    fn: outlook,
  },
  office365: {
    name: 'Office 365 calendar',
    fn: office365,
  },
  yahoo: {
    name: 'Yahoo calendar',
    fn: yahoo,
  },
  ics: {
    name: 'iCal download (.ics)',
    fn: ics,
  },
};

export const getReminderDate = (date: number) => {
  const today = new Date();
  const expirationDate = new Date(date);
  const onWeekBeforeExpiration = addDays(expirationDate, -7);

  const diffBetweenTodayAndExpirationDate = differenceInCalendarDays(expirationDate, today);
  return { reminderDate: onWeekBeforeExpiration, expiresSoon: diffBetweenTodayAndExpirationDate < 7 };
};
