import { useEffect } from 'react';
import { allChainsByAlias } from 'utils/dapis';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePageParams } from './use-page-params';
import { useActiveDapis } from './chain-data/use-active-dapis';

// Loads the active dapis if there is chain alias in the URL
// Keeps loading animation displayed until the active dapis are loaded or an error occurs
export const useLoader = () => {
  const { chainAlias, dapiName } = usePageParams();
  const chain = allChainsByAlias[chainAlias || ''];
  const { activeFeeds, isError } = useActiveDapis(chain?.id);
  const location = useLocation();
  const navigate = useNavigate();

  const activeFeedsLoaded = !!activeFeeds || isError;
  const notActiveDapi = !isError && dapiName && chain && activeFeeds && !activeFeeds?.includes(dapiName);
  const isOnFeedPage = location.pathname.match(/^\/[a-z\-]+\/[a-z\-]+$/);
  const hidePageContent = (chainAlias && !activeFeedsLoaded) || (isOnFeedPage && notActiveDapi);

  // Hide the loading cube when the new page is fully loaded
  useEffect(() => {
    const loadingScreen = document.querySelector<HTMLElement>('.loading-screen');
    if (loadingScreen && !hidePageContent) {
      loadingScreen.style.display = 'none';
    }
  }, [hidePageContent, location]);

  // Redirect to 404 if we are on dapi page and the dapi is not active
  useEffect(() => {
    if (isOnFeedPage && notActiveDapi) {
      navigate('/404', {
        replace: true,
      });
    }
  }, [activeFeeds, chain, dapiName, isError, navigate]);

  // Display loading screen when page content gets hidden
  useEffect(() => {
    const loadingScreen = document.querySelector<HTMLElement>('.loading-screen');
    if (hidePageContent && loadingScreen) {
      loadingScreen.style.display = 'block';
    }
  }, [hidePageContent, location]);

  return {
    hidePageContent,
  };
};
