export const DatafeedLogosMasks = () => {
  return (
    <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id="xsMask">
          <rect width="21" height="21" fill="white" />
          <circle cx="-3" cy="8" r="8" fill="black" />
        </mask>
        <mask id="smMask">
          <rect width="21" height="21" fill="white" />
          <circle cx="-5" cy="10" r="10" fill="black" />
        </mask>
        <mask id="mdMask">
          <rect width="32" height="32" fill="white" />
          <circle cx="-10" cy="16" r="16" fill="black" />
        </mask>
        <mask id="lgMask">
          <rect width="36" height="36" fill="white" />
          <circle cx="-11" cy="18" r="18" fill="black" />
        </mask>
        <mask id="xlMask">
          <rect width="42" height="42" fill="white" />
          <circle cx="-12" cy="21" r="21" fill="black" />
        </mask>
        <mask id="xxlMask">
          <rect width="64" height="64" fill="white" />
          <circle cx="-20" cy="32" r="32" fill="black" />
        </mask>
      </defs>
    </svg>
  );
};
