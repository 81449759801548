import { RefObject, useLayoutEffect, useRef, useState } from 'react';

export const useDetectPageScroll = (contentRef?: RefObject<HTMLDivElement>) => {
  const [pageOffset, setPageOffset] = useState(0);
  const [pageScrollDirection, setPageScrollDirection] = useState<'up' | 'down'>('down');

  // Use a ref to store the last scroll position to compare against the current scroll position
  const lastScrollY = useRef(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Update pageOffset state
      setPageOffset(currentScrollY);

      // Determine the scroll direction based on the last scroll position
      const newScrollDirection = lastScrollY.current < currentScrollY ? 'down' : 'up';
      setPageScrollDirection(newScrollDirection);

      // Update the last scroll position for the next scroll event
      lastScrollY.current = currentScrollY;

      // Set the CSS variables on the content element to clip the content creating the effect of a sticky header
      if (contentRef?.current) {
        const headerDisplayed = newScrollDirection === 'down' ? 0 : 1;
        const scrolled = currentScrollY > 0 ? 1 : 0;
        contentRef.current.style.setProperty('--content-clip-height', `${currentScrollY}px`);
        contentRef.current.style.setProperty('--header-displayed', `${headerDisplayed}`);
        contentRef.current.style.setProperty('--page-scrolled', `${scrolled}`);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const resetClipPath = () => {
    if (contentRef?.current) {
      contentRef.current.style.setProperty('--content-clip-height', `0px`);
      contentRef.current.style.setProperty('--header-displayed', '1');
      contentRef.current.style.setProperty('--page-scrolled', '0');
    }
  };

  return { resetClipPath, pageOffset, pageScrollDirection };
};
