import { FormEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { CheckIcon } from '../icons';

const checkedCss = css`
  background-color: var(--font-color-white);
  color: var(--color-neutral-gray8);
`;

const StyledWrapper = styled.div<{ checked: boolean }>`
  position: relative;

  .chip {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    background-color: transparent;
    border: 1px solid var(--color-neutral-gray6);
    color: var(--color-neutral-gray6);
    font-size: 12px;
    line-height: 150%;
    font-weight: 400;
    padding: 3px 10px;
    border-radius: 15px;

    ${({ checked }) =>
      checked &&
      css`
        ${checkedCss}
      `}

    :hover {
      ${checkedCss}
    }
  }
`;

interface IProps {
  id: string;
  checked: boolean;
  label: string | ReactNode;
  onChange: (event: FormEvent<HTMLButtonElement>) => void;
  wrapperClassName?: string;
}

export const CustomChip = ({ id, label = '', checked, onChange, wrapperClassName = '' }: IProps) => {
  return (
    <StyledWrapper className={wrapperClassName} checked={checked}>
      <button id={id} className="chip" type="button" onClick={onChange}>
        {label} {checked && <CheckIcon />}
      </button>
    </StyledWrapper>
  );
};
