import { useEffect, useState } from 'react';

// Hook to control the loading state of images.
// Useful when we want to load images into the memory before displaying them.
export const useImageLoader = (images?: string[]) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!images) {
      return; // No list of images provided yet, just wait
    }

    if (images.length === 0) {
      setIsLoading(false); // There is nothing to be loaded
      return;
    }

    const imageObjects = images.map((url) => {
      const img = new Image();
      img.src = url;
      return img;
    });

    Promise.all(
      imageObjects.map(
        (img) =>
          new Promise((resolve) => {
            img.onload = resolve; // eslint-disable-line no-param-reassign
          })
      )
    )
      .then(() => {
        setIsLoading(false); // All images have been loaded
      })
      .catch((err) => console.error(err));
  }, [images]);

  return { isLoading };
};
