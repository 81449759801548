import { css } from 'styled-components';
import { breakpoints } from 'styles/theme';

// As of now, both Primary and Secondary buttons match the same sizes
// If this changes in the future, we can separate them out
const XXSmall = css`
  min-height: 24px;
  height: 24px;
  padding: 0 12px;
  font-size: 10px;
  font-weight: 600;
  line-height: 170%;
  border-radius: 24px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const XSmall = css`
  min-height: 32px;
  height: 32px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;

  &.icon-only {
    padding: 6px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const Small = css`
  min-height: 40px;
  height: 40px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.2px;

  &.icon-only {
    padding: 10px;
  }
`;

const Medium = css`
  min-height: 48px;
  height: 58px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.3px;

  &.icon-only {
    padding: 12px;
  }
`;

const Large = css`
  min-height: 56px;
  height: 56px;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.3px;
  border-radius: 28px;

  &.icon-only {
    padding: 16px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ButtonDefaultStyle = css`
  @media (max-width: ${breakpoints.xs}px) {
    ${XSmall}
  }
  @media (max-width: ${breakpoints.sm}px) {
    ${Small}
  }
  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md}px) {
    ${Medium}
  }
  @media (min-width: ${breakpoints.md}px) {
    ${Large}
  }

  // Override default size if specific size is provided
  &.xx-small {
    ${XXSmall}
  }
  &.x-small {
    ${XSmall}
  }
  &.small {
    ${Small}
  }
  &.medium {
    ${Medium}
  }
  &.large {
    ${Large}
  }
`;
