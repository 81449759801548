import { ComponentProps } from 'react';

export const EnterIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none" {...props}>
      <path d="M13 1V4C13 5.10457 12.1046 6 11 6H1.5" stroke="#6C6D6D" strokeLinecap="round" />
      <path d="M4 9L1 6L4 3" stroke="#6C6D6D" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
