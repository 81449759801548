import * as Sentry from '@sentry/react';
import { isStaging } from 'styles/theme';

let initialised = false;
export const initSentry = () => {
  if (initialised) return;

  Sentry.init({
    dsn: isStaging ? process.env.VITE_APP_SENTRY_DSN_STAGING : process.env.VITE_APP_SENTRY_DSN,
    environment: isStaging ? 'staging' : process.env.NODE_ENV,
    maxValueLength: 5000,
    integrations(integrations) {
      return integrations.filter((integration) => {
        // We filter out the HttpContext (which includes the user agent info etc)
        // See: https://docs.sentry.io/platforms/javascript/configuration/integrations/default/
        return integration.name !== 'HttpContext';
      });
    },
    beforeSend(event) {
      return {
        ...event,
        // Do not collect the user's IP address
        user: { ip_address: '0.0.0.0' },
        // Add a light-weight HttpContext
        request: { url: window.location.href },
      };
    },
  });

  initialised = true;
};
