import { usePageParams } from './use-page-params';

export const useURLWithShowHidden = () => {
  const { showHidden } = usePageParams();

  return {
    getURL: (url: string) => {
      const separator = url.includes('?') ? '&' : '?';

      return showHidden ? `${url}${separator}showHidden=true` : url;
    },
  };
};
