import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { fonts } from 'styles/fonts';

interface Props {
  text: string;
}

const SEPARATOR = '* ';

export const DapiNameTooltip = ({ text }: Props) => (
  <StyledTooltipContent>
    <span className="title">Data Feed Details</span>
    <ul className="description">
      {text
        .split(SEPARATOR)
        .slice(1)
        .map((item, index) => (
          <li key={index}>{item}</li>
        ))}
    </ul>
  </StyledTooltipContent>
);

const StyledTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--color-base-black);
  max-width: 360px;
  padding: 20px 8px 8px;

  .title {
    ${fonts.body[11]};
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 12px;
    margin: 0;

    li {
      ${fonts.body[15]}
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    .title {
      ${fonts.body[8]};
    }

    .description {
      gap: 8px;

      li {
        ${fonts.body[12]};
      }
    }
  }
`;
