import { useEffect } from 'react';
import { useAccount, useEnsName } from 'wagmi';
import { usePrevious } from 'hooks/use-previous';
import { PURCHASE_TEST_CHAIN, IS_PURCHASE_TEST } from '__test_utils__/purchase-test';
import { useDisconnect } from './use-disconnect';

export const useConnectedAccount = () => {
  const account = useAccount();
  const disconnect = useDisconnect();
  const previousChainId = usePrevious(account.chainId);
  const { address, chain, chainId, isConnected } = account;

  const ensName = useEnsName({ address: account.address });

  useEffect(() => {
    if (!account.chain && previousChainId) {
      // On user locking his MetaMask, disconnect & remove wagmi.store from the local storage
      // We're doing this because of a bug in useAccount, which won't update by itself after user locks his MetaMask
      disconnect();
      localStorage.removeItem('wagmi.store');
    }
  }, [account.chain]);

  if (process.env.VITE_APP_MOCK_ENV === 'true') {
    return {
      connectedAddress: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
      connectedAccountName: 'cypress.test',
      connectedChainId: Number(localStorage.getItem('mock-chain-id')) || 31337,
      connectedChainAlias: 'hardhat',
      connectorName: 'MetaMask',
      isConnected: true,
    };
  }

  if (IS_PURCHASE_TEST) {
    return {
      connectedAddress: '0x07b589f06bD0A5324c4E2376d66d2F4F25921DE1',
      connectedAccountName: 'purchase.test',
      connectedChainId: PURCHASE_TEST_CHAIN.id,
      connectedChainAlias: PURCHASE_TEST_CHAIN.alias,
      connectorName: 'MetaMask',
      isConnected: true,
    };
  }

  return {
    connectedAddress: address,
    connectedAccountName: ensName.data,
    connectedChainId: chainId,
    connectedChainAlias: chain?.name || '',
    connectorName: account?.connector?.name || '',
    isConnected,
  };
};
