import { api3Chains, dapis as dmDapis } from '@api3/dapi-management';
import keyBy from 'lodash/keyBy';
import { BeaconData } from 'types';
import { slugify } from 'utils/format';
import * as api3ApiIntegrations from './mock-api-integration';
import dapiManagementMerkleTree from './mock-dapi-management-merkle-tree.json';
import AIRNODE_ADDRESSES from './mock-airnode-addresses.json';

type DapiType = (typeof dmDapis)[number];

export { api3ApiIntegrations };
export const getActiveOrDeprecatedChainIds = () => {
  return ['31337', '31338', '80001', '11155111', '5', '12345'];
};

export const getActiveOrDeprecatedDapiNames = () => {
  return ['API3/USD', 'AAVE/USD', 'ALGO/USD', 'BTC/USD', 'ETH/USD', 'LINK/USD', 'stMATIC/MATIC Exchange Rate'];
};

export const isDeprecatedChain = (chainId?: string) => {
  return chainId === '80001';
};

export const isDeprecatedDapi = (dapiName?: string) => {
  return dapiName === 'ALGO/USD' || dapiName === 'LINK/USD';
};

export const isDeprecatedDataFeed = (chainId: string, dapiName?: string) => {
  return isDeprecatedChain(chainId) || isDeprecatedDapi(dapiName);
};

export const getDapiManagementMerkleTreeValues = () => {
  return dapiManagementMerkleTree.values.map((values) => values.value);
};

const chains: api3Chains.Chain[] = [
  {
    id: '31337',
    name: 'Hardhat',
    alias: 'hardhat',
    decimals: 18,
    symbol: 'ETH',
    testnet: false,
    explorer: { browserUrl: 'http://localhost:8545/' },
    providers: [{ alias: 'default', rpcUrl: 'http://localhost:8545' }],
  },
  {
    id: '31338',
    name: 'Hardhat testnet',
    alias: 'hardhat-testnet',
    decimals: 18,
    symbol: 'ETH',
    testnet: true,
    explorer: { browserUrl: 'http://localhost:8545/' },
    providers: [{ alias: 'default', rpcUrl: 'http://localhost:8545' }],
  },
  {
    alias: 'polygon-testnet',
    decimals: 18,
    explorer: { browserUrl: 'https://mumbai.polygonscan.com/' },
    id: '80001',
    name: 'Polygon testnet',
    providers: [{ alias: 'default', rpcUrl: 'https://rpc-mumbai.polygon.technology' }],
    symbol: 'MATIC',
    testnet: true,
  },
  {
    alias: 'ethereum-sepolia-testnet',
    decimals: 18,
    explorer: { browserUrl: 'https://sepolia.etherscan.io/' },
    id: '11155111',
    name: 'Ethereum Sepolia testnet',
    providers: [{ alias: 'default', rpcUrl: 'https://sepolia.gateway.tenderly.co' }],
    symbol: 'ETH',
    testnet: true,
  },
  {
    alias: 'ethereum-goerli-testnet',
    decimals: 18,
    explorer: { browserUrl: 'https://goerli.etherscan.io/' },
    id: '5',
    name: 'Ethereum Goerli testnet',
    providers: [{ alias: 'default', rpcUrl: 'https://rpc.ankr.com/eth_goerli' }],
    skipProviderCheck: true,
    symbol: 'ETH',
    testnet: true,
  },
  {
    alias: 'mock-hidden-network',
    decimals: 18,
    explorer: { browserUrl: 'https://mock.etherscan.io/' },
    id: '12345',
    name: 'Mock hidden network',
    providers: [{ alias: 'default', rpcUrl: 'https://mock.gateway.tenderly.co' }],
    symbol: 'ETH',
    testnet: false,
  },
];

const dapis: DapiType[] = [
  {
    name: 'API3/USD',
    metadata: {
      prefix: '$',
      category: 'Equities',
    },
    providers: ['coingecko', 'coinpaprika', 'dxfeed', 'finage', 'kaiko'],
    stage: 'active',
  },
  {
    name: 'ALGO/USD',
    metadata: {
      prefix: '$',
      category: 'Cryptocurrency',
    },
    providers: ['coingecko', 'coinpaprika', 'dxfeed', 'finage'],
    stage: 'deprecated',
  },
  {
    name: 'AAVE/USD',
    metadata: {
      prefix: '$',
      category: 'Cryptocurrency',
    },
    providers: ['coingecko', 'coinpaprika', 'dxfeed', 'finage'],
    stage: 'active',
  },
  {
    name: 'ETH/USD',
    metadata: {
      prefix: '$',
      category: 'Equities',
    },
    providers: ['coingecko', 'coinpaprika', 'dxfeed', 'finage'],
    stage: 'active',
  },
  {
    name: 'stMATIC/MATIC Exchange Rate',
    metadata: {
      prefix: '$',
      category: 'LST & LRT Exchange Rates',
    },
    providers: ['coingecko'],
    stage: 'active',
  },
];

export const API3_CHAINS = chains;

export const getBeaconByAirnodeAddress = (beacons: BeaconData[], airnodeAddress: string) => {
  const index = AIRNODE_ADDRESSES.findIndex((address) => address === airnodeAddress);
  if (index !== -1) {
    return Object.values(beacons)[index];
  }
};

export const getProviderAliasesToNames = (beacons: BeaconData[]) => {
  const providersData = beacons.map((_, index) => api3ApiIntegrations.getApiUrls(AIRNODE_ADDRESSES[index]));
  return Object.fromEntries(providersData.map((data) => [data.alias, data.name]));
};

export const dapisByName = keyBy(dapis, 'name');
export const dapisBySlug = keyBy(dapis, (dapi: { name: string }) => slugify(dapi.name));
export const allChainsByAlias = keyBy(chains, 'alias');
export const allChainsById = keyBy(chains, 'id');
export const activeOrDeprecatedChainIds = keyBy(getActiveOrDeprecatedChainIds());
export const HIDDEN_CHAINS = ['mock-hidden-network'];
