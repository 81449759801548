import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import { canReportErrors, canUseAnalytics, initAnalytics } from 'utils/analytics';
import { UserTracking } from 'user-tracking';
import { NotificationBannerContextProvider } from 'components/layout/notification-banner';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { ErrorFallback } from './components/layout/error-fallback';
import reportWebVitals from './reportWebVitals';

import { initSentry } from './utils/error-reporting';

const rootElem = document.getElementById('root');
const root = createRoot(rootElem as Element);

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <SkeletonTheme baseColor="var(--color-dark-blue-300)" highlightColor="var(--color-dark-blue-400)">
        <HelmetProvider>
          <NotificationBannerContextProvider>
            <UserTracking />
            <App />
            <ToastContainer
              className="flags-toast-container-wrapper"
              limit={3}
              pauseOnFocusLoss={false}
              autoClose={2500}
              hideProgressBar
            />
          </NotificationBannerContextProvider>
        </HelmetProvider>
      </SkeletonTheme>
    </Sentry.ErrorBoundary>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (canReportErrors()) {
  initSentry();
}

if (canUseAnalytics()) {
  initAnalytics();
}

function registerSW() {
  // eslint-disable-next-line no-restricted-globals
  if (location.hostname === 'localhost') return;
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw-prod.js').catch((e) => {
      console.log('Service worker registration fail: ', e);
    });
  }
}
registerSW();
