import { api3Chains } from '@api3/dapi-management';
import { CalendarReminder } from 'components/shared/calendar-reminder';
import { useDapiChainData } from 'hooks/chain-data';
import { decodeUpdateParameters } from 'utils/contracts';
import Skeleton from 'components/shared/skeleton';
import { formatExpiryDate } from 'utils/format';

interface Props {
  chain: api3Chains.Chain;
  dapiName: string;
}

export const ExpirationDetails = ({ chain, dapiName }: Props) => {
  const { isLoading, isError, updateParameters, expiringTimestamps } = useDapiChainData({
    dapiName,
    chainId: chain.id,
  });

  const { deviationThreshold } = decodeUpdateParameters(updateParameters?.[updateParameters.length - 1]);
  const expiryDate = expiringTimestamps?.[expiringTimestamps.length - 1]
    ? expiringTimestamps[expiringTimestamps.length - 1] * 1000
    : null;

  return (
    <div className="summary-item">
      <div className="label">Expiring</div>

      {isLoading || !expiryDate ? (
        <div className="value">
          <Skeleton width="70%" enableAnimation={!isError} />
        </div>
      ) : (
        <div className="value expiration-date">
          {formatExpiryDate(expiryDate)}
          <CalendarReminder
            chainAlias={chain.alias}
            chainName={chain.name}
            dapiName={dapiName}
            date={expiryDate}
            deviationThreshold={deviationThreshold}
            size="medium"
          />
        </div>
      )}
    </div>
  );
};
