import { RefObject, useEffect, useRef } from 'react';

export function useOnClickOutside(elementRef: RefObject<HTMLElement>, callback: () => void) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if clicking the element or one of its descendents
      if (!elementRef.current || elementRef.current.contains(event.target as Node)) {
        return;
      }
      callbackRef.current();
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [elementRef]);
}
