import styled from 'styled-components';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { breakpoints } from 'styles/theme';
import { NetworkType } from 'types';

interface Props {
  value: NetworkType;
  onChange: (networkType: NetworkType) => void;
}

export const MainnetTestnetToggle = ({ value, onChange }: Props) => {
  return (
    <StyledToggleRoot type="single" value={value} onValueChange={onChange} aria-label="Mainnet/Testnet">
      <ToggleGroup.Item className="toggle-group-item" value="mainnet" aria-label="Mainnet networks">
        Mainnets
      </ToggleGroup.Item>
      <ToggleGroup.Item className="toggle-group-item" value="testnet" aria-label="Testnet networks">
        Testnets
      </ToggleGroup.Item>
    </StyledToggleRoot>
  );
};

const StyledToggleRoot = styled(ToggleGroup.Root)`
  background-color: var(--color-dark-blue-800);
  border-radius: 22px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  z-index: var(--z-index-content);

  .toggle-group-item {
    background: none;
    outline: none;
    border: none;
    border-radius: inherit;
    transition: color 0.2s ease-in-out;
    color: var(--color-base-light);
    font-size: 12px;
    font-weight: 600;
    line-height: 140%;
    padding: 4px 40px;
    width: 144px;
    position: relative;
    z-index: var(--z-index-content);

    &[data-state='on'] {
      color: var(--color-dark-blue-500);
    }

    &:hover {
      &:not([data-state='on']) {
        color: var(--color-dark-blue-10);
      }
    }
  }

  .toggle-group-item:first-child {
    &:before {
      content: '';
      position: absolute;
      z-index: var(--z-index-background);
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      transition: left 0.2s ease-in-out;
      color: var(--color-dark-blue-500);
      background-color: var(--color-base-light);
    }

    &[data-state='on'] {
      &:before {
        left: 0;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .toggle-group-item {
      font-size: 12px;
      font-weight: 600;
      line-height: 140%;
      padding: 8px 40px;
      min-width: 144px;
    }
  }
`;
