import { useWagmiWeb3Modal } from 'hooks/chain-data';
import { Button, ButtonProps } from '../shared/button/button';

const ConnectButton = (props: Omit<ButtonProps, 'onClick'>) => {
  const { open } = useWagmiWeb3Modal();

  return (
    <Button
      {...props}
      onClick={async () => {
        await open();
      }}
    />
  );
};

export default ConnectButton;
