import Skeleton from 'components/shared/skeleton';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import { BreakpointKeys, breakpoints } from 'styles/theme';
import { SymbolLogo } from '@api3/logos';
import { useWindowDimensions } from 'hooks';
import { DatafeedLogosMasks } from './datafeed-logos-masks';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type BreakpointsProps = { [key in BreakpointKeys]?: { size?: Size } };

interface Props extends BreakpointsProps {
  dapiName?: string;
  isError?: boolean;
  isLoading?: boolean;
  size?: Size;
  light?: boolean;
}

const DatafeedLogos = (props: Props) => {
  const { dapiName, light = false, isLoading, isError, size, xs, sm, md, lg } = props;

  const { width } = useWindowDimensions();

  const sizeClass = getSizeClass(width, size, { xs, sm, md, lg });

  const logos = dapiName?.split('/');

  return (
    <Container className={clsx('feed-container', { [sizeClass]: sizeClass })} aria-hidden>
      {!logos || logos.length === 0 || isLoading ? (
        <>
          <Skeleton className={clsx('feed', { 'light-mode': light })} enableAnimation={!isError} />
          <Skeleton className={clsx('feed feed-right loading', { 'light-mode': light })} enableAnimation={!isError} />
        </>
      ) : (
        <>
          <DatafeedLogosMasks />
          <img className="feed" src={SymbolLogo(logos[0], light)} alt={`${logos[0]} logo`} />
          <img className="feed feed-right" src={SymbolLogo(logos[1], light)} alt={`${logos[1]} logo`} />
        </>
      )}
    </Container>
  );
};

const XS = css`
  .feed {
    width: 16px;
    height: 16px;
    margin-left: 0;
  }

  .feed-right {
    mask-image: url('#xsMask');
    margin-left: -3px;
  }
`;

const SM = css`
  .feed {
    width: 21px;
    height: 21px;
    margin-left: 0;
  }

  .feed-right {
    mask-image: url('#smMask');
    margin-left: -3px;
  }
`;

const MD = css`
  .feed {
    width: 32px;
    height: 32px;
    margin-left: 0;
  }

  .feed-right {
    mask-image: url('#mdMask');
    margin-left: -3px;
  }
`;

const LG = css`
  .feed {
    width: 36px;
    height: 36px;
    margin-left: 0;
  }

  .feed-right {
    mask-image: url('#lgMask');
    margin-left: -4px;
  }
`;

const XL = css`
  .feed {
    width: 42px;
    height: 42px;
    margin-left: 0;
  }

  .feed-right {
    mask-image: url('#xlMask');
    margin-left: -6px;
  }
`;

const XXL = css`
  .feed {
    width: 64px;
    height: 64px;
    margin-left: 0;
  }

  .feed-right {
    mask-image: url('#xxlMask');
    margin-left: -8px;
  }
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-size: border-box;

  .feed {
    position: relative;
    border-radius: 50%;

    &.light-mode {
      filter: invert(1);
    }
  }

  // This selector targets the Skeletons
  > * {
    display: inline-flex;
  }

  // Default size
  ${SM}

  @media (min-width: ${breakpoints.sm}px) {
    ${MD}
  }
  @media (min-width: ${breakpoints.md}px) {
    ${LG}
  }
  @media (min-width: ${breakpoints.lg}px) {
    ${XXL}
  }

  // Override default size if specific size is provided
  &.xs {
    ${XS}
  }
  &.sm {
    ${SM}
  }
  &.md {
    ${MD}
  }
  &.lg {
    ${LG}
  }
  &.xl {
    ${XL}
  }
  &.xxl {
    ${XXL}
  }

  .feed-right.loading {
    mask-image: none;
  }
`;

const getSizeClass = (width: number, size?: Size, sizeProps?: BreakpointsProps) => {
  let sizeClass: Size | undefined;

  const { xs, sm, md, lg } = sizeProps || {};

  if (width >= breakpoints.lg) {
    sizeClass = lg?.size || md?.size || sm?.size || xs?.size;
  }
  if (width < breakpoints.lg) {
    sizeClass = md?.size || sm?.size || xs?.size;
  }
  if (width < breakpoints.md) {
    sizeClass = sm?.size || xs?.size;
  }
  if (width < breakpoints.sm) {
    sizeClass = xs?.size;
  }

  return sizeClass || size || '';
};

export default DatafeedLogos;
