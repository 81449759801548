import { lazy, MouseEvent, Suspense } from 'react';
import { useConnectedAccount } from 'hooks/chain-data';
import styled from 'styled-components';
import { HeaderWallet } from 'components/layout/header/header-wallet';
import { breakpoints } from 'styles/theme';
import { Button } from '../shared';

interface Props {
  onMobileClick: (event?: MouseEvent<HTMLButtonElement>) => void;
}

const ConnectButton = lazy(() => import('./connect-button'));

const SignIn = ({ onMobileClick }: Props) => {
  const { isConnected } = useConnectedAccount();

  if (isConnected) {
    return (
      <Container>
        <HeaderWallet onMobileClick={onMobileClick} />
      </Container>
    );
  }

  return (
    <Container>
      <Suspense
        fallback={
          <Button
            variant="secondary"
            color="gradient"
            size="x-small"
            md={{
              size: 'small',
            }}
            disabled
          >
            Connect
          </Button>
        }
      >
        <ConnectButton
          variant="secondary"
          color="gradient"
          size="x-small"
          md={{
            size: 'small',
          }}
        >
          Connect
        </ConnectButton>
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  .mobile-view {
    display: none;
  }

  button {
    height: 35px !important;
    min-height: 35px !important;
  }

  @media (min-width: ${breakpoints.md}px) {
    button {
      height: 48px !important;
      min-height: 48px !important;
      min-width: 203px;
    }
  }
`;

export default SignIn;
