import { createContext, ReactNode, useContext, useMemo } from 'react';
import ReactSkeleton, { SkeletonProps } from 'react-loading-skeleton';

const SkeletonAnimationContext = createContext({ enabled: true });

export const SkeletonAnimation = (props: { enabled: boolean; children: ReactNode }) => {
  const { enabled, children } = props;
  const ctx = useMemo(() => ({ enabled }), [enabled]);
  return <SkeletonAnimationContext.Provider value={ctx}>{children}</SkeletonAnimationContext.Provider>;
};

const Skeleton = (props: SkeletonProps) => {
  const ctx = useContext(SkeletonAnimationContext);
  return <ReactSkeleton enableAnimation={ctx.enabled} {...props} />;
};

export default Skeleton;
