import { css } from 'styled-components';
import { fonts } from 'styles/fonts';
import { breakpoints } from 'styles/theme';

const XSmall = css`
  min-height: 40px;
  height: 40px;
  min-width: 120px;
  padding: 8px 0;
  ${fonts.body[14]};
`;

const Small = css`
  min-height: 46px;
  height: 46px;
  padding: 11px 0;
  ${fonts.button[3]};
`;

const Medium = css`
  min-height: 58px;
  height: 58px;
  padding: 19px 0;
  ${fonts.button[2]};
`;

const Large = css`
  min-height: 64px;
  height: 64px;
  padding: 20px 0;
  ${fonts.button[1]};
`;

export const ButtonTextStyle = css`
  width: fit-content;

  @media (max-width: ${breakpoints.xs}px) {
    ${XSmall}
  }
  @media (max-width: ${breakpoints.sm}px) {
    ${Small}
  }
  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md}px) {
    ${Medium}
  }
  @media (min-width: ${breakpoints.md}px) {
    ${Large}
  }

  // Override default size if specific size is provided
  &.x-small {
    ${XSmall}
  }
  &.small {
    ${Small}
  }
  &.medium {
    ${Medium}
  }
  &.large {
    ${Large}
  }
`;
