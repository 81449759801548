import { css } from 'styled-components';
import { NOT_IMPLEMENTED } from 'styles/theme';
import { ButtonTextStyle } from '../sizes';

const Gray = css`
  color: var(--color-gray-300);

  > svg {
    color: var(--color-gray-300);
  }

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-base-light);

    > svg {
      color: var(--color-base-light);
    }
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-gray-25);

    > svg {
      color: var(--color-gray-25);
    }
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-gray-700);

    > svg {
      color: var(--color-gray-700);
    }
  }
`;

const Blue = css`
  color: var(--color-blue-400);

  > svg {
    color: var(--color-blue-400);
  }

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-200);

    > svg {
      color: var(--color-blue-200);
    }
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-500);

    > svg {
      color: var(--color-blue-500);
    }
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-blue-700);

    > svg {
      color: var(--color-blue-700);
    }
  }
`;

const BlueLightTheme = css`
  color: var(--color-blue-500);

  > svg {
    color: var(--color-blue-500);
  }

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-200);

    > svg {
      color: var(--color-blue-200);
    }
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-600);

    > svg {
      color: var(--color-blue-600);
    }
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-blue-50);

    > svg {
      color: var(--color-blue-50);
    }
  }
`;

export const TextVariantStyle = css`
  ${ButtonTextStyle}

  &.gray {
    ${Gray}
  }

  &.blue {
    ${Blue}
  }

  &.blue-light-theme {
    ${BlueLightTheme}
  }

  &:not(.gray):not(.blue):not(.blue-light-theme) {
    ${NOT_IMPLEMENTED}
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }

  &.no-border {
    border: none !important;
  }
`;
