import { Button } from 'components/shared';
import { links } from 'constants/links';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { fonts } from 'styles/fonts';
import { CatalogButton } from './chain-catalog-button';

interface Props {
  isInSearchMode: boolean;
  hasActiveFeeds: boolean;
}

export const ChainPageFooter = ({ isInSearchMode, hasActiveFeeds }: Props) => {
  if (!isInSearchMode) {
    return hasActiveFeeds ? <CatalogButton /> : null;
  }

  return (
    <Container>
      <span>Still don’t see what you need?</span>

      <Button
        variant="secondary"
        color="gradient"
        href={links.FORM_REQUEST_DATA_FEED}
        size="small"
        md={{
          size: 'large',
        }}
        iconEnd={null}
      >
        Request a new data feed
      </Button>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  margin-top: 24px;
  padding: 80px 0;
  width: 100%;
  position: relative;

  span {
    color: var(--color-base-light);
    ${fonts.heading[9]}
    white-space: nowrap;
  }

  a {
    margin: 0 auto;
    white-space: nowrap;
  }

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    padding: 144px;
    gap: 80px;

    span {
      font-size: 32px;
      line-height: 130%;
    }

    a {
      margin: unset;
    }
  }
`;
