import { useNotificationBannerContext } from 'components/layout/notification-banner';
import type { BannerProps } from 'components/layout/banner';
import { useEffect } from 'react';
import { getFormattedRemainingTime } from 'utils/format';
import { WarningBanner } from 'components/layout/warning-banner';
import { getMaintenanceStatus } from './maintenance-utils';

const REFRESH_INTERVAL_SHORT = 1 * 1000; // 1 second
const REFRESH_INTERVAL = 15 * 1000; // 15 seconds
const REFRESH_TRESHOLD = REFRESH_INTERVAL + 5 * 1000; // 20 seconds
let bannerSeen = false;

const processMaintenance = (setBanner: (banner: BannerProps) => void) => {
  const { maintenanceDate, maintenanceStatus } = getMaintenanceStatus();

  if (maintenanceStatus === 'upcoming-maintenance') {
    bannerSeen = true;

    setBanner({
      content: <WarningBanner title={`Scheduled maintenance in ${getFormattedRemainingTime(maintenanceDate)}`} />,
      type: 'maintenance',
    });
  }

  if (maintenanceStatus === 'under-maintenance') {
    if (!bannerSeen) return;

    // If we previously showed a maintenance banner, refresh the page
    window.location.reload();
  }
};

export const useShowMaintenanceBanner = () => {
  const { setBanner } = useNotificationBannerContext();
  const { maintenanceDate, maintenanceStatus } = getMaintenanceStatus();

  const timeTillMaintenance = maintenanceDate ? maintenanceDate.getTime() - Date.now() : 0;
  const refreshMoreOften = timeTillMaintenance < REFRESH_TRESHOLD && timeTillMaintenance > 0;

  // If there's a banner to show, show it on mount
  useEffect(() => {
    processMaintenance(setBanner);
  }, []);

  // Refresh periodically to update the remaining time
  useEffect(() => {
    if (!maintenanceStatus) return;

    const interval = setInterval(
      () => {
        processMaintenance(setBanner);
      },
      refreshMoreOften ? REFRESH_INTERVAL_SHORT : REFRESH_INTERVAL
    );

    return () => clearInterval(interval);
  });
};
