import { api3Chains } from '@api3/dapi-management';
import { RefObject } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { useSimpleSearchParam } from 'hooks/use-simple-search-param';
import { breakpoints } from 'styles/theme';
import { useDetectPageScroll } from 'hooks';
import { scrollBehaviourCss } from 'styles/scroll-behaviour-css';
import { ChainSearch } from './chain-search';
import { ChainsDropdown } from './chain-dropdown';
import { SearchExamples } from './search-examples';

interface Props {
  chain: api3Chains.Chain;
  options: api3Chains.Chain[];
  contentRef: RefObject<HTMLDivElement>;
}

export const ChainPageToolbox = ({ chain, options, contentRef }: Props) => {
  const { searchParamQueryValue, onSearchChange, onSearchSubmit, onUserInputClear, setSearchValue, userInput } =
    useSimpleSearchParam();

  const { pageOffset, pageScrollDirection } = useDetectPageScroll(contentRef);

  const isInSearchMode = !!searchParamQueryValue;

  return (
    <Container
      className={clsx({
        [`scroll-direction-${pageScrollDirection}`]: pageOffset > 0,
        'search-mode': isInSearchMode,
      })}
    >
      <SearchContainer className={clsx({ 'search-mode': isInSearchMode })}>
        <ChainsDropdown selectedChain={chain} dropdownOptions={options} isInSearchMode={isInSearchMode} />

        <ChainSearch
          chain={chain}
          isInSearchMode={isInSearchMode}
          onSearchChange={onSearchChange}
          onSearchSubmit={onSearchSubmit}
          onUserInputClear={onUserInputClear}
          setSearchValue={setSearchValue}
          userInput={userInput}
          searchParamQueryValue={searchParamQueryValue}
        />

        {!isInSearchMode && <SearchExamples onClick={setSearchValue} />}
      </SearchContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: stretch;

  &.search-mode {
    ${scrollBehaviourCss};
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 328px;
  width: 100%;
  gap: 16px;

  &.search-mode {
    gap: 24px;
    max-width: 468px;
    margin-top: 16px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    max-width: 460px;

    &.search-mode {
      max-width: 500px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    max-width: 580px;
    &.search-mode {
      max-width: 912px;
      flex-direction: row;
      & > div {
        flex: 1;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    &.search-mode {
      max-width: 1078px;
    }
  }
`;
