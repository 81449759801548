import { JsonRpcProvider } from 'ethers';
import { getApi3RpcUrls } from 'utils/utils';

export const PURCHASE_TEST_CHAIN = {
  id: 81457,
  name: 'Blast',
  alias: 'blast',
  rpcUrl: getApi3RpcUrls(81457)[0],
};

export const IS_PURCHASE_TEST = localStorage.getItem('test-purchase') === 'true';

export const PURCHASE_TEST_PROVIDER = new JsonRpcProvider(PURCHASE_TEST_CHAIN.rpcUrl);
