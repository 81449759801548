import { lazy, Suspense } from 'react';
import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { useConnectedAccount } from 'hooks/chain-data';
import { Button } from 'components/shared';
import { useNavigationHistoryContext } from 'hooks/use-navigation-history';
import { TransactionStatus } from 'types';
import { getDapiPath } from 'utils/utils';
import { api3Chains } from '@api3/dapi-management';
import { links } from 'constants/links';
import { fonts } from 'styles/fonts';

const ConnectButton = lazy(() => import('components/sign-in/connect-button'));

interface Props {
  dapiName: string;
  chain: api3Chains.Chain;
  disabled: boolean;
  txStatus?: TransactionStatus;
  onActivate: () => Promise<void>;
}

export const ActivateDataFeedButtons = ({ dapiName, disabled, chain, txStatus, onActivate }: Props) => {
  const { isConnected } = useConnectedAccount();
  const { navigateBack } = useNavigationHistoryContext();

  if (txStatus === 'pending') {
    return (
      <Container>
        <Button variant="primary" color="blue" size="small" sm={{ size: 'large' }} fullWidth disabled loading>
          Pending
        </Button>
        <NeedHelpMessage />
      </Container>
    );
  }

  if (txStatus === 'success') {
    return (
      <Container>
        <Button
          variant="primary"
          color="blue"
          size="small"
          fullWidth
          sm={{ size: 'large' }}
          href={getDapiPath(chain.alias, dapiName)}
          iconEnd={null}
        >
          View {dapiName} details
        </Button>
        <NeedHelpMessage />
      </Container>
    );
  }

  return (
    <Container className="activate-buttons">
      {isConnected ? (
        <Button
          variant="primary"
          color="blue"
          size="small"
          fullWidth
          md={{ size: 'large' }}
          onClick={onActivate}
          disabled={disabled}
          loading={txStatus === 'preparing'}
        >
          {txStatus === 'preparing' ? 'Purchasing...' : `Purchase ${dapiName}`}
        </Button>
      ) : (
        <Suspense
          fallback={
            <Button variant="primary" color="blue" fullWidth size="small" md={{ size: 'large' }} disabled>
              Connect Wallet
            </Button>
          }
        >
          <ConnectButton variant="primary" color="blue" fullWidth size="small" md={{ size: 'large' }}>
            Connect Wallet
          </ConnectButton>
        </Suspense>
      )}
      <Button variant="text" size="small" fullWidth onClick={navigateBack} md={{ size: 'large' }}>
        Cancel
      </Button>
    </Container>
  );
};

const NeedHelpMessage = () => (
  <div className="need-help-note">
    Need help?{' '}
    <Button variant="link" href={links.API3_DISCORD} iconEnd={null} size="small" sm={{ size: 'large' }} color="blue">
      Reach out to us on Discord
    </Button>
  </div>
);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;

  .need-help-note {
    padding-bottom: 16px;
    text-align: center;
    ${fonts.body[15]}
    color: var(--color-dark-blue-25);

    a {
      padding-left: 6px;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    padding-top: 32px;
    &.activate-buttons {
      flex-direction: row-reverse;
    }

    .need-help-note {
      ${fonts.body[9]}
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    padding-top: 24px;
  }
`;
