export const getMaintenanceStatus = () => {
  const maintenanceModeStartTime = Math.round(Number(process.env.VITE_APP_MAINTENANCE_START_TIME));

  if (!maintenanceModeStartTime || Number.isNaN(maintenanceModeStartTime)) return {};

  const maintenanceDate = new Date(maintenanceModeStartTime * 1000);
  const now = new Date().getTime() / 1000;

  if (maintenanceModeStartTime < now) {
    return { maintenanceDate, maintenanceStatus: 'under-maintenance' };
  }

  return {
    maintenanceDate,
    maintenanceStatus: 'upcoming-maintenance',
  };
};
