import styled from 'styled-components';
import { api3Chains } from '@api3/dapi-management';
import { ChainLogo } from '@api3/logos';
import { CheckIcon, ChevronDown } from 'components/shared/icons';
import clsx from 'clsx';
import { useSelect } from 'downshift';
import { isNullish } from 'utils/utils';
import { breakpoints } from 'styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkVariantStyle } from 'components/shared/button/styles';
import { Button } from 'components/shared';
import { fonts } from 'styles/fonts';

interface Props {
  selectedChain: api3Chains.Chain;
  dropdownOptions: api3Chains.Chain[];
  isInSearchMode: boolean;
}

export const ChainsDropdown = (props: Props) => {
  const { selectedChain, dropdownOptions, isInSearchMode } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const { highlightedIndex, isOpen, getItemProps, getMenuProps, getToggleButtonProps } = useSelect({
    items: dropdownOptions || [],
    selectedItem: selectedChain,
    onSelectedItemChange({ selectedItem: newSelectedItem }) {
      if (!isNullish(newSelectedItem)) {
        // Push new chain while preserving search params
        navigate(`/${newSelectedItem.alias}${location.search}`);
      }
    },
  });

  return (
    <Container tabIndex={0} data-testid="chains-dropdown" className={clsx({ 'search-mode': isInSearchMode })}>
      <div
        className={clsx('dropdown-toggle', {
          open: isOpen,
          'single-option': dropdownOptions.length === 0,
        })}
        aria-label="Chain"
        {...getToggleButtonProps({ 'aria-labelledby': undefined })}
      >
        <div className="select-title">
          <img src={ChainLogo(selectedChain.id)} alt="" />
          <div className="chain-title">
            <span className="chain-name">{selectedChain.name}</span>
            <span className="chain-id">(ID: {selectedChain.id})</span>
          </div>
          <ChevronDown />
        </div>
      </div>

      <div className={clsx('dropdown-options', { open: isOpen })}>
        <ul {...getMenuProps({ 'aria-labelledby': undefined })} tabIndex={-1}>
          {dropdownOptions?.map((item, index) => {
            const isSelected = item.alias === selectedChain?.alias;
            const isHovered = highlightedIndex === index;

            return (
              <li
                className={clsx('chain-element', { 'current-item': isSelected || isHovered })}
                key={index}
                {...getItemProps({ item, index })}
              >
                <img src={ChainLogo(item.id)} alt="" />
                <span className="chain-name">{item.name}</span>
                <span className="chain-id">(ID: {item.id})</span>
                {isSelected && <CheckIcon className="check-icon" />}
              </li>
            );
          })}
          <li className="view-all-chains">
            <Button href="/" variant="link" color="blue" iconEnd={null} size="medium" theme="light">
              View all chains
            </Button>
          </li>
        </ul>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  white-space: nowrap;
  position: relative;

  .dropdown-toggle {
    cursor: pointer;
    padding: 12px 26px 12px 24px;
    height: 48px;
    border-radius: 32px;
    background-color: var(--color-dark-blue-700);
    transition:
      background-color,
      border-bottom,
      border-radius 0.3s;
    transition-delay: 0.5s;

    .select-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;

      .chain-title {
        display: flex;
        align-items: baseline;
        gap: 8px;
        margin: 0 auto 0 8px;
      }

      .chain-name {
        ${fonts.body[9]}
        color: var(--color-base-light);
      }

      .chain-id {
        ${fonts.body[12]}
        color: var(--color-gray-400);
      }

      img {
        width: 20px;
        height: 20px;
      }

      svg {
        width: 13px;
        height: 13px;
        transition: var(--animation-flip-chevron);
      }
    }

    &.open {
      border-bottom: 1px solid var(--color-dark-blue-300);
      border-radius: 32px 32px 0 0;
      transition-delay: 0s;

      .select-title {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &.single-option {
      cursor: default;

      svg {
        display: none;
      }
    }
  }

  .dropdown-options {
    &.open {
      ul {
        max-height: 580px;
        transition-delay: 0.3s;
      }
    }

    ul {
      position: absolute;
      min-width: 100%;
      margin: 0;
      padding: 0;
      z-index: var(--z-index-dropdown);
      background-color: var(--color-dark-blue-700);
      border-radius: 0 0 6px 6px;
      transition: all 0.5s ease;
      transition-delay: 0s;
      max-height: 0px;
      overflow: hidden;

      &:empty {
        padding: 0;
      }

      li {
        display: flex;
        align-items: center;
        padding: 8px 18px;
        height: 52px;
        cursor: pointer;
        white-space: normal;
        border-bottom: 1px solid var(--color-dark-blue-500);

        &:last-child {
          border-bottom: none;
        }
      }

      .chain-element {
        img {
          width: 20px;
          height: 20px;
        }

        .chain-name {
          padding-left: 8px;
          color: var(--color-base-light);
          text-align: left;
          ${fonts.body[9]}
        }

        .chain-id {
          color: var(--color-gray-400);
          padding-left: 8px;
          text-align: left;
          ${fonts.body[12]}
        }

        svg {
          margin-left: auto;
          color: var(--color-action-success-500);
        }

        &:hover {
          background-color: var(--color-dark-blue-600);
        }
      }

      .view-all-chains {
        justify-content: center;
        padding: 6px 16px;

        a {
          ${LinkVariantStyle}
          text-decoration: none;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .dropdown-toggle {
      padding: 16px 32px 16px 24px;
      height: 64px;

      .select-title {
        img {
          width: 36px;
          height: 36px;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        .chain-name,
        .chain-id {
          ${fonts.body[6]}
        }
      }
    }

    .dropdown-options ul {
      border-radius: 0 0 6px 6px;

      li {
        height: 58px;
        padding-left: 32px;

        &.chain-element {
          ${fonts.body[6]}

          img {
            width: 32px;
            height: 32px;
          }

          .check-icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
`;
