import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { Button } from 'components/shared';
import { createExplorerUrl } from 'utils/utils';
import { ChainLogo } from '@api3/logos';
import { useChain } from 'hooks/chain-data';
import { usePageParams } from 'hooks';
import { VerificationStatus } from 'types';
import { fonts } from 'styles/fonts';
import { VerifiedProxyAddress } from './verified-proxy-address';

interface Props {
  proxyAddress?: string;
  verificationStatus: VerificationStatus;
}

export const ProxyParamsBox = ({ proxyAddress, verificationStatus }: Props) => {
  const { chainAlias } = usePageParams();
  const { chain, chainId = '', chainName } = useChain(chainAlias);
  const explorerUrl = chain?.explorer.browserUrl;

  return (
    <ProxyParamsTable>
      <tbody>
        <tr>
          <td>
            <span>
              Api3ReaderProxyV1 address:
              <span className="proxy-chain">
                on <img src={ChainLogo(String(chainId))} width={16} height={16} alt={`${chainName} logo`} /> {chainName}
              </span>
            </span>
            <VerifiedProxyAddress proxyAddress={proxyAddress} verificationStatus={verificationStatus} />
          </td>
        </tr>
        <tr>
          <td className="block-explorer-link">
            {explorerUrl && (
              <Button
                variant="link"
                color="blue"
                disabled={!proxyAddress}
                href={proxyAddress && createExplorerUrl(explorerUrl, proxyAddress, 'address')}
              >
                View on block explorer
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    </ProxyParamsTable>
  );
};

const ProxyParamsTable = styled.table`
  width: 100%;
  max-width: 524px;
  margin: 0 auto 24px auto;

  td {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid var(--color-dark-blue-300);

    &.block-explorer-link {
      display: flex;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        gap: 2px;
      }

      svg {
        width: 10px;
        height: 10px;
      }
    }

    span {
      ${fonts.body[11]};
      color: var(--color-base-light);

      .proxy-chain {
        ${fonts.body[15]};
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  @media (min-width: ${breakpoints.sm}px) {
    max-width: 600px;
    margin-bottom: 32px;

    td {
      padding: 16px 0;

      span {
        ${fonts.body[8]};

        .proxy-chain {
          ${fonts.body[12]};

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    td.block-explorer-link {
      a {
        gap: 4px;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
`;
