import { ReactNode } from 'react';
import { ArrowUpIcon } from 'components/shared/icons';
import { StyledEmptyTableRow, StyledTable, StyledWrapper } from 'components/shared/table/styles';
import { ISortingObj, ISortType } from 'types/sorting';
import clsx from 'clsx';
import { DEFAULT_TABLE_ROW_COUNT } from 'constants/constants';
import { handleInterpolation } from 'utils/utils';
import { SmartLink } from 'components/smart-link';
import { links } from 'constants/links';
import {
  COULDNT_FIND_RECORDS,
  COULDNT_FIND_RECORDS_DESC,
  COULDNT_FIND_RECORDS_FILTERS,
  COULDNT_FIND_RECORDS_REQUEST_DATA,
  TABLE_ERROR,
  TABLE_ERROR_DESCRIPTION,
} from './messages';
import { TableRow } from './table-row';
import { TablePagination } from './table-pagination';

export interface IColumn {
  label: string;
  sortable?: boolean;
  dataKey: string;
  render?: (dataKeyValue: any, row: any) => ReactNode;
  cellClassName?: string;
  columnClassName?: string;
}

interface Props {
  columns: IColumn[];
  rows?: Record<string, any>[] | null;
  hiddenColumnsKeys?: string[];
  onRowClick?: (row: any) => void;
  totalItems?: number;
  currentPage?: number;
  sortObj?: ISortingObj;
  loading?: boolean;
  error?: boolean;
  isFilterApplied?: boolean;
  itemsPerPage?: number;
  className?: string;
  searchText?: string;
  onPageChange?: (newPage: number) => void;
  onSortChange?: (sortObj: ISortingObj) => void;
  emptyTableText?: string;
  emptyTableDescription?: string;
}

export const Table = ({
  columns = [],
  rows = [],
  hiddenColumnsKeys = [],
  onRowClick,
  searchText = '',
  totalItems = DEFAULT_TABLE_ROW_COUNT,
  currentPage = 1,
  sortObj = { sortKey: '', sortType: 'asc' },
  loading = false,
  error = false,
  isFilterApplied = false,
  itemsPerPage = DEFAULT_TABLE_ROW_COUNT,
  className = '',
  onPageChange,
  onSortChange,
  emptyTableText = '',
  emptyTableDescription = '',
}: Props) => {
  const setPreciseColumnSorting = (columnDataKey: string, sortType: ISortType) => {
    // toggle off if already set
    if (sortObj.sortKey === columnDataKey && sortObj.sortType === sortType && onSortChange) {
      onSortChange({ sortType: '', sortKey: '' });
    } else if (onSortChange) {
      onSortChange({ sortKey: columnDataKey, sortType });
    }
  };

  const toggleColumnSorting = (columnDataKey: string) => {
    let newSortType: ISortType = '';
    if (sortObj.sortKey === columnDataKey && sortObj.sortType === 'asc') {
      newSortType = 'desc';
    } else if (sortObj.sortKey === columnDataKey && sortObj.sortType === 'desc') {
      newSortType = '';
    } else {
      newSortType = 'asc';
    }

    if (onSortChange) {
      onSortChange({ sortKey: columnDataKey, sortType: newSortType });
    }
  };

  const checkColumnSorted = (columnDataKey: string, sortType: ISortType) =>
    sortObj.sortKey === columnDataKey && sortObj.sortType === sortType;

  const handleRowClick = (row: IColumn) => {
    if (!loading && onRowClick && typeof onRowClick === 'function') onRowClick(row);
  };

  const getColumnAriaLabel = (column: IColumn) => {
    let nextSortType = '';
    if (column.dataKey === sortObj.sortKey) {
      switch (sortObj.sortType) {
        case '':
          nextSortType = 'ascending';
          break;
        case 'asc':
          nextSortType = 'descending';
          break;
        case 'desc':
          nextSortType = 'disable';
          break;
        default:
          break;
      }
    } else {
      nextSortType = 'ascending';
    }

    return `sort column ${nextSortType}`;
  };

  let rowsToRender = loading ? Array.from(Array(itemsPerPage)).fill(null) : rows || [];
  const columnsToRender = columns.filter((column) => !hiddenColumnsKeys?.includes(column.dataKey));
  const noRecords = (!rows || rows?.length === 0) && !loading && !error;
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const receivedFullTableData = rows?.length === totalItems;
  rowsToRender = receivedFullTableData
    ? rowsToRender.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    : rowsToRender;

  return (
    <StyledWrapper className={`table-container ${className}`}>
      <div className="table-borders">
        <StyledTable className="table" aria-label="dAPIs table">
          <thead className="table-head">
            <tr>
              {columnsToRender.map((column, index) => (
                <th
                  key={`${column.dataKey}-${index}`}
                  className={clsx(column.columnClassName, {
                    sortable: column.sortable,
                  })}
                >
                  <span
                    tabIndex={column.sortable && !loading ? 0 : -1}
                    role="button"
                    aria-label={column.sortable && !loading ? getColumnAriaLabel(column) : column.label}
                    className="column-label"
                    onClick={column.sortable && !loading ? () => toggleColumnSorting(column.dataKey) : undefined}
                    onKeyDown={(event) => {
                      if (column.sortable && !loading && event.key === 'Enter') {
                        toggleColumnSorting(column.dataKey);
                      }
                    }}
                  >
                    {column.label}
                  </span>
                  {column.sortable && (
                    <span className="column-actions">
                      <button
                        type="button"
                        aria-label={`sort column ${column.label} ascending ${
                          sortObj.sortKey === column.dataKey && sortObj.sortType === 'asc' ? 'disable' : 'enable'
                        }`}
                        className={clsx('sort-btn sort-asc', checkColumnSorted(column.dataKey, 'asc') && 'active')}
                        onClick={() => setPreciseColumnSorting(column.dataKey, 'asc')}
                        disabled={loading || error}
                      >
                        <ArrowUpIcon />
                      </button>
                      <button
                        type="button"
                        aria-label={`sort column ${column.label} descending ${
                          sortObj.sortKey === column.dataKey && sortObj.sortType === 'desc' ? 'disable' : 'enable'
                        }`}
                        className={clsx('sort-btn sort-desc', checkColumnSorted(column.dataKey, 'desc') && 'active')}
                        onClick={() => setPreciseColumnSorting(column.dataKey, 'desc')}
                        disabled={loading || error}
                      >
                        <ArrowUpIcon />
                      </button>
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="table-body">
            {rowsToRender.map((row, index) => (
              <TableRow
                key={`row-${index}`}
                columnsToRender={columnsToRender}
                handleRowClick={handleRowClick}
                loading={loading}
                onRowClick={onRowClick}
                row={row}
              />
            ))}
            {/* Used on Table page */}
            {noRecords && (!!searchText || isFilterApplied) ? (
              <StyledEmptyTableRow className="table-empty-row">
                <td colSpan={columnsToRender.length}>
                  <div className="error-msg">
                    {searchText
                      ? handleInterpolation(COULDNT_FIND_RECORDS, { searchText })
                      : COULDNT_FIND_RECORDS_FILTERS}
                  </div>
                  <div className="error-desc">{COULDNT_FIND_RECORDS_DESC}</div>
                  <div className="error-link">
                    <SmartLink href={links.FORM_REQUEST_DATA_FEED}>{COULDNT_FIND_RECORDS_REQUEST_DATA}</SmartLink>
                  </div>
                </td>
              </StyledEmptyTableRow>
            ) : noRecords ? (
              <StyledEmptyTableRow className="table-empty-row">
                <td colSpan={columnsToRender.length}>
                  <div className="error-msg">{emptyTableText || TABLE_ERROR}</div>
                  <div className="error-desc">{emptyTableDescription || TABLE_ERROR_DESCRIPTION}</div>
                </td>
              </StyledEmptyTableRow>
            ) : error ? (
              <StyledEmptyTableRow className="table-empty-row">
                <td colSpan={columnsToRender.length}>
                  <div className="error-msg">{TABLE_ERROR}</div>
                  <div className="error-desc">{TABLE_ERROR_DESCRIPTION}</div>
                </td>
              </StyledEmptyTableRow>
            ) : null}
          </tbody>
        </StyledTable>

        <TablePagination
          currentPage={currentPage}
          isHidden={loading}
          pageCount={pageCount}
          showContent={!loading}
          onPageChange={onPageChange}
        />
      </div>
      <div className="divider-bottom" />
      <div className="divider-bottom divider-bottom-right" />
    </StyledWrapper>
  );
};
