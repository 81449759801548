import { ComponentProps } from 'react';

export const DiscordIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none" {...props}>
      <path
        d="M15.0702 0.90643L14.7993 1.21496C18.0727 2.21579 19.5928 3.66061 19.5928 3.66061C15.0176 1.15476 9.64465 1.15476 5.20485 3.10375C4.48997 3.43486 4.06856 3.66061 4.06856 3.66061C4.06856 3.66061 5.66388 2.14054 9.13294 1.13971L8.93729 0.90643C8.93729 0.90643 6.29599 0.846228 3.52676 2.93068C3.52676 2.93068 0.75 7.95743 0.75 14.1581C0.75 14.1581 2.36789 16.9499 6.62709 17.0854C6.62709 17.0854 7.34197 16.22 7.9214 15.49C5.47575 14.7601 4.55017 13.2175 4.55017 13.2175C4.83612 13.4131 5.30267 13.6765 5.3403 13.6991C8.59114 15.5201 13.2115 16.1146 17.3579 14.3763C18.0351 14.128 18.7801 13.7593 19.5702 13.24C19.5702 13.24 18.607 14.8203 16.0861 15.5352C16.6656 16.2651 17.3579 17.0929 17.3579 17.0929C21.6171 16.9574 23.25 14.1656 23.25 14.1656C23.25 7.96496 20.4732 2.9382 20.4732 2.9382C17.7115 0.846229 15.0702 0.90643 15.0702 0.90643ZM8.41053 12.3671C7.33445 12.3671 6.44649 11.4039 6.44649 10.23C6.44649 9.05609 7.31187 8.09288 8.41053 8.09288C9.5092 8.09288 10.3972 9.05609 10.3746 10.23C10.3746 11.4039 9.5092 12.3671 8.41053 12.3671ZM15.439 12.3671C14.3629 12.3671 13.4749 11.4039 13.4749 10.23C13.4749 9.05609 14.3403 8.09288 15.439 8.09288C16.5376 8.09288 17.403 9.05609 17.403 10.23C17.403 11.4039 16.5376 12.3671 15.439 12.3671Z"
        fill="currentColor"
      />
    </svg>
  );
};
