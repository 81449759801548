import { ComponentPropsWithoutRef } from 'react';

export const HelpIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <circle cx="7.00077" cy="6.99979" r="6.28788" stroke="currentColor" />
      <path
        d="M5.41016 5.66105C5.48213 4.78545 6.25235 4.13383 7.13048 4.2056C7.93394 4.27127 8.59016 4.88135 8.59016 5.71303C8.59016 6.65494 7.70393 6.93257 7.13048 7.6363C6.86983 7.95618 6.76557 8.15467 6.76557 8.77843M6.80012 10.2002H6.75012"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
