import styled from 'styled-components';
import { CrossIcon } from 'components/shared/icons';
import clsx from 'clsx';
import { Button, ButtonProps } from '../button/button';

interface Props extends Omit<ButtonProps, 'variant' | 'color'> {
  color?: 'white' | 'black' | 'gray';
  strokeWidth?: number;
  onClick: () => void;
}

export const CloseButton = ({ color = 'white', strokeWidth = 2, onClick, className }: Props) => (
  <StyledCloseButton
    className={clsx('close-button', className, {
      [`close-button-${color}`]: color,
    })}
    variant="tertiary"
    color="gray"
    size="x-small"
    iconOnly
    onClick={onClick}
  >
    <CrossIcon strokeWidth={strokeWidth} />
    <span className="sr-only">Close</span>
  </StyledCloseButton>
);

const StyledCloseButton = styled(Button)`
  border: none !important;

  &.close-button-white > svg {
    color: var(--color-base-light);

    &:hover {
      color: var(--color-gray-300);
    }
  }

  &.close-button-gray > svg {
    color: var(--color-gray-900);

    &:hover {
      color: var(--color-gray-500);
    }
  }

  &.close-button-black > svg {
    color: var(--color-black);

    &:hover {
      color: var(--color-gray-900);
    }
  }
`;
