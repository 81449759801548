import styled from 'styled-components';
import Skeleton from 'components/shared/skeleton';
import { api3Chains } from '@api3/dapi-management';
import { ChainLogo } from '@api3/logos';
import { breakpoints, gradientBorder } from 'styles/theme';
import { keepLastTwoWordsTogether } from 'utils/utils';

interface Props {
  chain: api3Chains.Chain;
}

export const NetworkCard = ({ chain }: Props) => {
  return (
    <StyledNetworkCard>
      <div className="card-bg">
        <img src={ChainLogo(chain.id)} alt={`${chain.name} logo`} />
        <div className="chain-name">{keepLastTwoWordsTogether(chain.name)}</div>
        <div className="chain-id">ID: {chain.id}</div>
      </div>
    </StyledNetworkCard>
  );
};

export const NetworkCardSkeleton = () => {
  return (
    <StyledNetworkCard>
      <Skeleton enableAnimation width="100px" height="25px" />
      <Skeleton enableAnimation width="100px" height="25px" />
      <Skeleton enableAnimation width="100px" height="25px" />
    </StyledNetworkCard>
  );
};

const StyledNetworkCard = styled.div`
  position: relative;
  display: flex;
  width: 156px;
  height: 128px;
  cursor: pointer;
  transition: background-color var(--default-transition-duration);

  .card-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    background: radial-gradient(183.92% 131.37% at 50.04% 13.63%, rgba(44, 54, 161, 0.4) 0%, rgba(9, 13, 56, 0) 100%),
      #050818;
    border-radius: 20px;
    border: 1px solid var(--color-dark-blue-400);
  }

  &:hover {
    .card-bg {
      border: 1px solid var(--color-dark-blue-100); // Fallback for browsers who don't support gradient border
      ${gradientBorder('blue-01-stroke')}
    }
  }

  &:active,
  &:focus {
    .card-bg {
      border: 1px solid var(--color-dark-blue-100); // Fallback for browsers who don't support gradient border
      ${gradientBorder('general-blue')}
    }
  }

  & img {
    width: 32px;
    height: 32px;
  }

  & .chain-name {
    margin-top: 8px;
    color: var(--color-base-light);
    font-size: 12px;
    font-weight: 400;
    line-height: 170%;
    text-align: center;
    box-sizing: border-box;
  }

  & .chain-id {
    color: var(--color-gray-400);
    font-size: 10px;
    line-height: 170%;
  }

  & .react-loading-skeleton {
    margin-bottom: 10px;
  }

  @media (min-width: ${breakpoints.md}px) {
    width: 222px;
    height: 180px;

    .card-bg {
      padding: 24px 32px;
    }

    & .chain-name {
      font-size: 14px;
      line-height: 140%;
    }

    & img {
      width: 64px;
      height: 64px;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 234px;
    height: 196px;

    & .chain-name {
      margin-top: 16px;
      font-size: 16px;
    }

    & .chain-id {
      margin-top: 4px;
      font-size: 12px;
      line-height: 140%;
    }
  }
`;
