import { useState } from 'react';
import { Button } from 'components/shared';
import { GasIcon } from 'components/shared/icons';
import { links } from 'constants/links';
import styled from 'styled-components';
import { breakpoints, gradientBorder } from 'styles/theme';
import { fonts } from 'styles/fonts';
import { CloseButton } from 'components/shared/close-button/close-button';
import { getMaintenanceStatus } from 'components/maintenance/maintenance-utils';
import { useNotificationBannerContext } from './notification-banner';

interface Props {
  isOnChainPage: boolean;
}

export const RequestGrantBanner = ({ isOnChainPage }: Props) => {
  const [hidden, setHidden] = useState(false);

  const { banner } = useNotificationBannerContext();
  const { maintenanceStatus } = getMaintenanceStatus();

  const showGrantBanner = isOnChainPage && !maintenanceStatus;

  // Hide this banner if there is any other one already displayed, as this one has the lowest prio. Priorities are mentioned in the banner.tsx file
  if (banner.content || !showGrantBanner || hidden) {
    return null;
  }

  return (
    <ContainerWithBorders>
      <div className="request-banner-border" />

      <Container>
        <div className="banner-content">
          <GasIcon className="gas-icon" />
          <div className="request-text-and-button">
            <div className="request-text">Need us to purchase plans for you?</div>
            <Button
              variant="secondary"
              color="white"
              theme="light"
              size="xx-small"
              md={{ size: 'x-small' }}
              href={links.FORM_REQUEST_GRANT}
              iconEnd={null}
            >
              Request a grant for your dApp
            </Button>
          </div>
        </div>

        <CloseButton className="close-button" color="black" strokeWidth={2.5} onClick={() => setHidden(true)} />
      </Container>

      <div className="request-banner-border" />
    </ContainerWithBorders>
  );
};

const ContainerWithBorders = styled.div`
  position: relative;

  .request-banner-border {
    pointer-events: none;
    height: 1px;

    background: linear-gradient(89deg, #1a4fde 0%, #5effd7 51%, #f3f3f3 68%, rgba(26, 79, 222, 0.5) 100%);
    ${gradientBorder('general-soft')}
  }
`;

const Container = styled.div`
  background: var(--color-blue-25);
  height: 82px;
  width: 100%;
  padding: 16px 24px;
  display: flex;

  .banner-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  .request-text {
    ${fonts.body[13]}
    color: var(--color-blue-400);
  }

  .request-text-and-button {
    display: flex;
    flex-direction: column;
    gap: 8px;
    white-space: nowrap;
  }

  .gas-icon {
    min-width: 43px;
    min-height: 43px;
  }

  .close-button {
    margin: -4px -6px 0 0;
    width: 32px;
    height: 32px !important;
    min-height: 32px !important;

    svg {
      min-width: 20px;
      min-height: 20px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    height: 72px;
    padding: 16px 32px;

    .request-text-and-button {
      flex-direction: row;
      gap: 24px;
      align-items: center;
    }

    .request-text {
      ${fonts.body[7]}
    }

    .close-button {
      width: 40px;
      height: 40px !important;
      min-height: 40px !important;
      margin: auto 0;

      svg {
        min-width: 28px;
        min-height: 28px;
      }
    }
  }
`;
