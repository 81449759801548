import { ArrowRight as Arrow } from 'components/shared/icons';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';

interface Props {
  currentPage: number;
  isHidden: boolean;
  pageCount: number;
  showContent: boolean;
  onPageChange?: (newPage: number) => void;
}

// ReactPaginate starts the count from 0
// https://github.com/AdeleD/react-paginate/issues/167#issuecomment-388386261
export const TablePagination = (props: Props) => {
  const { currentPage, isHidden, pageCount, showContent, onPageChange } = props;

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newPage = selected + 1;

    if (!!onPageChange && typeof onPageChange === 'function') {
      onPageChange(newPage);
    }
  };

  return (
    <StyledPaginationWrapper
      data-testid="pagination-container"
      className="pagination-container"
      style={{
        visibility: isHidden ? 'hidden' : 'visible',
      }}
    >
      {showContent && (
        <ReactPaginate
          className="pagination-content"
          nextLabel={
            <span className="next-label">
              <span>Next</span>
              <Arrow />
            </span>
          }
          onPageChange={handlePageChange}
          pageRangeDisplayed={2}
          marginPagesDisplayed={3}
          pageCount={pageCount}
          previousLabel={
            <span className="previous-label">
              <Arrow />
              <span>Previous</span>
            </span>
          }
          breakLabel="..."
          activeClassName={pageCount === 1 ? 'only-one-active' : 'active'}
          forcePage={currentPage - 1}
        />
      )}
    </StyledPaginationWrapper>
  );
};

const StyledPaginationWrapper = styled.div`
  margin-top: 32px;

  & ul {
    margin: 0 auto;
  }

  .pagination-content {
    display: flex;
    width: 100%;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding: 0;

    .next-label {
      svg {
        margin-left: 8px;
        transform: rotate(180deg) translateY(-1px);
      }
    }

    .previous-label {
      svg {
        margin-right: 8px;
        transform: translateY(1px);
      }
    }

    a {
      transition: color 200ms;
    }

    li.active a {
      color: var(--color-blue-100);
    }

    li:hover a {
      color: var(--color-base-light);
    }

    li.only-one-active a {
      color: var(--color-gray-400);
      cursor: default;
    }

    li.disabled > a {
      color: var(--color-gray-400);
      cursor: not-allowed;
    }

    @media (max-width: ${breakpoints.sm}px) {
      margin: 0;

      .previous-label,
      .next-label {
        > span {
          display: none;
        }

        svg {
          margin: 0;
        }
      }
    }

    & > li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--color-blue-25);
      user-select: none;

      &.previous {
        margin-left: 0;
        margin-right: auto;
      }

      &.next {
        margin-right: 0;
        margin-left: auto;
      }

      a {
        font-size: 14px;
        line-height: 24px;
        padding: 0 10px;
      }
    }
  }
`;
