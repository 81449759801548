import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { api3Chains } from '@api3/dapi-management';
import { ApiProviderLogo, ChainLogo } from '@api3/logos';
import Skeleton from 'components/shared/skeleton/skeleton';
import { Subscription } from 'types';
import { DapiName, DatafeedLogos } from 'components/shared';
import { formatDeviation, formatHeartbeat } from 'utils/format';
import { api3ApiIntegrations, dapisByName } from 'utils/dapis';
import { fonts } from 'styles/fonts';
import { UpgradeOptionDropdown } from './upgrade-option-dropdown';
import { OrderDetailsWrapper } from './order-details-wrapper';

interface Props {
  chain: api3Chains.Chain;
  dapiName: string;
  txInProgress: boolean;
  subscription?: Subscription;
  subscriptionOptions?: Subscription[];
  dataLoadingError: boolean;
  onSubscriptionChange: (subscriptionIndex: number) => void;
}

export const OrderDetails = ({
  chain,
  dapiName,
  subscription,
  txInProgress,
  dataLoadingError,
  onSubscriptionChange,
  subscriptionOptions,
}: Props) => {
  const sources = dapisByName[dapiName].providers.map((provider) => {
    const airnodeAddress = api3ApiIntegrations.getAirnodeAddressByAlias(provider);
    const apiProviderData = api3ApiIntegrations.getApiUrls(airnodeAddress);
    return {
      name: apiProviderData.name,
      alias: provider,
    };
  });
  return (
    <OrderDetailsWrapper txInProgress={txInProgress}>
      <Container>
        <div className="field-label">Chain</div>
        <div className="field-value">
          <img src={ChainLogo(chain.id)} alt={`Chain logo for ${chain.name}`} className="chain-logo" />
          {chain.name}
        </div>
        <div className="field-label">Data Feed</div>
        <div className="field-value data-feed">
          <DatafeedLogos dapiName={dapiName} size="sm" md={{ size: 'md' }} />
          <DapiName name={dapiName} className="dapi-name" color="blue" />
        </div>
        <div className="field-label">
          Sources <span className="sources-count">({sources.length})</span>
        </div>
        <div className="field-value sources">
          {sources.map((source) => (
            <div key={source.alias} className="source">
              <img src={ApiProviderLogo(source.alias)} className="source-logo" alt={source.name} />
              <div className="source-name">{source.name}</div>
            </div>
          ))}
        </div>
        {!subscription || !subscriptionOptions ? (
          <div className="field-label">
            <Skeleton height="50px" width="100%" enableAnimation={!dataLoadingError} />
          </div>
        ) : txInProgress ? (
          <>
            <div className="field-label">Parameters</div>
            <div>
              <div className="field-value parameters-text">
                {formatDeviation(subscription.updateParameters.deviationThreshold)} deviation
                <span className="separator">|</span>
                {formatHeartbeat(subscription.updateParameters.heartbeatInterval)} heartbeat
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="field-label">Select your Parameters</div>
            <UpgradeOptionDropdown
              selected={subscription}
              options={subscriptionOptions}
              onSelect={onSubscriptionChange}
            />
          </>
        )}
        <div className="field-label gas-costs-note">Operating gas costs included in all specifications</div>
      </Container>
    </OrderDetailsWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .field-label {
    ${fonts.body[15]}
    color: var(--color-dark-blue-25);
    padding-top: 8px;
    padding-bottom: 4px;

    &.gas-costs-note {
      ${fonts.body[15]}
    }

    span.sources-count {
      color: var(--color-base-light);
    }
  }

  .chain-logo {
    width: 24px;
    height: 24px;
  }

  .field-value {
    display: flex;
    align-items: center;
    gap: 8px;
    ${fonts.body[12]}
    color: var(--color-base-light);
    margin-bottom: 8px;

    &.parameters-text {
      margin-bottom: 0px;
    }

    &.data-feed {
      height: 40px;
      ${fonts.body[11]}
    }

    &.sources {
      align-items: flex-start;
      margin-bottom: 0px;
      margin-top: 10px;
      gap: 0px;
    }
  }

  .source-logo {
    width: 26px;
    height: 26px;
    box-shadow: 0px 0px 8px 0px rgba(17, 22, 72, 0.7);
    border-radius: 100%;
  }

  .source-logo:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  .source {
    width: 21px;
    height: 40px;
    margin-left: -3px;

    &:first-of-type {
      margin-left: 0;
    }

    .source-name {
      display: none;
    }

    &:hover {
      .source-name {
        display: block;
        ${fonts.overline[2]};
        color: var(--color-base-light);
        white-space: nowrap;
      }
    }
  }

  .separator {
    color: var(--color-gray-500);
  }

  @media (min-width: ${breakpoints.sm}px) {
    .field-label {
      ${fonts.body[12]}
      padding-top: 12px;

      &.gas-costs-note {
        ${fonts.body[12]}
        padding-top: 8px;
        padding-bottom: 12px;
      }
    }

    .field-value {
      ${fonts.body[9]}
      margin-bottom: 12px;

      &.data-feed {
        ${fonts.body[8]}
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .field-label {
      ${fonts.body[9]}
      padding: 16px 0 8px 0;

      &.gas-costs-note {
        padding-bottom: 16px;
      }
    }

    .field-value {
      ${fonts.body[6]}
      margin-bottom: 16px;

      &.parameters-text {
        margin-bottom: 0px;
      }

      &.data-feed {
        ${fonts.body[5]}
      }
    }

    .source-logo {
      width: 32px;
      height: 32px;
    }

    .source {
      width: 32px;
      height: 56px;
      margin-left: -6px;
    }
  }
`;
