import styled from 'styled-components';
import clsx from 'clsx';
import { ErrorCircleFillIcon } from 'components/shared/icons';
import { breakpoints } from 'styles/theme';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { CloseButton } from 'components/shared/close-button/close-button';
import { usePrevious } from 'hooks/use-previous';
import { ErrorMessage } from 'types';
import { fonts } from 'styles/fonts';
import { Button } from 'components/shared/button/button';

interface Props {
  error: ErrorMessage;
  closeBanner?: () => void;
}

export const ErrorBanner = ({ error: { message, secondaryMessage }, closeBanner }: Props) => {
  const location = useLocation();

  const pageWithError = usePrevious(location.pathname);

  // Hide the error banner on page change
  useEffect(() => {
    if (pageWithError && location.pathname !== pageWithError) {
      closeBanner?.();
    }
  }, [location.pathname, pageWithError]);

  const isReloadMessage = secondaryMessage.includes('reload');

  return (
    <Container className={clsx({ closable: !!closeBanner })}>
      <div className="banner-content">
        <ErrorCircleFillIcon className="error-icon" />
        <div className="text-container">
          <div className="message">{message}</div>
          {isReloadMessage ? (
            <Button
              className="reload-message"
              variant="link"
              theme="light"
              color="blue"
              onClick={() => window.location.reload()}
            >
              {secondaryMessage}
            </Button>
          ) : (
            <div className="secondary-message">{secondaryMessage}</div>
          )}
        </div>
      </div>
      {closeBanner && <CloseButton className="close-button" color="black" strokeWidth={2.5} onClick={closeBanner} />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  color: var(--color-base-dark);
  display: flex;
  gap: 8px;
  justify-content: space-between;

  &.closable {
    padding-left: 40px; // To compensate for the close button on the right side
  }

  .banner-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .error-icon {
    color: var(--color-action-error-600);
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  .close-button {
    margin: -4px -6px 0 0;
    width: 32px;
    height: 32px !important;
    min-height: 32px !important;

    svg {
      min-width: 20px;
      min-height: 20px;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .message {
    ${fonts.body[10]};
    padding-right: 8px;
  }

  .secondary-message {
    ${fonts.link[3]};

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .reload-message {
    padding: 0;
    margin: auto 0;
  }

  @media (min-width: ${breakpoints.sm}px) {
    &.closable {
      padding-left: 48px; // To compensate for the close button on the right side
    }

    align-items: center;

    .error-icon {
      width: 24px;
      height: 24px;
    }

    .close-button {
      margin: -6px 0;
      width: 32px;
      height: 32px !important;
    }

    .text-container {
      flex-direction: row;
    }

    .message {
      ${fonts.body[8]};
    }

    .secondary-message {
      ${fonts.link[2]};
    }

    .close-button {
      margin: 0 -6px 0 0;
      width: 40px;
      height: 40px !important;
      min-height: 40px !important;

      svg {
        min-width: 28px;
        min-height: 28px;
      }
    }
  }
`;
