import { useEffect } from 'react';
import { createPopup } from '@typeform/embed';
import { useLocation } from 'react-router-dom';

export const INTEGRATE_FEEDBACK_DISPLAYED = 'integrate-feedback-displayed';
export const INTEGRATION_FINALIZED = 'integration-finalized';
export const INTEGRATE_FEEDBACK_FORM_ID = 'FDnBFheP';

export const useFeedbackForm = () => {
  const { toggle } = createPopup(INTEGRATE_FEEDBACK_FORM_ID);
  const location = useLocation();

  useEffect(() => {
    const feedbackDisplayed = localStorage.getItem(INTEGRATE_FEEDBACK_DISPLAYED);
    const integrationFinalized = localStorage.getItem(INTEGRATION_FINALIZED);
    const isOnIntegratePage = location.pathname.includes('/integrate');
    if (integrationFinalized && !feedbackDisplayed && !isOnIntegratePage) {
      setTimeout(() => toggle(), 3000); // 3s delay for the feedback form to appear
      localStorage.setItem(INTEGRATE_FEEDBACK_DISPLAYED, 'true');
    }
  }, [location]);
};
