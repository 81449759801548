import FocusLock from 'react-focus-lock';
import { useEffect, useState, ReactNode, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { useKeyListener } from 'hooks/use-key-listener';
import styled from 'styled-components';
import { breakpoints, gradientBorder, verticalBreakpoint } from 'styles/theme';
import { CloseButton } from 'components/shared/close-button/close-button';
import { ErrorMessage } from 'types';
import { fonts } from 'styles/fonts';
import { ErrorBanner } from 'components/layout/error-banner';
import { Banner } from 'components/layout/banner';

interface ModalContentProps {
  children?: ReactNode;
  onClose?: () => void;
  hideCloseButton?: true;
  fullScreen?: boolean;
  error?: ErrorMessage;
}

interface ModalProps extends ModalContentProps {
  open: boolean;
}

const ModalContent = (props: ModalContentProps) => {
  const { onClose, children, fullScreen, hideCloseButton, error } = props;

  const [shake, setShake] = useState(false);

  const triggerShake = () => {
    setShake(true);
    setTimeout(() => setShake(false), 500);
  };

  useKeyListener('Escape', hideCloseButton || !onClose ? triggerShake : onClose);

  const handleOutsideClickClose = (e: MouseEvent<HTMLDivElement>) => {
    const outsideModalBodyClick = (e.target as HTMLElement).classList[0] === 'modal-body';

    if (outsideModalBodyClick && hideCloseButton) {
      triggerShake();
    }
  };

  return (
    <FocusLock>
      <StyledModalWrapper
        className={clsx('modal-wrapper', { 'full-screen': fullScreen })}
        onClick={handleOutsideClickClose}
        role="dialog"
      >
        <div className={clsx('modal-body', { 'shake-animation': shake, 'has-banner': !!error })}>
          <div className="modal">
            {error && (
              <div className="modal-banner">
                <Banner type="error" fullBorder content={<ErrorBanner error={error} />} />
              </div>
            )}
            {onClose && (
              <CloseButton
                className={clsx('close-button', { 'close-button-hidden': hideCloseButton })}
                onClick={onClose}
              />
            )}
            <div className="modal-children-wrapper">{children}</div>
          </div>
        </div>
      </StyledModalWrapper>
    </FocusLock>
  );
};

export const Modal = (props: ModalProps) => {
  const { open } = props;

  // Solves "Uncaught Error: Target container is not a DOM element." error
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  });

  if (!open) return null;

  return domReady ? ReactDOM.createPortal(<ModalContent {...props} />, document.getElementById('modal')!) : null;
};

interface ModalHeaderProps {
  children?: ReactNode;
}

export const ModalHeader = ({ children }: ModalHeaderProps) => {
  if (!children) return null;

  return <h1 className="modal-header">{children}</h1>;
};

export const StyledModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-modal);
  box-sizing: border-box;
  outline: none;
  background-color: rgba(9, 13, 56, 0.9); // --color-dark-blue-800 with 90% opacity
  backdrop-filter: blur(16px);

  .modal-header {
    color: var(--font-color-white);
    text-align: center;
    ${fonts.heading[8]};
    margin: 0;

    @media (min-width: ${breakpoints.sm}px) {
      ${fonts.heading[6]};
    }
  }

  .modal-body {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;

    .modal {
      --modal-padding-y: 32px;
      --modal-padding-x: 32px;
      --modal-margin-y: 40px;

      position: relative;
      max-height: 100%;
      padding: var(--modal-padding-y) var(--modal-padding-x);
      margin: var(--modal-margin-y) auto;
      background: radial-gradient(50% 80% at 50% 0%, rgba(45, 55, 163, 0.8) 10%, rgba(14, 21, 98, 0.35) 140%),
        var(--color-base-dark);
      border-radius: 16px;
      color: var(--color-tertiary);
      box-sizing: border-box;

      .close-button {
        position: absolute;
        top: 24px;
        right: 20px;

        svg {
          width: 36px;
          height: 36px;
        }

        &.close-button-hidden {
          display: none;
        }
      }

      .modal-children-wrapper {
        position: relative;
        max-height: calc(
          100vh - var(--modal-padding-y) * 2 - var(--modal-margin-y) * 2
        ); // Need this calc for scroll to work.
        overflow: auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      @media (min-width: ${breakpoints.sm}px) {
        --modal-padding-y: 96px;
        --modal-padding-x: 80px;

        @media (min-height: ${verticalBreakpoint}px) {
          ${gradientBorder('blue-01-stroke')}
        }
      }

      @media (min-width: ${breakpoints.lg}px) {
        --modal-padding-y: 96px;
      }
    }

    &.has-banner {
      .modal {
        padding-top: 0;

        .modal-banner {
          padding-top: 1px !important;
          margin-bottom: var(--modal-padding-y);

          // stretch banner to the full modal width including paddings
          width: calc(100% + var(--modal-padding-x) * 2 - 2px);
          margin-left: calc(var(--modal-padding-x) * -1 + 1px);

          .banner-container {
            border-radius: 16px 16px 0 0;
          }
        }
      }
    }

    @media (max-width: ${breakpoints.sm - 1}px), (max-height: ${verticalBreakpoint}px) {
      padding: 0;

      .modal {
        margin: 0;
        padding: 104px 0 24px 0;
        height: 100%;
        width: 100%;
        border-radius: 0;
        max-width: unset;
      }

      & .modal > .modal-children-wrapper {
        padding: 0 24px 40px 24px;
        margin: 0 auto;
        height: 100%;
        max-height: 100%;
      }

      &.has-banner {
        .modal {
          .modal-banner {
            padding-top: 0px !important;
            padding: 16px 24px;

            .banner-container {
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  &.full-screen {
    .modal-body {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;

      .modal {
        padding: 82px 0 42px;
        height: 100%;
        width: 100%;

        .modal-children-wrapper {
          margin: 0;
          max-height: 100%;
        }
      }

      &.has-banner {
        .modal {
          .modal-banner {
            padding-top: 0px !important;

            .banner-container {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
`;
