import { css } from 'styled-components';
import { ButtonDefaultStyle } from '../sizes/default';

const Blue = css`
  background-color: var(--color-blue-400);
  color: var(--color-base-light);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    background-color: var(--color-blue-200);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    background-color: var(--color-blue-500);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    background-color: var(--color-dark-blue-400);
    color: var(--color-blue-200);

    svg {
      color: var(--color-blue-200);
    }
  }
`;

const White = css`
  background-color: var(--color-base-light);
  color: var(--color-blue-400);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-200);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-500);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    background-color: var(--color-gray-500);
    color: var(--color-gray-400);

    svg {
      color: var(--color-gray-400);
    }
  }
`;

const PrimaryDestructive = css`
  background-color: var(--color-action-error-700);
  color: var(--color-base-light);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    background-color: var(--color-action-error-600);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    background-color: var(--color-action-error-700);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    background-color: var(--color-action-error-900);
    color: var(--color-action-error-800);
  }
`;

export const PrimaryVariantStyle = css`
  ${ButtonDefaultStyle}

  &.blue {
    &:not(.destructive) {
      ${Blue}
    }

    &.destructive {
      ${PrimaryDestructive}
    }
  }

  &.white {
    &:not(.destructive) {
      ${White}
    }
    &.destructive {
      ${PrimaryDestructive}
    }
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }

  &.no-border {
    border: none !important;
  }
`;
