import { useNotificationBannerContext } from 'components/layout/notification-banner';
import { usePrevious } from 'hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { WarningBanner } from './warning-banner';

interface Props {
  type: 'feed' | 'chain';
}

export const DeprecatedBanner = ({ type }: Props) => {
  const { setBanner } = useNotificationBannerContext();
  const location = useLocation();

  const hideBanner = () => {
    setBanner({ content: null, type: 'deprecated' });
  };

  const initialPath = usePrevious(location.pathname);

  useEffect(() => {
    if (location.pathname !== initialPath && initialPath) {
      hideBanner();
    }
  }, [location.pathname, initialPath]);

  return (
    <WarningBanner
      title={type === 'feed' ? 'This feed has been deprecated.' : 'This chain has been deprecated.'}
      subtitle="New plans are not available for purchase."
    />
  );
};
