import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { CopyButton, Spinner } from 'components/shared';
import { abbrStr } from 'utils/utils';
import { VerificationStatus } from 'types';
import { BadgeCheckIcon, WarningCircleIcon } from 'components/shared/icons';
import { INTEGRATION_FINALIZED } from 'hooks';
import { fonts } from 'styles/fonts';

interface Props {
  proxyAddress?: string;
  verificationStatus: VerificationStatus;
}

export const VerifiedProxyAddress = ({ proxyAddress, verificationStatus }: Props) => {
  if (verificationStatus === 'verifying' || !proxyAddress) {
    return (
      <Container>
        <Spinner />
        <div className="verifying">Verifying...</div>
      </Container>
    );
  }

  return (
    <Container>
      {verificationStatus === 'verified' ? (
        <BadgeCheckIcon className="verification-icon" />
      ) : (
        <WarningCircleIcon className="verification-icon" />
      )}
      <div className="proxy-address">{abbrStr(proxyAddress, 4)}</div>
      <CopyButton copyString={proxyAddress} onClick={() => localStorage.setItem(INTEGRATION_FINALIZED, 'true')} />
    </Container>
  );
};

const Container = styled.div`
  background: var(--color-dark-blue-700);
  padding: 6px 12px;
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 4px;

  &:has(.verifying) {
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
    }

    .verifying {
      ${fonts.body[15]};
      color: var(--color-base-light);
    }
  }

  .verification-icon {
    width: 20px;
    height: 20px;
  }

  .proxy-address {
    ${fonts.body[5]};
  }

  .copy-btn {
    margin-left: -8px;
    padding-right: 0;

    svg {
      width: 16px;
      height: 16px;
      padding: 0;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    &:has(.verifying) {
      svg {
        width: 24px;
        height: 24px;
      }

      .verifying {
        ${fonts.body[12]};
      }
    }

    .proxy-address {
      ${fonts.subtitle[8]};
    }
  }

  .copy-btn {
    margin-left: -6px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;
