export const ArrowUpIcon = () => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.76371 5.9147L5.29349 0.696258C5.16554 0.546885 4.91916 0.546885 4.78984 0.696258L0.319617 5.9147C0.153549 6.10929 0.303282 6.39433 0.571441 6.39433H9.51189C9.78005 6.39433 9.92978 6.10929 9.76371 5.9147Z"
        fill="currentColor"
      />
    </svg>
  );
};
