import { lazy } from 'react';
import { StatusActiveIcon } from 'components/shared/icons';
import Skeleton from 'components/shared/skeleton';
import { MISSING_VALUE_DASH } from 'constants/constants';
import { formatBaseUnits } from 'utils/utils';
import styled from 'styled-components';
import { api3Chains } from '@api3/dapi-management';
import { breakpoints } from 'styles/theme';
import { dapisByName } from 'utils/dapis';
import { useDapiChainData } from 'hooks/chain-data/use-dapi-chain-data';
import { formatUpdateDate } from 'utils/format';
import { fonts } from 'styles/fonts';

const FeedSources = lazy(() => import('./feed-sources'));

interface Props {
  dapiName: string;
  chain: api3Chains.Chain;
}

export const OnChainValue = ({ dapiName, chain }: Props) => {
  const { isLoading, isError, value, timestamp, beacons } = useDapiChainData({
    dapiName,
    chainId: chain.id,
  });

  const prefix = dapisByName?.[dapiName]?.metadata?.prefix;

  return (
    <>
      <OnChainValueContainer>
        <div className="title-and-status-icon">
          <StatusActiveIcon />
          <span>Aggregated Onchain Value</span>
        </div>
        <div className="on-chain-value" data-testid="on-chain-value">
          {isLoading || isError ? (
            <Skeleton enableAnimation={!isError} />
          ) : (
            <span>{value ? `${prefix}${formatBaseUnits(value)}` : MISSING_VALUE_DASH}</span>
          )}
        </div>
        <div className="last-updated" data-testid="last-updated">
          {isLoading || isError ? (
            <Skeleton enableAnimation={!isError} />
          ) : timestamp ? (
            formatUpdateDate(timestamp)
          ) : (
            MISSING_VALUE_DASH
          )}
        </div>
      </OnChainValueContainer>

      <FeedSources dapiName={dapiName} beacons={beacons} prefix={prefix} loadingDataError={isError} />
    </>
  );
};

const OnChainValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: auto;
  min-width: 328px;
  padding: 24px 40px;
  border-radius: 10px 10px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid var(--color-dark-blue-50);
  background: radial-gradient(40% 360% at 50% 0%, rgba(45, 55, 163, 0.4) 0%, rgba(9, 13, 56, 0.3) 100%);
  z-index: var(--z-index-content);

  & .title-and-status-icon {
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    ${fonts.body[12]};
    padding-left: 16px;

    & svg {
      position: absolute;
      left: -12px;
      width: 22px;
      height: 22px;
    }
  }

  & .on-chain-value {
    padding: 0 12px;
    text-align: center;

    & span {
      ${fonts.heading[6]};
    }
  }

  & .last-updated {
    ${fonts.body[15]};
    color: var(--color-dark-blue-25);
    text-align: center;
  }

  @media (min-width: ${breakpoints.sm}px) {
    min-width: 393px;
    padding: 29px 48px;
    margin-top: 64px;
    gap: 3px;

    & .title-and-status-icon {
      & svg {
        width: 26px;
        height: 26px;
      }
    }

    & .on-chain-value {
      padding: 0 15px;
    }

    & .last-updated {
      font-size: 16px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    min-width: 386px;
    padding: 24px 60px;
    gap: 6px;

    & .title-and-status-icon {
      ${fonts.body[9]};
    }

    & .on-chain-value {
      & span {
        ${fonts.heading[3]};
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    min-width: 516px;
    padding: 32px 80px;
    gap: 4px;

    & .title-and-status-icon {
      & svg {
        left: -20px;
        width: 32px;
        height: 32px;
      }
    }
  }
`;
