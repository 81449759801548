import { ComponentPropsWithoutRef } from 'react';

export const CalendarIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14 7.31576H2M10.6667 1.98242V4.64909M5.33333 1.98242V4.64909M5.2 15.3158H10.8C11.9201 15.3158 12.4802 15.3158 12.908 15.0978C13.2843 14.906 13.5903 14.6001 13.782 14.2237C14 13.7959 14 13.2359 14 12.1158V6.51576C14 5.39565 14 4.8356 13.782 4.40777C13.5903 4.03145 13.2843 3.72549 12.908 3.53374C12.4802 3.31576 11.9201 3.31576 10.8 3.31576H5.2C4.0799 3.31576 3.51984 3.31576 3.09202 3.53374C2.71569 3.72549 2.40973 4.03145 2.21799 4.40777C2 4.8356 2 5.39565 2 6.51576V12.1158C2 13.2359 2 13.7959 2.21799 14.2237C2.40973 14.6001 2.71569 14.906 3.09202 15.0978C3.51984 15.3158 4.07989 15.3158 5.2 15.3158Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
