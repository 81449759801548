import { ComponentPropsWithoutRef } from 'react';

export const InfoIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="15.9998" cy="16.4993" r="12.5833" stroke="#6069CA" strokeWidth="1.5" />
      <path
        d="M14 14.5H16.0076L16.0076 22.5H18"
        stroke="#6069CA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="15.8001" cy="10.7" r="1.2" fill="#6069CA" />
    </svg>
  );
};
