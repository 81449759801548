import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { Button } from 'components/shared';
import { links } from 'constants/links';

interface Props {
  title: string;
  description: string;
}

export const HomepageHeader = (props: Props) => {
  const { title = '', description = '' } = props;

  return (
    <StyledPageHeaderWrapper>
      <div className="page-header-title">
        <h1>{title}</h1>
      </div>
      <div className="page-header-description">
        <h2>{description}</h2>
        <Button
          variant="link"
          color="blue"
          href={links.DOCS_OEV_REWARDS}
          size="small"
          sm={{ size: 'medium' }}
          md={{ size: 'large' }}
        >
          Read more about OEV Rewards
        </Button>
      </div>
    </StyledPageHeaderWrapper>
  );
};

const StyledPageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 24px 0 40px 0;

  .page-header-title {
    display: inline-block;
    margin: 0 auto;

    h1 {
      font-size: 32px;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: 0.5px;
      color: var(--color-base-light);
      margin: 0;
      padding: 0;
    }
  }

  .page-header-description {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 auto;

    h2 {
      font-family: 'Karla', sans-serif;
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.5px;
      color: var(--color-gray-200);
      margin: 0 8px;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    gap: 2px;
    margin-bottom: 90px;

    .page-header-title {
      h1 {
        font-size: 36px;
      }
    }

    .page-header-description {
      max-width: 560px;
      h2 {
        font-size: 18px;
        line-height: 150%;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    gap: 4px;
    margin: 90px 0 70px 0;

    .page-header-title {
      max-width: 100%;

      h1 {
        letter-spacing: 1px;
        max-width: 100%;
        font-size: 56px;
        line-height: 110%;
      }
    }

    .page-header-description {
      max-width: 100%;
      gap: 12px;

      h2 {
        max-width: 100%;
        font-size: 22px;
        line-height: 130%;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 90px;
  }
`;
