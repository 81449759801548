import { MouseEventHandler, useRef, useState, ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import * as Tooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import { CopyIcon } from 'components/shared/icons';
import { go } from '@api3/promise-utils';
import { fonts } from 'styles/fonts';
import { Button } from '../button/button';

interface Props extends ComponentPropsWithoutRef<'button'> {
  copyString: string;
  hideTooltip?: boolean;
}

const initialState = { open: false, content: 'Copy' };

export const CopyButton = (props: Props) => {
  const { copyString, className, hideTooltip = false, onClick, color: _color, ...rest } = props;

  const textRef = useRef<HTMLTextAreaElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [state, setState] = useState(initialState);

  const timeoutIdRef = useRef<number>();

  const handleCopy: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (navigator.clipboard) {
      const result = await go(() => navigator?.clipboard?.writeText(copyString));
      setState({ open: true, content: result.success ? 'Copied!' : 'Failed to copy' });
    } else if (textRef.current) {
      textRef.current.select();
      document.execCommand('copy');
      buttonRef.current!.focus();
      setState({ open: true, content: 'Copied!' });
    }

    onClick?.(e);

    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = window.setTimeout(() => {
      setState(initialState);
    }, 3000);
  };

  return (
    <>
      <Tooltip.Root
        open={state.open}
        onOpenChange={(open) => {
          if (open) {
            setState({ ...state, open });
            return;
          }
          setState(initialState);
          clearTimeout(timeoutIdRef.current);
        }}
      >
        <Tooltip.Trigger asChild>
          <StyledTextButton
            ref={buttonRef}
            variant="link"
            color="blue"
            size="medium"
            onClick={handleCopy}
            iconStart={<CopyIcon />}
            className={clsx('copy-btn', className)}
            {...rest}
          >
            {hideTooltip ? state.content : ''}
            <span className="sr-only">Copy button</span>
          </StyledTextButton>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <StyledTooltipContent
            hidden={hideTooltip}
            sideOffset={5}
            onPointerDownOutside={(event) => {
              // We don't want the tooltip to close when clicking the copy button
              if (buttonRef.current!.contains(event.target as Node)) {
                event.preventDefault();
              }
            }}
          >
            {state.content}
            <TooltipArrow />
          </StyledTooltipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
      <textarea ref={textRef} tabIndex={-1} aria-hidden className="sr-only" value={copyString} readOnly />
    </>
  );
};

const StyledTooltipContent = styled(Tooltip.Content)`
  background: var(--color-gray-800);
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 12px;
  z-index: var(--z-index-tooltip);

  &[data-state='delayed-open'] {
    animation: var(--animation-fade-in);
  }
`;

const TooltipArrow = styled(Tooltip.Arrow)`
  fill: var(--color-gray-800);
`;

const StyledTextButton = styled(Button)`
  margin: auto 0;
  ${fonts.link[2]};
  gap: 4px;
  color: var(--color-gray-400) !important;

  svg {
    color: var(--color-dark-blue-50);
    padding: 2px;
  }

  &:hover {
    color: var(--color-base-light) !important;
  }
`;
