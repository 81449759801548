/* eslint-disable functional/immutable-data */
import { css } from 'styled-components';

export const isStaging = ['staging.api3-market.pages.dev', 'main.api3-market.pages.dev'].includes(
  window.location.hostname
);
export const isProduction = process.env.VITE_APP_CF_ENV === 'Production';

export type BreakpointKeys = 'xs' | 'sm' | 'md' | 'lg';

export const breakpoints: Record<BreakpointKeys, number> = {
  xs: 360,
  sm: 768,
  md: 1200,
  lg: 1600,
};

export const threeCardsBreakpoint = 560;
export const verticalBreakpoint = 650;

export const NOT_IMPLEMENTED = css`
  &:before {
    content: ${isProduction ? '""' : '"Not implemented"'};
    position: absolute;
    bottom: -4px;
    background-color: white;
    color: red;
    font-size: 12px;
  }
`;

type Gradient =
  | 'general-light'
  | 'general-soft'
  | 'general-soft-disable'
  | 'general-soft-light'
  | 'general-medium'
  | 'general-medium-light'
  | 'general-blue'
  | 'blue-01-stroke';

/**
 * Creates a gradient border using the mask-composite property
 * @param {Gradient} gradient Applies a gradient variable from index.css
 * @param {number} inset Expands or contracts gradient to cover the original border. Default is 0
 */
export const gradientBorder = (gradient: Gradient, inset: number = 0) => css`
  @supports (mask-composite: exclude) {
    &:before {
      content: '';
      position: absolute;
      inset: ${inset}px;
      padding: 1px;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
      background: ${`var(--gradient-${gradient})`};
      transition: background var(--default-transition-duration);
      border-radius: inherit;
    }
  }
`;
