import * as Sentry from '@sentry/react';
import { LIMITED_CACHING } from 'constants/caching';
import { ethers } from 'ethers';
import { useQuery } from '@tanstack/react-query';
import { decodeDapiName, getActiveDataFeedCount, getAirseekerRegistry, verifyMulticallResponse } from 'utils/contracts';
import { AirseekerRegistry } from '@api3/contracts';
import { useProviderStore } from 'stores';

export const BATCH_SIZE = 10;

const fetchBatch = async (airseekerRegistry: AirseekerRegistry, offset: number) => {
  const calldatas: string[] = [];
  calldatas.push(
    ...Array.from({ length: BATCH_SIZE }, (_, index) =>
      airseekerRegistry.interface.encodeFunctionData('activeDataFeed', [offset + index])
    )
  );
  const returndatas = verifyMulticallResponse(await airseekerRegistry.tryMulticall.staticCall(calldatas, {}));
  return returndatas.map((data: any) => airseekerRegistry.interface.decodeFunctionResult('activeDataFeed', data));
};

const fetchActiveDapis = async (chainId?: string, provider?: ethers.Provider) => {
  if (!chainId || !provider) {
    throw new Error('Missing chainId or provider');
  }

  const airseekerRegistry = await getAirseekerRegistry(chainId, provider);
  const activeFeedsCount = await getActiveDataFeedCount(airseekerRegistry);
  if (activeFeedsCount === 0) {
    return [];
  }

  const batchesCount = Math.ceil(activeFeedsCount / BATCH_SIZE);
  const batches = Array.from({ length: batchesCount }, (_, index) => index);
  const results = await Promise.all(batches.map((offset) => fetchBatch(airseekerRegistry, offset * BATCH_SIZE)));

  // right now we are interested only in dapiName
  return results
    .flatMap((result) => result.map((data: any) => decodeDapiName(data.dapiName)))
    .filter((name: string) => name !== '')
    .sort();
};

export const useActiveDapis = (chainId?: string) => {
  const { providerState, providers } = useProviderStore();
  const provider = chainId ? providers[chainId] : undefined;

  const { data, error, isError, isSuccess, isLoading } = useQuery({
    queryKey: ['activeDapis', chainId],
    queryFn: () => fetchActiveDapis(chainId!, provider),
    ...LIMITED_CACHING,
    enabled: !!provider && !!chainId,
    retry: false,
    retryOnMount: false,
  });

  if (isError) {
    Sentry.captureException(error);
  }

  return {
    activeFeeds: data?.sort((a, b) => a.localeCompare(b)),
    isLoading,
    isError: isError || (!!chainId && providerState[chainId] === 'error'),
    isSuccess,
  };
};
