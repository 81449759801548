import { useRef, useState } from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';
import { Button } from 'components/shared';
import { CalendarIcon } from 'components/shared/icons';
import { calendars, CalendarType, getReminderDate } from 'utils/calendar-reminder';
import { getDapiPath } from 'utils/utils';
import { breakpoints, gradientBorder } from 'styles/theme';
import { CloseButton } from 'components/shared/close-button/close-button';
import { fonts } from 'styles/fonts';

interface Props {
  chainAlias: string;
  chainName: string;
  dapiName: string;
  date: number;
  deviationThreshold: number;
  size?: 'small' | 'medium';
}

export const CalendarReminder = (props: Props) => {
  const { chainAlias, chainName, dapiName, date, deviationThreshold, size = 'small' } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const { reminderDate, expiresSoon } = getReminderDate(date);

  const closePopover = () => setOpen(false);

  const createEvent = (calendarType: CalendarType) => {
    const calendarFn = calendars[calendarType].fn;

    const calendarLink = calendarFn({
      title: `API3: ${dapiName} on ${chainName} expiring in 1 week`,
      description: `${dapiName} on ${chainName} with ${deviationThreshold}% deviation can be renewed at https://market.api3.org${getDapiPath(chainAlias, dapiName)}`,
      start: reminderDate,
      allDay: true,
    });

    window.open(calendarLink);
    closePopover();
  };

  return (
    <Container ref={containerRef}>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <Button
            className="expiring-reminder-button"
            size={size}
            variant="link"
            color="blue"
            iconStart={<CalendarIcon className="expiring-icon" />}
            tooltip={
              expiresSoon
                ? 'This data feed is expiring soon. We recommend purchasing a plan now to avoid interruptions'
                : undefined
            }
            disabled={expiresSoon}
          >
            Set Reminder
          </Button>
        </Popover.Trigger>
        <Popover.Portal container={containerRef.current}>
          <StyledPopoverContent
            side="bottom"
            sideOffset={6}
            onOpenAutoFocus={(e) => {
              // Do this to prevent first button from being focused on open
              e.preventDefault();
            }}
          >
            <div className="popover-content">
              <CloseButton className="calendar-close-button" onClick={closePopover} />

              <div className="popover-content-inner">
                <h3>Set reminder</h3>

                <ul>
                  {Object.entries(calendars).map(([key, { name }]) => (
                    <li key={key}>
                      <Button
                        className="calendar-button"
                        variant="link"
                        color="blue"
                        size="large"
                        onClick={() => createEvent(key as CalendarType)}
                      >
                        {name}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </StyledPopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  justify-content: center;

  .expiring-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }

  .medium .expiring-icon {
    width: 18px;
    height: 18px;
    min-width: 18px;
  }

  .expiring-reminder-button {
    padding-left: 0;
    gap: 6px;
    white-space: nowrap;
  }

  @media (max-width: ${breakpoints.sm}px) {
    [data-radix-popper-content-wrapper] {
      transform: none !important;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    .medium .expiring-icon {
      width: 22px;
      height: 22px;
      min-width: 22px;
    }
  }
`;

const StyledPopoverContent = styled(Popover.Content)`
  z-index: var(--z-index-dropdown);
  background-color: var(--color-dark-blue-900) !important;
  background: radial-gradient(100% 100% at 50% 0%, rgba(45, 55, 163, 0.7) 10%, rgba(9, 13, 56, 0) 100%);
  width: 100vw;
  height: 100vh;

  &[data-state='open'] {
    animation: var(--animation-slide-down);
  }

  &:not([data-state='open']) {
    animation: var(--animation-slide-up);
  }

  .popover-content {
    display: flex;
    flex-direction: column;
    padding: 24px;
    height: 100vh;
    overflow-y: auto;

    .calendar-close-button {
      align-self: flex-end;
    }

    .popover-content-inner {
      display: flex;
      flex-direction: column;
      padding: 32px 40px;
      width: 100%;

      > h3 {
        ${fonts.heading[9]};
        color: var(--color-base-light);
        margin-bottom: 56px;
      }

      > ul {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        gap: 16px;
        padding: 0;

        li {
          width: 100%;
          list-style-type: none;

          .calendar-button {
            display: flex;
            align-items: center;
            width: 100%;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    height: initial;
    width: initial;
    border-radius: 16px;
    background: radial-gradient(120% 140% at 50% 0%, rgba(45, 55, 163, 0.7) 10%, rgba(9, 13, 56, 0) 100%);
    ${gradientBorder('blue-01-stroke')}

    .popover-content {
      padding: 0;
      height: unset;

      > .calendar-close-button {
        display: none;
      }

      .popover-content-inner {
        > h3 {
          display: none;
        }

        > ul li {
          .calendar-button {
            justify-content: flex-start;
          }
        }
      }
    }
  }
`;
