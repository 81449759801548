import { CaretDown } from 'components/shared/icons';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles/theme';
import { fonts } from 'styles/fonts';

interface Props {
  title: string;
  subtitle: string;
  isAlwaysExpanded?: boolean;
  children: ReactNode;
}

export const IntegrateFlowBox = ({ children, isAlwaysExpanded = false, subtitle, title }: Props) => {
  const disabled = isAlwaysExpanded;

  return (
    <Container open={disabled} aria-disabled={disabled} className={clsx({ disabled })}>
      <summary>
        <div className="summary-content">
          <h1>{title}</h1>
          <span>{subtitle}</span>
        </div>
        {!disabled && <CaretDown className="arrow" width={32} height={32} />}
      </summary>

      <div className="inner-content">{children}</div>
    </Container>
  );
};

const Container = styled.details`
  border-radius: 16px;
  border: 1px solid var(--color-dark-blue-400);
  background: radial-gradient(45% 360%, rgba(45, 55, 163, 0.7) 0%, rgba(9, 13, 56, 0.3) 100%);
  min-width: 250px;

  &[disabled] summary,
  &.disabled summary {
    pointer-events: none;
  }

  summary {
    display: flex;
    align-items: flex-start;
    padding: 32px 24px;
    border-radius: 8px;
    list-style: none;
    cursor: pointer;
    justify-content: space-between;
    &::-webkit-details-marker,
    &::marker {
      display: none;
    }

    .summary-content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h1 {
        ${fonts.body[8]};
        color: var(--color-gray-50);
        margin: 0;
      }

      span {
        ${fonts.body[15]};
        color: var(--color-dark-blue-25);
      }
    }

    svg.arrow {
      transition: var(--animation-flip-chevron);
    }
  }

  .inner-content {
    > * {
      display: flex;
      margin: 0 24px 32px 24px;
    }
  }

  &[open] {
    summary {
      padding-bottom: 24px;

      svg.arrow {
        transform: rotate(180deg);
      }
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    summary {
      padding: 56px 64px;

      .summary-content {
        h1 {
          ${fonts.body[5]};
          color: var(--color-gray-50);
          margin: 0;
        }

        h3 {
          font-size: 18px;
          line-height: 150%;
        }

        span {
          ${fonts.body[9]};
        }
      }
    }

    .inner-content {
      > * {
        margin: 0 64px 56px 64px;
      }
    }

    &[open] {
      summary {
        padding-bottom: 24px;
      }
    }
  }
`;
