import { create } from 'zustand';
import { disablePageScrolling, enablePageScrolling } from 'utils/utils';
import { toast } from 'react-toastify';

export type MenuState = 'closed' | 'opened-support' | 'opened-wallet';

type ChainSwitchStatus = 'idle' | 'changed-from-wallet' | 'changed-on-navigation' | 'pending' | 'rejected';

interface Props {
  chainSwitchStatus: ChainSwitchStatus;
  setChainSwitchStatus: (status: ChainSwitchStatus) => void;
  isOnDifferentChain: boolean;
  setIsOnDifferentChain: (isOnDifferentChain: boolean) => void;
  mobileMenuState: MenuState;
  closeSidebar: () => void;
  openSidebar: (type: MenuState) => void;
}

export const useGeneralStore = create<Props>()((set) => ({
  chainSwitchStatus: 'idle',
  setChainSwitchStatus: (status) => {
    set(() => ({
      chainSwitchStatus: status,
    }));
  },
  isOnDifferentChain: false,
  setIsOnDifferentChain: (isOnDifferentChain) => {
    set(() => ({
      isOnDifferentChain,
    }));
  },
  mobileMenuState: 'closed',
  closeSidebar: () => {
    enablePageScrolling();
    set(() => ({
      mobileMenuState: 'closed',
    }));
  },
  openSidebar: (type: MenuState) => {
    disablePageScrolling();
    toast.dismiss();
    set(() => ({
      mobileMenuState: type,
    }));
  },
}));
