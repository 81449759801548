import { ComponentProps } from 'react';

export const ChevronDown = (props: ComponentProps<'svg'>) => {
  return (
    <svg
      aria-hidden
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.42139 3.24707L5.08805 6.75584L1.75472 3.24707"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
