import { ReactNode } from 'react';
import Skeleton from 'components/shared/skeleton';
import { StyledRow } from 'components/shared/table/styles';

export interface IColumn {
  label: string;
  sortable?: boolean;
  dataKey: string;
  render?: (dataKeyValue: any, row: any) => ReactNode;
  cellClassName?: string;
  columnClassName?: string;
}

interface IProps {
  columnsToRender: any[];
  handleRowClick: any;
  loading: boolean;
  onRowClick: any;
  row: any;
}

export const TableRow = ({ columnsToRender, handleRowClick, loading, onRowClick, row }: IProps) => {
  return (
    <StyledRow
      tabIndex={!loading && onRowClick ? 0 : undefined}
      onClick={() => handleRowClick(row)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleRowClick(row);
        }
      }}
      $loading={loading}
    >
      {columnsToRender.map((column, index) => (
        <td key={`${column.dataKey}-${index}`} className={column.cellClassName}>
          {loading ? (
            <Skeleton />
          ) : column.render && typeof column.render === 'function' ? (
            column.render(row[column.dataKey], row)
          ) : (
            row[column.dataKey]
          )}
        </td>
      ))}
    </StyledRow>
  );
};
