import { useEffect, useRef } from 'react';

export const useKeyListener = (key: string, callback: () => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key#example
      if (e.key === key || (key === 'Escape' && e.key === 'Esc')) {
        callbackRef.current();
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, []);
};
