import { useURLWithShowHidden } from 'hooks/use-url-with-show-hidden';
import { ComponentPropsWithRef, forwardRef, Ref } from 'react';
import { Link } from 'react-router-dom';

interface Props extends ComponentPropsWithRef<'a'> {
  href: string;
}

export const SmartLink = forwardRef((props: Props, ref: Ref<HTMLAnchorElement>) => {
  const { href, children, ...rest } = props;
  const { getURL } = useURLWithShowHidden();

  return isExternal(href) ? (
    <a href={href} target="_blank" rel="noopener noreferrer" {...rest} ref={ref}>
      {children}
    </a>
  ) : isMailTo(href) ? (
    <a href={href} {...rest} ref={ref}>
      {children}
    </a>
  ) : (
    <Link to={getURL(href)} {...rest} ref={ref}>
      {children}
    </Link>
  );
});

export const isExternal = (link: string) => {
  const urlRegex = /^https?:\/\//i;
  return urlRegex.test(link);
};

export const isMailTo = (link: string) => {
  const mailToRegex = /^mailto:/i;
  return mailToRegex.test(link);
};
