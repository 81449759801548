import { css } from 'styled-components';

export const fonts = {
  body: {
    3: css`
      font-family: Karla;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
    `,
    4: css`
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    `,
    5: css`
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
    `,
    6: css`
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
    `,
    7: css`
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
    `,
    8: css`
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    `,
    9: css`
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    `,
    10: css`
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    `,
    11: css`
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
    `,
    12: css`
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    `,
    13: css`
      font-family: Karla;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 16.8px */
    `,
    14: css`
      font-family: Karla;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 16.8px */
    `,
    15: css`
      font-family: Karla;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
    `,
    18: css`
      font-family: Karla;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 14px */
    `,
  },
  button: {
    1: css`
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 18px */
      letter-spacing: 0.3px;
    `,
    2: css`
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 16px */
      letter-spacing: 0.3px;
    `,
    3: css`
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 14px */
      letter-spacing: 0.2px;
    `,
  },
  codeSnippet: {
    1: css`
      font-family: 'Source Code Pro';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    `,
  },
  description: {
    3: css`
      font-family: Karla;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 33px */
    `,
    5: css`
      font-family: Karla;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
    `,
    7: css`
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%; /* 27px */
    `,
    9: css`
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 23.4px */
      letter-spacing: -0.5px;
    `,
  },
  heading: {
    2: css`
      font-family: Poppins;
      font-size: 56px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 61.6px */
      letter-spacing: 1px;
    `,
    3: css`
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 52.8px */
      letter-spacing: 1px;
    `,
    5: css`
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: 0.5px;
    `,
    6: css`
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 41.6px */
      letter-spacing: 0.5px;
    `,
    7: css`
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 28.6px */
      letter-spacing: 0.5px;
    `,
    8: css`
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 26px */
      letter-spacing: 0.5px;
    `,
    9: css`
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 23.4px */
      letter-spacing: 0.5px;
    `,
    10: css`
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 20.8px */
      letter-spacing: 0.16px;
    `,
  },
  link: {
    2: css`
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    `,
    3: css`
      font-family: Karla;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
    `,
  },
  overline: {
    1: css`
      font-family: Karla;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 20.4px */
    `,
    2: css`
      font-family: Karla;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 17px */
    `,
  },
  subtitle: {
    3: css`
      font-family: Karla;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 41.6px */
      letter-spacing: -0.64px;
    `,
    5: css`
      font-family: Karla;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 28.6px */
      letter-spacing: -0.44px;
    `,
    8: css`
      font-family: Karla;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      letter-spacing: -0.4px;
    `,
    9: css`
      font-family: Karla;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
      letter-spacing: -0.4px;
    `,
    11: css`
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 25.2px */
      letter-spacing: -0.36px;
    `,
    12: css`
      font-family: Karla;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 25.2px */
      letter-spacing: -0.36px;
    `,
  },
};
