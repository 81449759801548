import clsx from 'clsx';
import { FormEvent, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  .checkbox-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 9px 0 4px;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 12px;
    line-height: 160%;
  }

  label .checkbox-label {
    display: inline-flex;
    align-items: center;
  }

  label input {
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid var(--color-neutral-gray6);
    border-radius: 1px;
    margin: 0;
    cursor: pointer;
  }

  label input:checked {
    background-color: white;
    border-color: white;
  }

  label input:checked::before {
    content: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.76385 3.38311H1.76401C1.83035 3.38319 1.89581 3.3983 1.95547 3.4273C2.01512 3.45629 2.06743 3.49842 2.10848 3.55052L2.10843 3.55046L2.00992 3.6282L2.1085 3.55055L1.76385 3.38311ZM1.76385 3.38311H1.07859C0.908235 3.38311 0.813112 3.57936 0.918285 3.71275L0.918327 3.71281L3.60351 7.11463L3.60355 7.11468C3.77947 7.33733 4.11679 7.33671 4.2933 7.11506L4.29364 7.11462L9.08098 1.0481C9.08114 1.0479 9.0813 1.0477 9.08146 1.0475C9.18858 0.913294 9.08949 0.718509 8.92141 0.718509H8.23615C8.10199 0.718509 7.97424 0.779977 7.89135 0.886139C7.89127 0.886249 7.89118 0.886358 7.89109 0.886468L3.94809 5.88144L1.76385 3.38311Z' fill='%23121313' stroke='%23121313' stroke-width='0.25097'/%3E%3C/svg%3E");
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-33%, -58%);
    color: black;
    width: 15px;
    height: 15px;
  }

  &.light {
    label input {
      border-radius: 1px;
      background-color: transparent;
      border: 1px solid var(--color-neutral-gray7);
    }

    label input:checked {
      background-color: var(--color-neutral-gray8);
      border-color: var(--color-neutral-gray8);
    }

    label input:checked::before {
      content: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.76385 3.38311H1.76401C1.83035 3.38319 1.89581 3.3983 1.95547 3.4273C2.01512 3.45629 2.06743 3.49842 2.10848 3.55052L2.10843 3.55046L2.00992 3.6282L2.1085 3.55055L1.76385 3.38311ZM1.76385 3.38311H1.07859C0.908235 3.38311 0.813112 3.57936 0.918285 3.71275L0.918327 3.71281L3.60351 7.11463L3.60355 7.11468C3.77947 7.33733 4.11679 7.33671 4.2933 7.11506L4.29364 7.11462L9.08098 1.0481C9.08114 1.0479 9.0813 1.0477 9.08146 1.0475C9.18858 0.913294 9.08949 0.718509 8.92141 0.718509H8.23615C8.10199 0.718509 7.97424 0.779977 7.89135 0.886139C7.89127 0.886249 7.89118 0.886358 7.89109 0.886468L3.94809 5.88144L1.76385 3.38311Z' fill='white' stroke='white' stroke-width='0.25097'/%3E%3C/svg%3E");
    }
  }
`;

interface IProps {
  id: string;
  bgColor?: 'dark' | 'light';
  checked: boolean;
  label: string | ReactNode;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  value?: string;
  inputProps?: InputHTMLAttributes<any>;
  required?: boolean;
  wrapperClassName?: string;
}

export const CustomCheckbox = ({
  id,
  bgColor = 'dark',
  value = '',
  label = '',
  checked,
  onChange,
  required,
  wrapperClassName = '',
  inputProps = {},
}: IProps) => {
  return (
    <StyledWrapper className={clsx({ [wrapperClassName]: true, [bgColor]: true })}>
      <label htmlFor={id}>
        <span className="checkbox-input-wrapper">
          <input
            id={id}
            type="checkbox"
            value={value}
            onChange={onChange}
            checked={checked}
            required={required}
            {...inputProps}
          />
        </span>
        <span className="checkbox-label">{label}</span>
      </label>
    </StyledWrapper>
  );
};
