import { ethers } from 'ethers';
import { UpdateParameters } from 'types';
import { AirseekerRegistry, Api3ReaderProxyV1__factory as Api3ReaderProxyV1Factory } from '@api3/contracts';
import * as mockContracts from '../__test_utils__/mock-contracts';
import * as prodContracts from './contracts-prod';

const contracts = process.env.VITE_APP_MOCK_ENV === 'true' ? mockContracts : prodContracts;

export const { getAirseekerRegistry, getApi3Market, computeProxyAddress } = contracts;

export const HUNDRED_PERCENT = 1e8;
export const PRICE_INCREASE_IN_HOURS = 24;
export const MIN_SIGNIFICANT_DISCOUNT_PERCENT = 0.01; // 0.01%

export function deriveBeaconId(airnodeAddress: string, templateId: string) {
  return ethers.solidityPackedKeccak256(['address', 'bytes32'], [airnodeAddress, templateId]);
}

export function deriveBeaconSetId(beaconIds: string[]) {
  return ethers.keccak256(ethers.AbiCoder.defaultAbiCoder().encode(['bytes32[]'], [beaconIds]));
}

export const verifyMulticallResponse = (
  response: [boolean[], string[]] & { successes: boolean[]; returndata: string[] }
) => {
  const { successes, returndata } = response;

  if (!successes.every(Boolean)) throw new Error('One of the multicalls failed');
  return returndata;
};

export const getActiveDataFeedCount = async (airseekerRegistry: AirseekerRegistry) => {
  const result = await airseekerRegistry.activeDataFeedCount();
  return Number(result);
};

export const getApi3ReaderProxy = async (proxyAddress: string, providerOrSigner: ethers.Provider | ethers.Signer) => {
  return Api3ReaderProxyV1Factory.connect(proxyAddress, providerOrSigner);
};

export const decodeUpdateParameters = (updateParameters?: string): UpdateParameters => {
  if (!updateParameters) return {} as UpdateParameters;
  const decoded = ethers.AbiCoder.defaultAbiCoder().decode(['uint256', 'uint256', 'uint256'], updateParameters);
  return {
    heartbeatInterval: Number(decoded[2]),
    deviationReference: Number(decoded[1]),
    deviationThreshold: (Number(decoded[0]) / HUNDRED_PERCENT) * 100,
  };
};

export const encodeUpdateParameters = (updateParameters: UpdateParameters) => {
  return ethers.AbiCoder.defaultAbiCoder().encode(
    ['uint256', 'uint256', 'uint256'],
    [
      (updateParameters.deviationThreshold * HUNDRED_PERCENT) / 100,
      updateParameters.deviationReference,
      updateParameters.heartbeatInterval,
    ]
  );
};

export const encodeBeaconData = (airnodes: string[], templateIds: string[]) => {
  return airnodes.length === 1
    ? ethers.AbiCoder.defaultAbiCoder().encode(['address', 'bytes32'], [airnodes[0], templateIds[0]])
    : ethers.AbiCoder.defaultAbiCoder().encode(['address[]', 'bytes32[]'], [airnodes, templateIds]);
};

export const encodeDapiName = (dapiName: string) => ethers.encodeBytes32String(dapiName);
export const decodeDapiName = (dapiName: string) => ethers.decodeBytes32String(dapiName);
