import styled from 'styled-components';
import { formatBaseUnits } from 'utils/utils';
import { useDisconnect, useConnectedAccount, useGetUserBalance } from 'hooks/chain-data';
import Skeleton from 'components/shared/skeleton/skeleton';
import { DisconnectIcon } from 'components/shared/icons';
import { allChainsById } from 'utils/dapis';

interface Props {
  onDisconnect: () => void;
}

export const WalletDetails = ({ onDisconnect }: Props) => {
  const { connectedChainId, isConnected } = useConnectedAccount();
  const disconnect = useDisconnect();
  const { userBalance, isLoading, isError } = useGetUserBalance();
  const parsedUserBalance = formatBaseUnits(userBalance);

  const handleDisconnect = () => {
    disconnect();
    onDisconnect();
  };

  if (isLoading) {
    return (
      <Container>
        <div className="content-token-balance">
          <Skeleton enableAnimation={!isError} width={200} height={20} />
        </div>

        <DisconnectButton onDisconnect={handleDisconnect} />
      </Container>
    );
  }

  if (!isConnected || !connectedChainId) {
    return (
      <Container>
        <DisconnectButton onDisconnect={handleDisconnect} />
      </Container>
    );
  }

  return (
    <Container>
      <div className="content-token-balance">
        <span className="balance">{parsedUserBalance}</span>
        <span className="native-token">{allChainsById[connectedChainId]?.symbol || ''}</span>
      </div>

      <DisconnectButton onDisconnect={handleDisconnect} />
    </Container>
  );
};

const DisconnectButton = (props: { onDisconnect?: () => void }) => (
  <button type="button" className="disconnect-button" onClick={props.onDisconnect}>
    <DisconnectIcon width={24} height={24} />
    Disconnect
  </button>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 264px;
  padding: 24px 40px;

  .disconnect-button {
    display: flex;
    align-items: center;
    gap: 16px;
    background: transparent;
    border: none;
    padding: 16px 0px;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
    color: var(--color-base-light);
    text-decoration: none;

    &:hover {
      color: var(--color-gray-300);
    }

    > svg {
      color: var(--color-gray-300);
    }
  }

  .content-token-balance {
    display: flex;
    align-items: baseline;
    word-break: break-word;
    padding: 16px 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }

  .balance {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: var(--color-base-light);
    word-break: break-word;
  }

  .native-token {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: var(--color-dark-blue-25);
    padding-left: 8px;
    white-space: nowrap;
  }
`;
