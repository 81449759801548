import styled, { css } from 'styled-components';
import { breakpoints } from 'styles/theme';

export const StyledWrapper = styled.div`
  padding: 0;
  margin: 32px 0;
  --table-space-top-offset: 40px;

  .divider-bottom {
    display: none;
  }

  @media (max-width: ${breakpoints.lg}px) {
    .divider-bottom {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.sm}px) {
    margin: 64px 0;
  }

  @media (min-width: ${breakpoints.lg}px) {
    margin: calc(var(--table-space-top-offset) + 64px) 0;
  }

  .table-borders {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    border: none;
    padding: 0;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: -40px;
  background-color: var(--color-base-blue-ui);

  thead tr {
    &:first-child {
      border-bottom: none;
    }
  }

  .vertical-center {
    display: flex;
    align-items: center;
  }

  td {
    font-weight: 400;
  }

  th.sortable > span {
    cursor: pointer;
  }

  th,
  td {
    padding-left: 20px;
    padding-right: 24px;
    text-align: left;
  }

  tr {
    height: 82px;
  }

  & span.column-actions {
    position: relative;
    margin-left: 10px;

    .sort-btn {
      padding: 0;
      border: none;
      background-color: transparent;
      color: var(--color-gray-600);
      cursor: pointer;
    }

    .sort-btn.active {
      color: var(--color-base-light);
    }

    button.sort-desc {
      position: absolute;
      bottom: -7px;
      left: 0px;
      transform: rotate(180deg);
    }

    button.sort-asc {
      position: absolute;
      top: -5px;
      left: 0;
    }

    @media (max-width: ${breakpoints.xs}px) {
      button.sort-asc {
        top: -7px;
      }
    }
  }

  .link {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .transaction-column {
    color: inherit;
    margin: inherit;
    padding: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  .dapi-column {
    white-space: nowrap;
    min-width: unset;
  }

  @media (max-width: ${breakpoints.sm}px) {
    .dapi-column {
      min-width: unset;
      white-space: normal;
    }
  }
`;

export const StyledEmptyTableRow = styled.tr`
  text-transform: none !important;
  border-top: none !important;

  & td {
    height: 167px;
    text-align: center;
    font-weight: 300;

    .error-msg {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 8px;
    }

    .error-desc {
      font-size: 12px;
      line-height: 19px;
      color: var(--color-gray-500);
    }

    .error-link {
      margin-top: 8px;
    }
  }
`;

export const StyledRow = styled.tr<{ $loading: boolean }>`
  ${({ $loading }) =>
    !$loading &&
    css`
      &:hover {
        background-color: var(--color-dark-blue-700);
        cursor: pointer;
      }
    `}

  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
    `}
  
  
  img {
    color: transparent;
  }
`;
