import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { ErrorMessage } from 'types';
import sortBy from 'lodash/sortBy';
import { ErrorBanner } from './error-banner';
import { Banner, BannerProps } from './banner';

// Show only one banner at a time: https://github.com/api3dao/data-feeds/issues/1018
// RequestGrantBanner has its own separate component. Lowest prio because it has no effect on the functionality of the page
const bannerPrios = {
  error: 1,
  maintenance: 2,
  deprecated: 3,
  expired: 4,
  neutral: 5,
  requestGrant: 99,
};

export const initialBanner: BannerProps = {
  content: null,
  type: 'neutral',
};

const NotificationBannerContext = createContext<{
  banner: BannerProps;
  setBanner: (banner: BannerProps) => void;
  setErrorBanner: (error: ErrorMessage) => void;
}>({
  banner: initialBanner,
  setBanner: () => null,
  setErrorBanner: () => null,
});

export const NotificationBannerContextProvider = (props: { children: ReactNode }) => {
  const [banners, setBanners] = useState<BannerProps[]>([]);

  const setErrorBanner = ({ message, secondaryMessage }: ErrorMessage) => {
    const newBanner = {
      content: (
        <ErrorBanner
          error={{ message, secondaryMessage }}
          closeBanner={() => setBanners((p) => p.filter((b) => b.type !== 'error'))}
        />
      ),
      type: 'error' as const,
    };
    updateBanners(newBanner);
  };

  const updateBanners = (newBanner: BannerProps) => {
    setBanners((prev) => {
      const filteredBanners = prev.filter((b) => b.type !== newBanner.type);
      const newBanners = newBanner.content ? [...filteredBanners, newBanner] : filteredBanners;

      return sortBy(newBanners, (b) => bannerPrios[b.type]);
    });
  };

  const ctx = useMemo(
    () => ({
      banner: banners[0] || initialBanner,
      setBanner: updateBanners,
      setErrorBanner,
    }),
    [banners.length]
  );

  return <NotificationBannerContext.Provider value={ctx}>{props.children}</NotificationBannerContext.Provider>;
};

export const useNotificationBannerContext = () => {
  return useContext(NotificationBannerContext);
};

export const NotificationBanner = () => {
  const { banner } = useNotificationBannerContext();

  return banner.content ? <Banner type={banner.type} content={banner.content} /> : null;
};
