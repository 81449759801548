import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  #eoYV8Ss9Wdq3_tr {
    animation: eoYV8Ss9Wdq3_tr__tr 2100ms linear infinite normal forwards;
  }
  @keyframes eoYV8Ss9Wdq3_tr__tr {
    0% {
      transform: translate(10px, 10px) rotate(0deg);
    }
    9.52381% {
      transform: translate(10px, 10px) rotate(0deg);
      animation-timing-function: cubic-bezier(0.4, 0, 0.16, 1.01);
    }
    100% {
      transform: translate(10px, 10px) rotate(360.000002deg);
    }
  }
  #eoYV8Ss9Wdq6_tr {
    animation: eoYV8Ss9Wdq6_tr__tr 2100ms linear infinite normal forwards;
  }
  @keyframes eoYV8Ss9Wdq6_tr__tr {
    0% {
      transform: translate(10px, 10px) rotate(0deg);
      animation-timing-function: cubic-bezier(0.79, -0.005, 0.39, 1.005);
    }
    90.47619% {
      transform: translate(10px, 10px) rotate(360.000004deg);
    }
    100% {
      transform: translate(10px, 10px) rotate(360.000004deg);
    }
  }
  #eoYV8Ss9Wdq7_tr {
    animation: eoYV8Ss9Wdq7_tr__tr 2100ms linear infinite normal forwards;
  }
  @keyframes eoYV8Ss9Wdq7_tr__tr {
    0% {
      transform: translate(10px, 10px) rotate(0deg);
    }
    4.761905% {
      transform: translate(10px, 10px) rotate(0deg);
      animation-timing-function: cubic-bezier(0.79, -0.005, 0.39, 1.005);
    }
    95.238095% {
      transform: translate(10px, 10px) rotate(360.000004deg);
    }
    100% {
      transform: translate(10px, 10px) rotate(360.000004deg);
    }
  }
  #eoYV8Ss9Wdq8_tr {
    animation: eoYV8Ss9Wdq8_tr__tr 2100ms linear infinite normal forwards;
  }
  @keyframes eoYV8Ss9Wdq8_tr__tr {
    0% {
      transform: translate(10px, 10px) rotate(0deg);
    }
    9.52381% {
      transform: translate(10px, 10px) rotate(0deg);
      animation-timing-function: cubic-bezier(0.79, -0.005, 0.39, 1.005);
    }
    100% {
      transform: translate(10px, 10px) rotate(360.000004deg);
    }
  }
`;

const COLORS = {
  green: ['#7ce3cb', '#030303', 'rgba(3,3,3,0)', '#222'],
  blue: ['#153DD1', '#102FA3', '#0C2275', '#0C2275'],
};

interface Props extends ComponentPropsWithoutRef<'svg'> {
  variant?: 'green' | 'blue';
}

export const Spinner = (props: Props) => {
  const { variant = 'green', ...rest } = props;

  return (
    <StyledSvg
      id="eoYV8Ss9Wdq1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="20"
      height="20"
      {...rest}
    >
      <defs>
        <linearGradient
          id="eoYV8Ss9Wdq4-fill"
          x1="1"
          y1="0.113809"
          x2="1"
          y2="0.89921"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eoYV8Ss9Wdq4-fill-0" offset="0%" stopColor={COLORS[variant][0]} />
          <stop id="eoYV8Ss9Wdq4-fill-1" offset="74.7282%" stopColor={COLORS[variant][1]} />
          <stop id="eoYV8Ss9Wdq4-fill-2" offset="100%" stopColor={COLORS[variant][2]} />
        </linearGradient>
      </defs>
      <path
        d="M16.22125,10c0-3.4359-2.78535-6.22125-6.22125-6.22125s-6.22125,2.78535-6.22125,6.22125s2.78535,6.22125,6.22125,6.22125s6.22125-2.78535,6.22125-6.22125Zm2.00038,0c0,4.54068-3.68095,8.22163-8.22163,8.22163s-8.22163-3.68095-8.22163-8.22163s3.68095-8.22163,8.22163-8.22163s8.22163,3.68095,8.22163,8.22163Z"
        fill={COLORS[variant][3]}
        strokeWidth="0"
      />
      <g id="eoYV8Ss9Wdq3_tr" transform="translate(10,10) rotate(0)">
        <g transform="translate(-10,-9.999999)">
          <path
            d="M1.77837,10c0-4.54068,3.68095-8.22163,8.22163-8.22163c0,0,0,0,0,0v2.00038c0,0,0,0,0,0C6.5641,3.77875,3.77875,6.5641,3.77875,10s2.78535,6.22125,6.22125,6.22125c0,0,0,0,0,0v2.00038c0,0,0,0,0,0-4.54068,0-8.22163-3.68095-8.22163-8.22163Z"
            fill="url(#eoYV8Ss9Wdq4-fill)"
            strokeWidth="0"
          />
          <ellipse
            rx="1.710827"
            ry="1.710827"
            transform="matrix(.581979 0 0 0.581979 10 2.742059)"
            fill={COLORS[variant][0]}
            strokeWidth="0"
          />
        </g>
      </g>
      <g id="eoYV8Ss9Wdq6_tr" transform="translate(10,10) rotate(0)">
        <ellipse
          rx="1.710827"
          ry="1.710827"
          transform="scale(0.581979,0.581979) translate(0,-12.416197)"
          fill={COLORS[variant][0]}
          strokeWidth="0"
        />
      </g>
      <g id="eoYV8Ss9Wdq7_tr" transform="translate(10,10) rotate(0)">
        <ellipse
          rx="1.710827"
          ry="1.710827"
          transform="scale(0.581979,0.581979) translate(0,-12.416197)"
          fill={COLORS[variant][0]}
          strokeWidth="0"
        />
      </g>
      <g id="eoYV8Ss9Wdq8_tr" transform="translate(10,10) rotate(0)">
        <ellipse
          rx="1.710827"
          ry="1.710827"
          transform="scale(0.581979,0.581979) translate(0,-12.416197)"
          fill="#153DD1"
          strokeWidth="0"
        />
      </g>
    </StyledSvg>
  );
};
