import { useWeb3Modal } from '@web3modal/wagmi/react';

// Use this to by-pass jest import error of the useWeb3Modal hook
// Atm there is no solution for this problem: https://github.com/orgs/WalletConnect/discussions/4219
export function useWagmiWeb3Modal() {
  const { open } = useWeb3Modal?.() ?? { open: () => new Promise(() => {}) };

  return {
    open,
  };
}
