import { ComponentProps } from 'react';

export const GasIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_10814_99930)">
        <path d="M7.21875 1.96973H31.5V40.0322H7.21875V1.96973Z" fill="#DFE8FF" />
        <path
          d="M31.3436 11.0036L32.4897 12.8475L34.7818 16.5354V31.665L32.4937 31.6648H31.5M7.21875 1.96973H31.5V40.0322H7.21875V1.96973Z"
          stroke="#1B39FF"
          strokeWidth="0.65625"
        />
        <rect x="9.84375" y="7.21973" width="19.0312" height="9.1875" fill="#1B39FF" />
        <path d="M9.84375 30.5166H28.875M9.84375 35.1104H28.875" stroke="#1B39FF" strokeWidth="0.65625" />
      </g>
      <defs>
        <clipPath id="clip0_10814_99930">
          <rect width="42" height="42" fill="white" transform="translate(0 0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};
