import { css } from 'styled-components';
import { breakpoints } from 'styles/theme';

const XSmall = css`
  min-height: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: 400;
  line-height: 170%;
`;

const Small = css`
  min-height: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
`;

const Medium = css`
  min-height: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
`;

const Large = css`
  min-height: 24px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;

export const ButtonLinkStyle = css`
  @media (max-width: ${breakpoints.xs}px) {
    ${XSmall}
  }
  @media (max-width: ${breakpoints.sm}px) {
    ${Small}
  }
  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md}px) {
    ${Medium}
  }
  @media (min-width: ${breakpoints.md}px) {
    ${Large}
  }

  // Override default size if specific size is provided
  &.x-small {
    ${XSmall}
  }
  &.small {
    ${Small}
  }
  &.medium {
    ${Medium}
  }
  &.large {
    ${Large}
  }
`;
