export const ToastSuccessIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_48_45903" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.7211 9.30257C18.1116 8.91204 18.1116 8.27888 17.7211 7.88835C17.3305 7.49783 16.6974 7.49783 16.3069 7.88835L10.2077 13.9875L8.20686 11.9866C7.81634 11.5961 7.18317 11.5961 6.79265 11.9866C6.40212 12.3772 6.40212 13.0103 6.79265 13.4009L9.5006 16.1088C9.89113 16.4993 10.5243 16.4993 10.9148 16.1088L17.7211 9.30257Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.7211 9.30257C18.1116 8.91204 18.1116 8.27888 17.7211 7.88835C17.3305 7.49783 16.6974 7.49783 16.3069 7.88835L10.2077 13.9875L8.20686 11.9866C7.81634 11.5961 7.18317 11.5961 6.79265 11.9866C6.40212 12.3772 6.40212 13.0103 6.79265 13.4009L9.5006 16.1088C9.89113 16.4993 10.5243 16.4993 10.9148 16.1088L17.7211 9.30257Z"
        fill="#19BA63"
      />
      <path
        d="M17.7211 7.88835L17.014 8.59546L17.014 8.59546L17.7211 7.88835ZM17.7211 9.30257L17.014 8.59546L17.014 8.59546L17.7211 9.30257ZM16.3069 7.88835L17.014 8.59546L17.014 8.59546L16.3069 7.88835ZM10.2077 13.9875L9.5006 14.6946L10.2077 15.4017L10.9148 14.6946L10.2077 13.9875ZM8.20686 11.9866L7.49975 12.6938L7.49976 12.6938L8.20686 11.9866ZM6.79265 11.9866L7.49976 12.6938L7.49976 12.6938L6.79265 11.9866ZM6.79265 13.4009L7.49976 12.6938L7.49976 12.6938L6.79265 13.4009ZM9.5006 16.1088L8.7935 16.8159L8.7935 16.8159L9.5006 16.1088ZM10.9148 16.1088L11.6219 16.8159L11.6219 16.8159L10.9148 16.1088ZM21 12C21 16.9706 16.9706 21 12 21V23C18.0751 23 23 18.0751 23 12H21ZM12 3C16.9706 3 21 7.02944 21 12H23C23 5.92487 18.0751 1 12 1V3ZM3 12C3 7.02944 7.02944 3 12 3V1C5.92487 1 1 5.92487 1 12H3ZM12 21C7.02944 21 3 16.9706 3 12H1C1 18.0751 5.92487 23 12 23V21ZM17.014 8.59546V8.59546L18.4282 10.0097C19.2092 9.22862 19.2092 7.96229 18.4282 7.18124L17.014 8.59546ZM17.014 8.59546H17.014L18.4282 7.18125C17.6471 6.4002 16.3808 6.4002 15.5997 7.18125L17.014 8.59546ZM10.9148 14.6946L17.014 8.59546L15.5997 7.18125L9.5006 13.2804L10.9148 14.6946ZM7.49976 12.6938L9.5006 14.6946L10.9148 13.2804L8.91397 11.2795L7.49976 12.6938ZM7.49976 12.6938H7.49975L8.91397 11.2795C8.13292 10.4985 6.86659 10.4985 6.08554 11.2795L7.49976 12.6938ZM7.49976 12.6938V12.6938L6.08554 11.2795C5.30449 12.0606 5.30449 13.3269 6.08554 14.108L7.49976 12.6938ZM10.2077 15.4017L7.49976 12.6938L6.08554 14.108L8.7935 16.8159L10.2077 15.4017ZM10.2077 15.4017H10.2077L8.7935 16.8159C9.57455 17.597 10.8409 17.597 11.6219 16.8159L10.2077 15.4017ZM17.014 8.59546L10.2077 15.4017L11.6219 16.8159L18.4282 10.0097L17.014 8.59546Z"
        fill="#19BA63"
        mask="url(#path-1-inside-1_48_45903)"
      />
    </svg>
  );
};
