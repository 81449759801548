import { css } from 'styled-components';
import { ButtonLinkStyle } from '../sizes';

const Gray = css`
  color: var(--color-gray-400);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-green-500);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-green-300);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-gray-700);
  }
`;

const Green = css`
  color: var(--color-green-100);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-base-light);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-green-400);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-green-900);
  }
`;

const Blue = css`
  color: var(--color-blue-50);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-base-light);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-blue-300);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-blue-500);
  }
`;

const Purple = css`
  color: var(--color-purple-200);

  &:hover:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-base-light);
  }

  &:focus:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    color: var(--color-purple-400);
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    color: var(--color-purple-700);
  }
`;

const GrayLightTheme = css`
  color: var(--color-gray-700);

  &:hover:not(.disabled) {
    color: var(--color-green-700);
  }

  &:focus:not(.disabled) {
    color: var(--color-green-800);
  }

  &:disabled {
    color: var(--color-gray-300);
  }
`;

const GreenLightTheme = css`
  color: var(--color-green-700);

  &:hover:not(.disabled) {
    color: var(--color-gray-600);
  }

  &:focus:not(.disabled) {
    color: var(--color-green-800);
  }

  &:disabled {
    color: var(--color-green-100);
  }
`;

const BlueLightTheme = css`
  color: var(--color-blue-500);

  &:hover:not(.disabled) {
    color: var(--color-gray-600);
  }

  &:focus:not(.disabled) {
    color: var(--color-blue-600);
  }

  &:disabled {
    color: var(--color-blue-50);
  }
`;

const PurpleLightTheme = css`
  color: var(--color-purple-400);

  &:hover:not(.disabled) {
    color: var(--color-gray-600);
  }

  &:focus:not(.disabled) {
    color: var(--color-purple-600);
  }

  &:disabled {
    color: var(--color-purple-100);
  }
`;

export const LinkVariantStyle = css`
  ${ButtonLinkStyle}

  &.gray {
    ${Gray}
  }

  &.green {
    ${Green}
  }

  &.blue {
    ${Blue}
  }

  &.purple {
    ${Purple}
  }

  &.gray-light-theme {
    ${GrayLightTheme}
  }

  &.green-light-theme {
    ${GreenLightTheme}
  }

  &.blue-light-theme {
    ${BlueLightTheme}
  }

  &.purple-light-theme {
    ${PurpleLightTheme}
  }

  &:disabled,
  &.disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }

  &.no-border {
    border: none !important;
  }
`;
