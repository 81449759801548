import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSimpleSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamQueryValue = searchParams.get('search') || '';

  const [userInput, setUserInput] = useState(searchParamQueryValue);

  // Update local search value on params change
  useEffect(() => {
    setUserInput(searchParamQueryValue);
  }, [searchParams]);

  const handleSearchClear = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('search');
    setSearchParams(newParams);
    setUserInput('');
  };

  const setSearchValue = (value: string) => {
    setSearchParams((params) => {
      params.set('search', value);
      return params;
    });
  };

  const handleSearchSubmit = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (!userInput) {
      handleSearchClear();
      return;
    }
    setSearchValue(userInput);
  };

  return {
    onSearchChange: setUserInput,
    onSearchClear: handleSearchClear,
    onUserInputClear: () => setUserInput(''),
    onSearchSubmit: handleSearchSubmit,
    setSearchValue,
    searchParamQueryValue,
    userInput,
  };
};
