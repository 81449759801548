import { ethers } from 'ethers';
import { Subscription } from 'types';
import { deriveSponsorWalletAddress } from '@api3/dapi-management';
import { encodeDapiName, encodeUpdateParameters, getApi3Market } from 'utils/contracts';
import { formatBaseUnits } from 'utils/utils';

export const PRICE_INCREASE_IN_HOURS = 24;
export const MIN_SIGNIFICANT_DISCOUNT_PERCENT = 0.01; // 0.01%

export const computeSubscriptionPricePerHours = (subscription: Subscription, hours: number) => {
  const priceInWei = BigInt(subscription.price);
  const durationInHours = BigInt(Math.round(subscription.duration / (hours * 60 * 60)));
  return priceInWei / durationInHours;
};

// use formatter logic to round the price up
export const roundThePriceUp = (price: bigint) => {
  const formattedPrice = formatBaseUnits(price);
  if (!formattedPrice) return price;
  return ethers.parseEther(formattedPrice.replace(/,/g, ''));
};

// We add something on top of the base price in case some funds leaves the sponsor wallet while the transaction is pending
export const computeFullPrice = (subscription: Subscription, basePrice: bigint) => {
  const pricePerDay = computeSubscriptionPricePerHours(subscription, PRICE_INCREASE_IN_HOURS);
  return roundThePriceUp(basePrice + pricePerDay);
};

// If we have some balance in the sponsor wallet allready we present it as a discount in the UI.
// There are two edge cases we are handling in this function:
// 1. There is more funds in the sponsor wallet as the actual price - we show the price as zero
// 2. The discount is negligible - we don't show it at all
export const computeDiscountedPrice = async (fullPrice: bigint, dapiName: string, provider: ethers.Provider) => {
  const sponsorWalletAddress = deriveSponsorWalletAddress(encodeDapiName(dapiName));
  const currentBalance = await provider.getBalance(sponsorWalletAddress);
  const discountedPrice = fullPrice - currentBalance;
  const nonNegativeDiscountedPrice = discountedPrice > 0n ? discountedPrice : 0n;

  const precision = 1000;
  const discountPercent = 100 - Number((nonNegativeDiscountedPrice * 100n * BigInt(precision)) / fullPrice) / precision;

  const noDiscount = discountPercent < MIN_SIGNIFICANT_DISCOUNT_PERCENT;
  return noDiscount ? undefined : roundThePriceUp(nonNegativeDiscountedPrice);
};

// Compute the price for each subscription and return the updated subscription objects
export const computeSubscriptionPrices = async (
  chainId: string,
  dapiName: string,
  provider: ethers.Provider,
  subscriptions: Subscription[]
) => {
  const api3Market = await getApi3Market(chainId, provider);
  const calldatas = subscriptions.map((data) => {
    const encodedDapiName = encodeDapiName(dapiName);
    const updateParameters = encodeUpdateParameters(data.updateParameters);
    return api3Market.interface.encodeFunctionData('computeExpectedSponsorWalletBalanceAfterSubscriptionIsAdded', [
      encodedDapiName,
      updateParameters,
      data.duration,
      data.price,
    ]);
  });

  const { successes, returndata } = await api3Market.tryMulticall.staticCall(calldatas);
  const priceCalculationPromises = subscriptions.map(async (subscription, index) => {
    if (!successes[index]) return subscription;
    const expectedSponsorWalletBalance = api3Market.interface.decodeFunctionResult(
      'computeExpectedSponsorWalletBalanceAfterSubscriptionIsAdded',
      returndata[index]
    )[0] as bigint;

    const fullPrice = computeFullPrice(subscription, expectedSponsorWalletBalance);
    const discountedPrice = await computeDiscountedPrice(fullPrice, dapiName, provider);
    const discount = discountedPrice !== undefined && fullPrice - discountedPrice;

    return {
      ...subscription,
      discountedPrice: discountedPrice?.toString(),
      price: fullPrice.toString(),
      discount: discount?.toString(),
    };
  });

  const subscriptionsWithCalculatedPrices = await Promise.all(priceCalculationPromises);

  return subscriptionsWithCalculatedPrices.filter((_, index) => successes[index]);
};
