// "phrase" contains words separated by spaces or other non-word characters such as / and -
export const checkPhraseMatchesSearchQuery = (phrase: string, searchQuery: string) => {
  if (searchQuery === '') {
    return true;
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
  const escapedSearchQuery = searchQuery.toLocaleLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`\\b${escapedSearchQuery.trim()}`, 'i');

  return regex.test(phrase.toLocaleLowerCase());
};

export const filterDapisBySearchQuery = (dapis: string[], searchQuery: string) => {
  return dapis.filter((dapiName) => {
    // Some dAPI name part (divided by space or slash) starts with the search query
    const dapiNamePartsMatchSearchQuery = checkPhraseMatchesSearchQuery(dapiName, searchQuery);
    // Any word (including after slash) include at least 3 characters of the search query
    const dapiNameMatchesThreeChars = checkDapiNameMatchesAtLeastThreeChars(dapiName, searchQuery);

    return dapiNamePartsMatchSearchQuery || dapiNameMatchesThreeChars;
  });
};

export const checkDapiNameMatchesAtLeastThreeChars = (dapiName: string, searchQuery: string) => {
  if (searchQuery.length < 3) return false;

  // To return true, search query must contain only letters and numbers
  // (we want to avoid matching "ge r" with "Exchange Rate" for example)
  const cleanSearchQuery = /^[a-zA-Z0-9]+$/.test(searchQuery);

  return cleanSearchQuery && dapiName.toLocaleLowerCase().includes(searchQuery);
};

export const sortMatchingDapisByPriority = (matchingDapis: string[], searchQuery: string) =>
  matchingDapis.sort((dapi1, dapi2) => getMatchingScore(dapi1, searchQuery) - getMatchingScore(dapi2, searchQuery));

const getMatchingScore = (dapi: string, searchQueryInitial: string) => {
  const searchQuery = searchQueryInitial.toLowerCase();
  // Rules:
  // 1. DapiName starts with search query
  // 2. Words (strings delimited by space) start with search query
  // 3. Second feed starts with search query (after /, if there is any)
  // 4. Words include at least 3 characters of search query
  const dapiWords = dapi.toLowerCase().split(' ');
  const dapiFeeds = dapi.toLowerCase().split('/');

  if (dapi.startsWith(searchQuery)) {
    return 1;
  }

  if (dapiWords.some((word) => word.startsWith(searchQuery))) {
    return 2;
  }

  if (dapiFeeds[1]?.startsWith(searchQuery)) {
    return 3;
  }

  if (searchQuery.length >= 3 && dapiWords.some((word) => word.includes(searchQuery))) {
    return 4;
  }

  return 5;
};
