import styled from 'styled-components';
import { NO_VALUE } from 'constants/constants';

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledActionBtn = styled.button`
  &:disabled {
    color: rgba(64, 65, 65, 1);
  }
`;

const StyledValueWrapper = styled.span`
  padding: 0 10px;
`;

interface IProps {
  min?: number;
  max?: number;
  step?: number;
  prefix?: string;
  className?: string;
  value: number;
  onChange: (value: number) => void;
  disabled: boolean;
}

export const NumericInput = ({
  min = 0,
  max = 1000000,
  step = 1,
  prefix = '',
  className = '',
  value,
  onChange,
  disabled = false,
}: IProps) => {
  const handleAction = (increase = true) => {
    if (increase && value + step <= max) {
      onChange(value + step);
    } else if (!increase && value - step >= min) {
      onChange(value - step);
    }
  };

  return (
    <StyledWrapper className={className}>
      <StyledActionBtn
        type="button"
        onClick={() => handleAction(false)}
        className="arrow-down increase-decrease-btn"
        disabled={disabled}
      >
        -
      </StyledActionBtn>
      <StyledValueWrapper prefix={prefix} className="numeric-input-wrapper">
        {disabled ? NO_VALUE : value}
      </StyledValueWrapper>
      <StyledActionBtn
        type="button"
        onClick={() => handleAction(true)}
        className="arrow-up increase-decrease-btn"
        disabled={disabled}
      >
        +
      </StyledActionBtn>
    </StyledWrapper>
  );
};
