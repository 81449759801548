import { ReactNode, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDetectPageScroll } from 'hooks';
import { breakpoints } from 'styles/theme';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

// We have the navigation bar hidden when scrolling down and shown when scrolling up
// Because the navigation bar is transparent, we need to clip the content of the page
// from top so it doesn't show through
export const ScrollableContent = ({ children }: Props) => {
  const location = useLocation();
  const hasSearchParam = location.search.split(/[?&=]/).includes('search');
  const isOnSearchResultsPage = location.pathname.match(/^\/[a-z\-]+$/) && hasSearchParam;
  const isOnHomePage = location.pathname === '/';
  const headerFade = !isOnSearchResultsPage && !isOnHomePage;
  const contentRef = useRef<HTMLDivElement>(null);
  const { resetClipPath } = useDetectPageScroll(contentRef);

  // On the page change reset scroll position
  useEffect(() => {
    resetClipPath();
  }, [location]);

  return (
    <Container ref={contentRef} headerfade={headerFade.toString()}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ headerfade: string }>`
  --clipped-content: calc(var(--content-clip-height) + var(--header-height-sm) * (var(--header-displayed) - 1));

  @media (min-width: ${breakpoints.md}px) {
    --clipped-content: calc(var(--content-clip-height) + var(--header-height-md) * (var(--header-displayed) - 1));
  }

  clip-path: inset(var(--clipped-content) 0 0 0);

  ${({ headerfade }) =>
    headerfade === 'true' &&
    css`
      --starting-opacity: calc(1 - var(--page-scrolled)); // display fade effect only when the page is scrolled
      -webkit-mask: linear-gradient(
        to bottom,
        rgba(0, 0, 0, var(--starting-opacity)) var(--clipped-content),
        rgba(0, 0, 0, 1) calc(var(--clipped-content) + 100px)
      );
      mask: linear-gradient(
        to bottom,
        rgba(0, 0, 0, var(--starting-opacity)) var(--clipped-content),
        rgba(0, 0, 0, 1) calc(var(--clipped-content) + 100px)
      );
    `}
`;
