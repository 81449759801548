import { breakpoints } from 'styles/theme';
import styled from 'styled-components';
import { api3Chains } from '@api3/dapi-management';
import { CopyButton } from 'components/shared';
import { abbrStr, createExplorerUrl } from 'utils/utils';
import { TransactionResponse } from 'ethers';
import { SmartLink } from 'components/smart-link';
import { TransactionStatus } from 'types';
import { fonts } from 'styles/fonts';
import { ExpirationDetails } from './expiration-details';

interface Props {
  tx: Pick<TransactionResponse, 'hash' | 'from'>;
  chain: api3Chains.Chain;
  dapiName: string;
  txStatus?: TransactionStatus;
}

export const TransactionSummary = ({ tx, chain, dapiName, txStatus }: Props) => {
  return (
    <Container data-testid="transaction-summary">
      <div className="summary-item">
        <div className="label">Transaction Hash</div>
        <div className="value">
          <SmartLink href={createExplorerUrl(chain.explorer.browserUrl, tx.hash, 'tx')}>
            {abbrStr(tx.hash, 5)}
          </SmartLink>{' '}
          <CopyButton copyString={tx.hash} />
        </div>
      </div>
      {txStatus === 'success' && <ExpirationDetails chain={chain} dapiName={dapiName} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
  padding: 24px 0 24px 0;

  .label {
    ${fonts.body[9]}
    color: var(--color-dark-blue-25);
    padding-bottom: 6px;
    padding-top: 2px;
    text-align: center;
  }

  .value {
    display: flex;
    align-items: center;
    gap: 4px;
    ${fonts.body[6]}
    justify-content: center;

    a {
      color: var(--color-green-500);
      text-decoration: none;
      transition: color var(--default-transition-duration);

      &:hover {
        color: var(--font-color-white);
      }
    }
  }

  .expiration-date {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.sm}px) {
    gap: 0;

    .label {
      font-size: 16px;
      padding-top: 12px;
      text-align: right;
      width: 132px;
    }

    .summary-item {
      display: flex;
      gap: 24px;
      justify-content: center;
    }

    .expiration-date {
      align-items: flex-start;
      gap: 6px;
      &.value {
        padding-top: 10px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    .label {
      font-size: 16px;
      padding: 12px 0;
    }

    .value {
      font-size: 18px;
      line-height: 150%;
    }
  }
`;
