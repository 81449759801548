import { dapis } from '@api3/dapi-management';
import { ApiProviderLogo, ChainLogo, SymbolLogo } from '@api3/logos';
import { API3_CHAINS, api3ApiIntegrations } from 'utils/dapis';
import { useEffect, useState } from 'react';
import { useImageLoader } from './use-image-loader';

// Hook to preload all logos from the @api3/logos package so they are available in the cache when needed.
export const useLogosPreLoader = () => {
  const [allLogos, setAllLogos] = useState<string[]>([]);

  useEffect(() => {
    const allChainLogos = API3_CHAINS.flatMap((chain) => [ChainLogo(chain.id), ChainLogo(chain.id, true)]);
    const allDapiLogos = dapis.flatMap((dapi) => {
      const parts = dapi.name.split('/');
      return [SymbolLogo(parts[0]), SymbolLogo(parts[0], true), SymbolLogo(parts[1]), SymbolLogo(parts[1], true)];
    });
    const allProviderLogos = Object.keys(api3ApiIntegrations.apisData).map((provider) => ApiProviderLogo(provider));
    const allLogos = [...allChainLogos, ...allDapiLogos, ...allProviderLogos];
    setAllLogos(allLogos);
  }, [API3_CHAINS, dapis, api3ApiIntegrations.apisData]);

  useImageLoader(allLogos);
};
