import { NON_ALPHANUMERIC_REGEX } from 'constants/constants';
import { useParams, useSearchParams } from 'react-router-dom';
import { dapisBySlug } from 'utils/dapis';
import { slugify } from 'utils/format';

const handleLegacyUrls = (dapiSlugOrDapiName: string) => {
  if (dapiSlugOrDapiName.match(NON_ALPHANUMERIC_REGEX)) {
    return slugify(dapiSlugOrDapiName); // Handle legacy URLs with name instead of slug
  }
  return dapiSlugOrDapiName;
};

export const usePageParams = () => {
  const { chainAlias, dapiSlug } = useParams();
  const [searchParams] = useSearchParams();

  const showHidden = searchParams.get('showHidden') === 'true'; // Workaround to show hidden chains

  if (!dapiSlug) {
    return { chainAlias, showHidden };
  }

  const slug = handleLegacyUrls(dapiSlug);
  const dapiName = dapisBySlug[slug]?.name;

  return { chainAlias, dapiName, dapiSlug, showHidden };
};
