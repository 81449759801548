import { forwardRef, ComponentPropsWithoutRef, ReactNode, KeyboardEventHandler, ReactElement } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { breakpoints, BreakpointKeys } from 'styles/theme';
import { useWindowDimensions } from 'hooks/use-window-dimensions';
import { SmartLink } from 'components/smart-link';
import { useNavigate } from 'react-router-dom';
import { isExternal } from 'components/smart-link/smart-link';
import * as variants from './styles';
import { Spinner } from '../spinner/spinner';
import { ExternalLinkIcon } from '../icons';
import { Tooltip } from '../tooltip/tooltip';

export type Size = 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';

type BreakpointsProps = { [key in BreakpointKeys]?: { size?: Size } };

export interface ButtonProps extends ComponentPropsWithoutRef<'button'>, BreakpointsProps {
  color?: 'gray' | 'green' | 'blue' | 'purple' | 'white' | 'gradient';
  theme?: 'dark' | 'light';
  destructive?: boolean;
  fullWidth?: boolean;
  iconOnly?: boolean;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  loading?: boolean;
  noBorder?: boolean;
  size?: Size;
  tooltip?: string;
  variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'text'
    | 'link'
    | 'link-secondary'
    | 'menu-link'
    | 'menu-dropdown'
    | 'menu-link-secondary'
    | 'menu-dropdown-secondary';
  disabled?: boolean;
  href?: string;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, forwardedRef) => {
  const {
    children,
    className,
    color = 'gray',
    theme = 'dark',
    destructive = false,
    disabled = false,
    fullWidth = false,
    iconOnly = false,
    iconEnd,
    iconStart,
    loading = false,
    noBorder = false,
    size,
    tooltip,
    xs,
    sm,
    md,
    lg,
    variant,
    href,
    onClick,
    onKeyDown,
    ...rest
  } = props;

  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const sizeClass = getSizeClass(width, size, { xs, sm, md, lg });
  const colorClass = getColorClass(color, theme)!;
  const isInline =
    variant === 'link' ||
    variant === 'link-secondary' ||
    variant === 'menu-link' ||
    variant === 'menu-dropdown' ||
    variant === 'menu-dropdown-secondary';

  const handleClick = (event: any) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    onClick?.(event);
  };

  if (href) {
    return (
      <ButtonTooltip tooltip={tooltip}>
        <StyledLink
          href={href}
          aria-disabled={disabled}
          role="button"
          className={clsx(className, variant, colorClass, {
            destructive,
            [sizeClass]: sizeClass,
            'full-width': fullWidth,
            block: !isInline,
            disabled,
          })}
          onClick={handleClick}
          onKeyDown={(event) => {
            if (disabled) {
              event.preventDefault();
              return;
            }

            // If we have a custom onKeyDown handler, we don't want to override it
            if (onKeyDown) {
              onKeyDown(event);
              return;
            }

            // Otherwise, we want to open the link on enter or space key
            if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault(); // We don't want to scroll on space key
              event.stopPropagation();

              if (isExternal(href)) {
                window.open(href, '_blank');
              } else {
                navigate(href);
              }
            }
          }}
          tabIndex={disabled ? -1 : 0}
        >
          {iconStart}
          {children}
          {iconEnd === undefined ? <ExternalLinkIcon className="external-link-icon" /> : iconEnd}
        </StyledLink>
      </ButtonTooltip>
    );
  }

  return (
    <ButtonTooltip tooltip={tooltip}>
      <StyledButton
        type="button"
        ref={forwardedRef}
        aria-disabled={disabled || loading}
        disabled={disabled || loading}
        {...rest}
        role="button"
        onKeyDown={onKeyDown}
        onClick={handleClick}
        className={clsx(className, variant, colorClass, theme, {
          destructive,
          [sizeClass]: sizeClass,
          'full-width': fullWidth,
          'no-border': noBorder,
          'icon-only': iconOnly,
          disabled,
        })}
      >
        {loading ? <Spinner variant={variant === 'primary' ? 'blue' : 'green'} /> : iconStart}
        {preventSingleWordOnSecondLine(children)}
        {iconEnd}
      </StyledButton>
    </ButtonTooltip>
  );
});

interface ButtonTooltipProps {
  children: ReactElement;
  tooltip?: string;
}

const ButtonTooltip = (props: ButtonTooltipProps) => {
  const { children, tooltip } = props;

  if (!tooltip) {
    return children;
  }

  return <Tooltip content={tooltip}>{children}</Tooltip>;
};

const getSizeClass = (width: number, size?: Size, sizeProps?: BreakpointsProps) => {
  let sizeClass: Size | undefined;

  const { xs, sm, md, lg } = sizeProps || {};

  if (width >= breakpoints.lg) {
    sizeClass = lg?.size || md?.size || sm?.size || xs?.size;
  }
  if (width < breakpoints.lg) {
    sizeClass = md?.size || sm?.size || xs?.size;
  }
  if (width < breakpoints.md) {
    sizeClass = sm?.size || xs?.size;
  }
  if (width < breakpoints.sm) {
    sizeClass = xs?.size;
  }

  return sizeClass || size || '';
};

const getColorClass = (color: ButtonProps['color'], theme: ButtonProps['theme']) => {
  return theme === 'light' ? `${color}-light-theme` : color;
};

const preventSingleWordOnSecondLine = (children: string | any) => {
  if (typeof children !== 'string') {
    return children;
  }

  const noOfSpaces = children.split(' ').length - 1;
  const lastSpaceIndex = children.lastIndexOf(' ');

  if (noOfSpaces < 2) {
    return children;
  }

  // Replace last space with non-breaking space
  return `${children.substring(0, lastSpaceIndex)}\u00A0${children.substring(lastSpaceIndex + 1)}`;
};

const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  transition: all 200ms ease-out;
  border: none;
  border-radius: 24px;
  background-color: transparent;
  color: var(--font-color-white);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: fit-content;
  gap: 8px;

  @media (min-width: ${breakpoints.lg}px) {
    gap: 12px;
  }

  > svg {
    height: 24px;
    width: 24px;
  }

  icon-only > svg {
    height: unset;
    width: unset;
  }

  &.primary {
    ${variants.PrimaryVariantStyle}
  }
  &.secondary {
    ${variants.SecondaryVariantStyle}
  }
  &.tertiary {
    ${variants.TertiaryVariantStyle}
  }
  &.text {
    ${variants.TextVariantStyle}
  }
  &.link {
    ${variants.LinkVariantStyle};
  }
  &.link-secondary {
    ${variants.LinkSecondaryVariantStyle};
  }
  &.menu-link {
    ${variants.MenuLinkVariantStyle};
  }
  &.menu-link-secondary {
    ${variants.MenuLinkSecondaryVariantStyle};
  }
  &.menu-dropdown {
    ${variants.MenuDropdownVariantStyle};
  }
  &.menu-dropdown-secondary {
    ${variants.MenuDropdownSecondaryVariantStyle};
  }
`;

const StyledLink = styled(SmartLink)`
  position: relative;
  transition: all 200ms ease-out;
  text-decoration: none;
  &.block {
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: fit-content;
    gap: 8px;
  }

  .external-link-icon {
    margin: 0 4px 0 6px;
    color: var(--color-dark-blue-50);
  }

  &.x-small {
    .external-link-icon {
      margin: 0 2px 0 4px;
      width: 8px;
      height: 8px;
    }
  }

  &.small {
    .external-link-icon {
      margin: 0 2px 0 4px;
      width: 10px;
      height: 10px;
    }
  }

  &.primary {
    ${variants.PrimaryVariantStyle}
  }
  &.secondary {
    ${variants.SecondaryVariantStyle}
  }
  &.tertiary {
    ${variants.TertiaryVariantStyle}
  }
  &.text {
    ${variants.TextVariantStyle}
  }
  &.link {
    ${variants.LinkVariantStyle};
  }
  &.link-secondary {
    ${variants.LinkSecondaryVariantStyle};
  }
  &.menu-link {
    ${variants.MenuLinkVariantStyle};
  }
  &.menu-link-secondary {
    ${variants.MenuLinkSecondaryVariantStyle};
  }
  &.menu-dropdown {
    ${variants.MenuDropdownVariantStyle};
  }
  &.menu-dropdown-secondary {
    ${variants.MenuDropdownSecondaryVariantStyle};
  }
`;
