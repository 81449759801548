import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDisconnect as useWagmiDisconnect } from 'wagmi';

export function useDisconnect() {
  const { disconnect: wagmiDisconnect } = useWagmiDisconnect();

  const queryClient = useQueryClient();

  const disconnect = () => {
    wagmiDisconnect();
    // Clear user-related data from the storage
    queryClient.removeQueries({ queryKey: ['userBalance'] });
  };

  return disconnect;
}

// There is a bug when user after restarting the browser is still connected with their wallet
// despite MetaMask being locked after the browser restart, causing any MetaMask-related action to fail.
// This hook clears the wagmi local storage when page is closed to prevent this bug from happening.
export function useDisconnectOnPageClose() {
  const clearWagmiStore = () => localStorage.removeItem('wagmi.store');

  useEffect(() => {
    window.addEventListener('beforeunload', clearWagmiStore);

    return () => {
      window.removeEventListener('beforeunload', clearWagmiStore);
    };
  }, []);
}
