import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { allChainsByAlias, dapisBySlug } from 'utils/dapis';
import { usePageParams } from './use-page-params';
import { useAllChains } from './use-all-chains';

export const useValidatePageParams = () => {
  const { chainAlias, dapiSlug } = usePageParams();
  const navigate = useNavigate();
  const { allChains } = useAllChains();

  useEffect(() => {
    const noParams = chainAlias === undefined && dapiSlug === undefined;
    if (noParams) {
      return;
    }

    const chain = chainAlias ? allChainsByAlias[chainAlias] : undefined;
    const wrongDapiName = !!dapiSlug && !dapisBySlug[dapiSlug];
    const wrongChainAlias = !chain || !allChains.includes(chain);

    if (wrongDapiName || wrongChainAlias) {
      navigate('/404');
    }
  }, [chainAlias, dapiSlug]);
};
