import { ReactElement, ReactNode, useState, useRef, forwardRef, ComponentPropsWithoutRef } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import styled from 'styled-components';
import { isNullish } from 'utils/utils';
import clsx from 'clsx';
import { useOnClickOutside } from 'hooks/use-on-outside-click';
import { fonts } from 'styles/fonts';
import { CrossIcon } from '../icons';

export type Props = {
  /*
    The element that triggers the tooltip to open.
    It should preferably be a focusable element (like a button or an anchor) that accepts a React ref.
   */
  children: ReactElement;
  content: ReactNode;
  placement?: 'bottom' | 'top' | 'left' | 'right';
  offset?: number;
  align?: 'center' | 'start' | 'end';
  alignOffset?: number;
  displayArrow?: boolean;
  styleContent?: boolean;
  /*
    Info tooltips have a close button and won't close when hovering outside of the tooltip.
   */
  infoTooltip?: boolean;
};

export const Tooltip = (props: Props) => {
  const {
    children,
    content,
    offset = 5,
    placement = 'bottom',
    align = 'center',
    alignOffset = 0,
    displayArrow = true,
    styleContent = true,
    infoTooltip = false,
  } = props;
  const [open, setOpen] = useState(false);

  const triggerRef = useRef<HTMLElement>();
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(tooltipRef, () => {
    if (!infoTooltip) {
      return;
    }

    setOpen(false);
  });

  const Content = styleContent ? StyledContent : RadixTooltip.Content;

  const handleOpenChange = (isOpen: boolean) => {
    if (infoTooltip) {
      return;
    }

    setOpen(isOpen);
  };

  return (
    <RadixTooltip.Root open={open} onOpenChange={handleOpenChange}>
      <RadixTooltip.Trigger
        className={clsx({ 'manual-close': infoTooltip })}
        asChild
        // @ts-expect-error Radix typed the ref to expect a button ref, but the trigger can be a non-button element
        ref={triggerRef}
        onClick={(ev) => {
          const isLink = ev.currentTarget.tagName === 'A';
          const isFormSubmitButton =
            ev.currentTarget.tagName === 'BUTTON' &&
            ev.currentTarget.type === 'submit' &&
            isNestedInForm(ev.currentTarget);

          // Calling preventDefault prevents the tooltip from closing, but we don't want to call
          // preventDefault on a link, or a form submit button, because that alters their behaviour
          if (!isLink && !isFormSubmitButton) {
            ev.preventDefault();
          }
          // Set to open here so that touch devices can open the tooltips on click
          setOpen(true);
        }}
      >
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <Content
          ref={tooltipRef}
          className={clsx({ 'manual-close': infoTooltip })}
          side={placement}
          sideOffset={offset}
          align={align}
          alignOffset={alignOffset}
          avoidCollisions
          onMouseDown={(ev) => {
            // Corner case: When clicking on a tooltip that is showing on a popover, we don't want
            // the popover to close
            ev.stopPropagation();
          }}
          onPointerDownOutside={(ev) => {
            // We don't want the tooltip to close when clicking the trigger. We need to call preventDefault
            // on both this event, and the trigger's click event
            if (triggerRef.current!.contains(ev.target as Node)) {
              ev.preventDefault();
            }
          }}
          data-testid="tooltip-content"
        >
          {infoTooltip && (
            <button type="button" className="tooltip-close-button" onClick={() => setOpen(false)}>
              <CrossIcon strokeWidth={3} />
            </button>
          )}
          {content}
          {displayArrow && <TooltipArrow />}
        </Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

const Button = forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<'button'>>((props, ref) => (
  <button ref={ref} type="button" {...props} />
));

const Trigger = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
  cursor: default;

  &.manual-close {
    cursor: pointer;
  }
`;

Tooltip.Trigger = Trigger;

const StyledContent = styled(RadixTooltip.Content)`
  background: var(--color-base-light);
  box-shadow: 0px 4px 20px 0px rgba(16, 18, 17, 0.9);
  border-radius: 4px;
  padding: 12px 16px;
  color: var(--color-base-dark);
  ${fonts.body[12]}
  position: relative;
  z-index: var(--z-index-tooltip);
  margin: 0 10px;

  &.manual-close {
    .tooltip-close-button {
      padding: 0;
      border: none;
      position: absolute;
      right: 8px;
      top: 8px;
      width: 20px;
      height: 20px;
      background-color: transparent;

      svg {
        color: var(--color-gray-700);
        width: 20px;
        height: 20px;
      }
    }
  }

  a {
    color: var(--color-emerald-anchor);
  }

  &[data-state='delayed-open'] {
    animation: var(--animation-fade-in);
  }
`;

const TooltipArrow = styled(RadixTooltip.Arrow)`
  fill: #f3f3f3;
`;

function isNestedInForm(element: HTMLElement) {
  let { parentElement } = element;

  while (!isNullish(parentElement)) {
    if (parentElement.tagName === 'FORM') {
      return true;
    }

    // Move up to the next parent element and continue
    parentElement = parentElement.parentElement;
  }

  return false;
}
