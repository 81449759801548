import { useLocation, useNavigate } from 'react-router-dom';
import { useURLWithShowHidden } from './use-url-with-show-hidden';

export const useGoOneLevelUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getURL } = useURLWithShowHidden();

  return {
    goOneLevelUp: () => {
      const newPath = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
      navigate(getURL(newPath));
    },
  };
};
