import { Suspense, lazy } from 'react';
import { Route, Navigate, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { WagmiProvider } from 'wagmi';
import * as Tooltip from '@radix-ui/react-tooltip';
import { FeedDetailsPage } from 'pages/feed/feed-details-page';
import IntegratePage from 'pages/integrate/integrate-page';
import { Layout } from 'components/layout/layout';
import HomePage from 'pages/home/homepage';
import PurchasePage from 'pages/purchase/purchase-page';
import { getMaintenanceStatus } from 'components/maintenance/maintenance-utils';
import ChainPage from 'pages/chain/chain-page';
import { wagmiConfig, projectId, walletIds } from 'utils/wallet-connect';

const NotFoundPage = lazy(() => import('pages/not-found/not-found'));
const MaintenancePage = lazy(() => import('pages/maintenance/maintenance-page'));

const { maintenanceStatus } = getMaintenanceStatus();

const router = createBrowserRouter(
  createRoutesFromElements(
    maintenanceStatus === 'under-maintenance' ? (
      <>
        <Route path="/maintenance" element={<Layout />}>
          <Route path="/maintenance" element={<MaintenancePage />} />
        </Route>
        <Route path="*" element={<Navigate to="/maintenance" replace />} />
      </>
    ) : (
      <>
        <Route path="/" element={<Layout />}>
          <Route path=":chainAlias" element={<ChainPage />} />
          {/* from historical reasons we support also /dapis path as the homepage */}
          <Route path="/dapis" element={<HomePage />} />
          <Route path=":chainAlias/:dapiSlug/" element={<FeedDetailsPage />} />
          <Route path=":chainAlias/:dapiSlug/activate" element={<PurchasePage />} />
          <Route path=":chainAlias/:dapiSlug/integrate" element={<IntegratePage />} />
          <Route index element={<HomePage />} />
          <Route path="/404" element={<NotFoundPage />} />
        </Route>
        <Route path="/maintenance" element={<Navigate to="/" replace />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </>
    )
  )
);

const App = () => {
  const queryClient = new QueryClient();
  const hideDuringCypress = !window.Cypress;

  createWeb3Modal({
    wagmiConfig,
    projectId,
    allWallets: 'ONLY_MOBILE', // https://docs.walletconnect.com/web3modal/react/options#allwallets
    includeWalletIds: [walletIds.METAMASK, walletIds.WALLET_CONNECT],
    excludeWalletIds: [walletIds.COINBASE],
    featuredWalletIds: [],
    allowUnsupportedChain: true,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={wagmiConfig}>
        <Suspense fallback={null}>
          <Tooltip.Provider delayDuration={200}>
            {hideDuringCypress && <ReactQueryDevtools initialIsOpen={false} />}
            <RouterProvider router={router} />
          </Tooltip.Provider>
        </Suspense>
      </WagmiProvider>
    </QueryClientProvider>
  );
};

export default App;
