import { ComponentProps } from 'react';

export const ErrorCircleFillIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="9.5" fill="#FF2E6A" stroke="#FF2E6A" />
      <path
        d="M8.42969 8.42969L15.5697 15.5697M15.5697 8.42969L8.42969 15.5697"
        stroke="#FFDBE5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
