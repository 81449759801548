import { links } from './links';

export type FooterLinksType =
  | 'About API3'
  | 'Docs'
  | 'Github'
  | 'Privacy Policy'
  | 'Privacy and Cookies'
  | 'Terms & Conditions'
  | 'Report a Bug';

export const footerLinks: Array<{ label: FooterLinksType; path: string }> = [
  { label: 'About API3', path: links.API3_WEBSITE },
  { label: 'Docs', path: links.DOCS_HOW_TO_INTEGRATE },
  { label: 'Report a Bug', path: links.REPORT_A_BUG },
  { label: 'Github', path: links.API3_GITHUB },
  { label: 'Privacy Policy', path: links.PRIVACY_POLICY },
  { label: 'Privacy and Cookies', path: links.PRIVACY_AND_COOKIES },
  { label: 'Terms & Conditions', path: links.TERMS_AND_CONDITIONS },
];
