export const MISSING_VALUE_DASH = '-';

export const NO_VALUE = '—';

export const INPUT_DEBOUNCE = 500;

export const DEFAULT_TABLE_ROW_COUNT = 10;

export const HTTP_STATUS_CODES = {
  NOT_FOUND: 404,
};

export const DEFAULT_UPDATES_PER_DAY = 12;

export const NON_ALPHANUMERIC_REGEX = /[^a-z0-9-]+/g;

export const DEFAULT_PROXY_METADATA = '0x';
export const DEFAULT_PROXY_DAPP_ID = 1;
export const MAX_SOURCES_MOBILE = 7; // Temporary limit for mobile sources
