import { ReactElement } from 'react';
import * as Sentry from '@sentry/react';
import throttle from 'lodash/throttle';
import { toast, Slide, ToastOptions } from 'react-toastify';
import { CrossIcon, ToastSuccessIcon, ToastErrorIcon } from 'components/shared/icons';
import styled from 'styled-components';
import './react-toastify-overrides.css';
import { fonts } from 'styles/fonts';
import { breakpoints } from 'styles/theme';

const THROTTLE_MS = 500;

interface ToastProps {
  message: string | ReactElement;
  secondaryMessage?: string;
  errorToReport?: Error | string;
}

const GenericIcon = ({ type }: { type: ToastOptions['type'] }) => {
  const icons: { [index: string]: ReactElement | null } = {
    info: null,
    success: <ToastSuccessIcon />,
    error: <ToastErrorIcon />,
  };

  return icons[String(type)];
};

const CustomToast = ({
  message,
  secondaryMessage,
  type,
}: Pick<ToastProps, 'message' | 'secondaryMessage'> & { type: ToastOptions['type'] }) => {
  const hasSubtitle = !!secondaryMessage;

  if (hasSubtitle) {
    return (
      <StyledToasts data-testid={`${type}-toast`}>
        <div className="title">
          <GenericIcon type={type} />
          <span>{message}</span>
        </div>
        <span className="subtitle">{secondaryMessage}</span>
      </StyledToasts>
    );
  }

  return (
    <StyledToasts data-testid={`${type}-toast`}>
      <div className="title">
        <span>{message}</span>
      </div>
    </StyledToasts>
  );
};

// https://fkhadra.github.io/react-toastify/api/toast
const BASE_OPTIONS: ToastOptions = {
  transition: Slide,
  closeButton: (props) => (
    <StyledCloseButton color="gray" onClick={props.closeToast}>
      <CrossIcon stroke="2" />
    </StyledCloseButton>
  ),
  hideProgressBar: false,
  closeOnClick: true,
  autoClose: 5000,
};

// NOTE: toasts are throttled to prevent duplicate notifications being displayed.
// This can occur due to callbacks being fired multiple times in quick succession
const info = throttle(
  (props: ToastProps, overrides?: ToastOptions) =>
    toast.info(<CustomToast {...props} type="info" />, {
      ...BASE_OPTIONS,
      toastId: 'info',
      ...overrides,
    }),
  THROTTLE_MS,
  { trailing: false }
);

const success = throttle(
  (props: ToastProps, overrides?: ToastOptions) =>
    toast.success(<CustomToast {...props} type="success" />, {
      ...BASE_OPTIONS,
      toastId: 'success',
      ...overrides,
    }),
  THROTTLE_MS,
  { trailing: false }
);

const error = throttle(
  (props: ToastProps, overrides?: ToastOptions) => {
    const { errorToReport, ...other } = props;

    if (errorToReport) {
      if (process.env.NODE_ENV === 'development') {
        // Prefixing the error message with ad-hoc string for better backwards search
        console.error('[DEV: Caught error]:', errorToReport);
      } else {
        const error = errorToReport instanceof Error ? errorToReport : new Error(errorToReport);
        Sentry.captureException(error);
      }
    }

    return toast.error(<CustomToast {...other} type="error" />, {
      ...BASE_OPTIONS,
      toastId: 'error',
      ...overrides,
    });
  },
  THROTTLE_MS,
  { trailing: false }
);

export const toasts = {
  info,
  success,
  error,
};

const StyledToasts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px 24px 34px 24px;
  box-sizing: border-box;
  gap: 6px;

  .url {
    padding-bottom: 16px;
  }

  .notification-url {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;

    span {
      ${fonts.body[8]}
    }
  }

  .subtitle {
    ${fonts.body[12]}
  }

  @media (min-width: ${breakpoints.md}px) {
    padding: 32px 28px 34px 28px;
    gap: 8px;
  }
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  padding: 0;

  svg {
    color: var(--color-gray-700);
    width: 20px;
    height: 20px;
    box-sizing: border-box;
  }

  @media (min-width: ${breakpoints.md}px) {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
