import { MouseEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';
import { Button } from 'components/shared';
import { ChevronDown, HelpOutlineIcon } from 'components/shared/icons';
import { useWindowDimensions } from 'hooks';
import { SupportLinks } from './support-links';

interface Props {
  onMobileClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const SupportDropdown = ({ onMobileClick }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { isTablet } = useWindowDimensions();

  const closeDropdown = () => setOpen(false);

  return (
    <Container ref={containerRef} opened={String(open)}>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          {isTablet ? (
            <Button variant="menu-link-secondary" size="large" onClick={onMobileClick}>
              <HelpOutlineIcon className="help-icon" />
              <span className="sr-only">Support</span>
            </Button>
          ) : (
            <Button variant="menu-link-secondary" size="medium" iconEnd={<ChevronDown className="dropdown-icon" />}>
              <HelpOutlineIcon className="help-icon" />
              Support
            </Button>
          )}
        </Popover.Trigger>
        <Popover.Portal container={containerRef.current}>
          <StyledPopoverContent side="bottom" sideOffset={6}>
            <SupportLinks onClick={closeDropdown} />
          </StyledPopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </Container>
  );
};

const Container = styled.div<{ opened: string }>`
  display: inline-flex;
  align-items: center;
  position: relative;
  justify-content: center;

  .menu-link-secondary > svg.dropdown-icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
    color: var(--color-dark-blue-50);
    transition: var(--animation-flip-chevron);
    ${({ opened }) => (opened === 'true' ? 'transform: rotate(180deg);' : 'transform: rotate(0);')}
  }

  button.menu-link-secondary {
    transition: none;
  }

  .help-icon {
    width: 18px;
    height: 18px;
  }
`;

const StyledPopoverContent = styled(Popover.Content)`
  z-index: var(--z-index-dropdown);
  border-radius: 10px;
  background-color: var(--color-base-blue-ui);

  &[data-state='open'] {
    animation: var(--animation-slide-down);
  }

  &:not([data-state='open']) {
    animation: var(--animation-slide-up);
  }
`;
