import { ComponentProps } from 'react';

export const WarningCircleIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.7425 6.62503C10.7425 6.21082 10.4067 5.87503 9.99252 5.87503C9.57831 5.87503 9.24252 6.21082 9.24252 6.62503V11.125C9.24252 11.5392 9.57831 11.875 9.99252 11.875C10.4067 11.875 10.7425 11.5392 10.7425 11.125V6.62503ZM9.99252 12.625C9.57831 12.625 9.24252 12.9608 9.24252 13.375C9.24252 13.7892 9.57831 14.125 9.99252 14.125H10.0038C10.418 14.125 10.7538 13.7892 10.7538 13.375C10.7538 12.9608 10.418 12.625 10.0038 12.625H9.99252Z"
        fill="#FFB119"
      />
    </svg>
  );
};
