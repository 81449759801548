import { lazy, KeyboardEvent } from 'react';
import { BigNumberish, ethers } from 'ethers';
import reactStringReplace from 'react-string-replace';
import axios from 'axios';
import { HTTP_STATUS_CODES } from 'constants/constants';
import { allChainsById } from './dapis';
import { slugify } from './format';

const DOUBLE_BRACKETS_REGEX = /\{\{([^}]+)\}\}/g;

type PreloadFn = typeof lazy;
export const preload: PreloadFn = (factory) => {
  const promise = factory();
  return lazy(() => promise);
};

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const keepLastTwoWordsTogether = (str: string | undefined) => {
  const lastSpaceIndex = str?.lastIndexOf(' ') || -1;

  if (!str || lastSpaceIndex === -1) return str;

  return `${str.substring(0, lastSpaceIndex)}\u00A0${str.substring(lastSpaceIndex + 1)}`;
};

export const handleInterpolation = (str: string, dictionary?: { [key: string]: any }): any =>
  reactStringReplace(str, DOUBLE_BRACKETS_REGEX, (match) => (dictionary ? dictionary[match] : match));

export const triggerOnEnter =
  (callback: (e: KeyboardEvent<HTMLElement>) => void) => (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      callback(e);
    }
  };

export const abbrStr = (str: string, prefixLength?: number) => {
  return `${str.substr(0, prefixLength || 9)}...${str.substr(str.length - 4, str.length)}`;
};

export const isNullish = <T,>(value: T | null | undefined): value is null | undefined => {
  return value == null;
};
export const getDapiPathName = (dApiName = '') => dApiName.replace('/', '-');

export const getDapiPath = (chainAlias: string, dApiName: string) => `/${chainAlias}/${slugify(dApiName)}`;

const getAvailableDecimalPlaces = (value: number) => {
  if (value >= 100000000) return 0;
  if (value >= 10000000) return 2;
  if (value >= 1000000) return 3;
  if (value >= 100000) return 4;
  if (value >= 10000) return 5;
  if (value >= 1000) return 6;
  if (value >= 100) return 7;
  if (value >= 10) return 8;
  return 9;
};

const FOUR_DECIMALS = 4;
const MAX_LENGTH_ALLOWED = 10;

const createNumber = (parsedIntegerValue: string, decimalValue: string, allowedDecimals: number, startIndex = 0) => {
  const loseTrailingZeros = /(\d*?[1-9])0+$/;
  const zeroesCutoffIndex = startIndex + allowedDecimals;
  const loseTrailingZerosFromIndex = new RegExp(`^(\\d{${zeroesCutoffIndex}})(0+)$`, 'g');

  const decimal = `${decimalValue}`.substring(0, startIndex + allowedDecimals);

  const numberDecimal = Number(decimal);
  if (numberDecimal === 0) {
    return parsedIntegerValue;
  }

  let roundedUpDecimal = decimal;

  // if we cut off some decimals, we need to round up the number
  if (decimalValue.replace(loseTrailingZerosFromIndex, '$1') !== decimal) {
    // Handle special case when all decimals are 9
    const allNines = /^9+$/.test(decimal);
    if (allNines) {
      return (Number(parsedIntegerValue) + 1).toString();
    }

    // Round up the decimal part and pad it with zeroes
    roundedUpDecimal = (numberDecimal + 1).toString().padStart(startIndex + allowedDecimals, '0');
  }

  // Handle trailing zeroes
  roundedUpDecimal = roundedUpDecimal.replace(loseTrailingZeros, '$1');
  const hasOnlyOneDecimal = roundedUpDecimal.toString().length === 1;
  const decimalFinal = `${roundedUpDecimal}${hasOnlyOneDecimal ? '0' : ''}`;

  // Merge integer and decimal part together
  const result = `${parsedIntegerValue}.${decimalFinal}`;

  return Number(result) === 0 ? '0' : result;
};

const getIndexOfLastConsecutiveZero = (decimalValue: string) => {
  let indexOfLastZero = -1;
  let i = 0;
  while (decimalValue[i] === '0' && i < decimalValue.length) {
    indexOfLastZero = i;
    i += 1;
  }

  return indexOfLastZero;
};

const commify = (value: string) => {
  const match = value.match(/^(-?)([0-9]*)$/);
  if (!match) {
    throw new Error(`bad formatted number: ${value} match ${match}`);
  }

  const neg = match[1];
  const whole = BigInt(match[2] || 0).toLocaleString('en-us');

  return `${neg}${whole}`;
};

// Display decimals only if the total length of the number is less than 10 characters
export const formatNumber = (initialValue: number | string | null, decimals?: number) => {
  const numValue = Number(initialValue);
  if (!initialValue || Number.isNaN(numValue)) return null;

  const value = initialValue.toString();

  const [integerValue, decimalValue] = value.split('.');
  const parsedIntegerValue = commify(integerValue);

  const allowedDecimalsUpTo10Chars = decimals ?? getAvailableDecimalPlaces(numValue);

  if (decimalValue === undefined || Number(decimalValue) === 0 || allowedDecimalsUpTo10Chars === 0)
    return parsedIntegerValue;

  if (Number(integerValue) > 1) {
    return createNumber(parsedIntegerValue, decimalValue, Math.min(FOUR_DECIMALS, allowedDecimalsUpTo10Chars));
  }

  const indexOfLastConsecutiveZero = getIndexOfLastConsecutiveZero(decimalValue);
  const maxAllowedDecimals = Math.min(FOUR_DECIMALS, Math.max(MAX_LENGTH_ALLOWED - indexOfLastConsecutiveZero, 1));

  return createNumber(parsedIntegerValue, decimalValue, maxAllowedDecimals, indexOfLastConsecutiveZero + 1);
};

export const formatBaseUnits = (value: BigNumberish | null | undefined, decimals?: number) => {
  if (isNullish(value)) return null;

  return formatNumber(ethers.formatUnits(BigInt(value)), decimals);
};

export const isResponseNotFound = (error: any) =>
  axios.isAxiosError(error) && error.response?.status === HTTP_STATUS_CODES.NOT_FOUND;

export const getMaxDecimalPlaces = (values: (number | string)[]) => {
  if (!values.length) return 0;

  return Math.max(
    ...values.map((value) => {
      const decimals = (value.toString().split('.')[1] || '').replace(/0+$/, '');
      return decimals.length;
    })
  );
};

export const disablePageScrolling = () => {
  window.document.body.style.overflow = 'clip';
};

export const enablePageScrolling = () => {
  window.document.body.style.overflow = 'unset';
};

export const filterNoCaseStartsWith = (data: string[] | undefined, filterValue: string) => {
  if (!data) {
    return [];
  }

  return data.filter((feed) => feed.toLocaleLowerCase().startsWith(filterValue.toLocaleLowerCase()));
};

export const getApi3RpcUrls = (chainId: number) =>
  allChainsById[chainId]?.providers.reduce<string[]>((acc, { rpcUrl }) => {
    if (rpcUrl) {
      acc.push(rpcUrl);
    }
    return acc;
  }, []) || [];

export const createExplorerUrl = (explorerUrl: string, hex: string, type: 'tx' | 'address') =>
  `${explorerUrl}/${type}/${hex}`.replace(`//${type}`, `/${type}`);
