import { useWalletClient } from 'wagmi';
import { BrowserProvider, FallbackProvider, Signer, ethers } from 'ethers';
import { WalletClient } from 'viem';
import { useEffect, useState } from 'react';
import { useConnectedAccount } from './use-connected-account';

const walletClientToSigner = async (walletClient: WalletClient) => {
  const { account, chain, transport } = walletClient;
  if (!account || !chain) return;

  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };

  const provider = new BrowserProvider(transport, network);
  const signer = await provider.getSigner(account.address);

  return signer;
};

/**
 * Hook to convert the viem Wallet Client to an ethers.js Signer.
 */
export function useSigner() {
  const [signer, setSigner] = useState<Signer>();
  const { connectedChainId } = useConnectedAccount();
  const { data: walletClient } = useWalletClient({ chainId: connectedChainId });

  useEffect(() => {
    const fetchSigner = async () => {
      if (!connectedChainId || !walletClient) {
        setSigner(undefined);
        return;
      }

      const signer = await walletClientToSigner(walletClient);
      setSigner(signer);
    };

    fetchSigner();
  }, [connectedChainId, walletClient]);

  return signer;
}

export const getHardhatSigner = (provider?: FallbackProvider) => {
  const wallet = ethers.Wallet.fromPhrase('test test test test test test test test test test test junk');
  return provider ? (wallet.connect(provider) as Signer) : undefined;
};
