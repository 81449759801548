import styled from 'styled-components';
import { fonts } from 'styles/fonts';
import { breakpoints } from 'styles/theme';

interface Props {
  onClick: (search: string) => void;
}

export const SearchExamples = ({ onClick }: Props) => {
  const searchExamples = ['USDT/USD', 'ETH', 'Exchange Rate'];
  return (
    <Container>
      {searchExamples.map((example, index) => (
        <ExampleBox
          key={index}
          data-testid="search-example-box"
          onClick={() => {
            onClick(example);
          }}
        >
          <span className="search-term">”{example}”</span>
        </ExampleBox>
      ))}
    </Container>
  );
};

const ExampleBox = styled.div`
  padding: 4px 16px;
  border-radius: 16px;
  background-color: var(--color-dark-blue-700);
  white-space: nowrap;
  cursor: pointer;
  ${fonts.body[14]};

  &:hover {
    background-color: var(--color-dark-blue-600);
  }

  .search-term {
    color: var(--color-base-light);
  }

  @media (min-width: ${breakpoints.md}px) {
    ${fonts.body[11]};
    padding: 8px 24px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.md}px) {
    justify-content: start;
  }
`;
